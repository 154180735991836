import React, { Component } from "react";
import ReactDOM from "react-dom"
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";

import "./Login.css";

import * as firebase from 'firebase';


export default class TermsConditions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      numPages: null,
      pageNumber: 1
    }
  }


  render() {
    const { pageNumber, numPages } = this.state;
    return (
      <div>
        <br/>
        <h2 className="centerStuffClass">Terms & Conditions</h2>{/*&nbsp;&nbsp;&nbsp;<a style={{'fontSize':'0.70em'}} href="../imgs/TermsConditions.pdf" download="TermsAndConditions.pdf">[download]</a> </h2>*/}
        {this.renderText()}
      </div>
    );
  }



  renderText() {
    return (
      <div>
    <p className="termsText">
    </p>
    <p className="termsText"><b>Agreement between
    User and www.unlimitedinkclub.com</b></p>
    <p className="termsText">Welcome to
    www.unlimitedinkclub.com. The www.unlimitedinkclub.com website (the
    &ldquo;Site&rdquo;) is</p>
    <p className="termsText">comprised of various
    web pages operated by Driven Office Solutions, LLC DBA Unlimited Ink</p>
    <p className="termsText">Club.
    www.unlimitedinkclub.com is offered to you conditioned on your
    acceptance without</p>
    <p className="termsText">modification of the
    terms, conditions, and notices contained herein (the &ldquo;Terms&rdquo;).
    Your use of</p>
    <p className="termsText">www.unlimitedinkclub.com
    constitutes your agreement to all such Terms. Please read these</p>
    <p className="termsText">terms carefully, and
    keep a copy of them for your reference.</p>
    <p className="termsText">
    </p><br/>
    <p className="termsText">www.unlimitedinkclub.com
    is an E-Commerce Site.</p>
    <p className="termsText">
    </p><br/>
    <p className="termsText">We provide ink and
    toner for desktop printers on a monthly subscription basis.</p>
    <p className="termsText">
    </p><br/>
    <p className="termsText"><b>Electronic
    Communications</b></p>
    <p className="termsText">Visiting
    www.unlimitedinkclub.com or sending emails to Driven Office
    Solutions, LLC DBA</p>
    <p className="termsText">Unlimited Ink Club
    constitutes electronic communications. You consent to receive
    electronic</p>
    <p className="termsText">communications and
    you agree that all agreements, notices, disclosures and other</p>
    <p className="termsText">communications that
    we provide to you electronically, via email and on the Site, satisfy
    any legal</p>
    <p className="termsText">requirement that
    such communications be in writing.</p>
    <p className="termsText">
    </p><br/>
    <p className="termsText"><b>Your Account</b></p>
    <p className="termsText">If you use this
    site, you are responsible for maintaining the confidentiality of your
    account and</p>
    <p className="termsText">password and for
    restricting access to your computer, and you agree to accept
    responsibility for</p>
    <p className="termsText">all activities that
    occur under your account or password. You may not assign or otherwise</p>
    <p className="termsText">transfer your
    account to any other person or entity. You acknowledge that Driven
    Office</p>
    <p className="termsText">Solutions, LLC DBA
    Unlimited Ink Club is not responsible for third party access to your
    account</p>
    <p className="termsText">that results from
    theft or misappropriation of your account. Driven Office Solutions,
    LLC DBA</p>
    <p className="termsText">Unlimited Ink Club
    and its associates reserve the right to refuse or cancel service,
    terminate</p>
    <p className="termsText">accounts, or remove
    or edit content in our sole discretion.</p>
    <p className="termsText">
    </p><br/>
    <p className="termsText"><b>Billing</b></p>
    <p className="termsText">Fees to be billed in
    advance of the month in which service is to be provided. Payments are
    to be</p>
    <p className="termsText">automatically paid
    in full by credit card. In the event the credit card expires and bill
    has not been</p>
    <p className="termsText">paid, Unlimited Ink
    Club reserves the right to cancel service within 30 days of bill
    date. Updates</p>
    <p className="termsText">to credit card
    information can be made via MyAccount portal or by contacting</p>
    <p className="termsText">info@unlimitedinkclub.com
    .</p>
    <p className="termsText"><br/>
    </p>
    <p className="termsText"><b>Children Under
    Thirteen</b></p>
    <p className="termsText">Driven Office
    Solutions, LLC DBA Unlimited Ink Club does not knowingly collect,
    either</p>
    <p className="termsText">online or offline,
    personal information from persons under the age of thirteen. If you
    are under</p>
    <p className="termsText">18, you may use
    www.unlimitedinkclub.com only with permission of a parent or
    guardian.</p>
    <p className="termsText">
    </p><br/>
    <p className="termsText"><b>Cancellation/Refund
    Policy</b></p>
    <p className="termsText">The subscription
    agreement may be canceled at any time</p>
    <p className="termsText">after the initial 12 month
    commitment for any reason, by either party.</p>
    <p className="termsText">There are no refunds or prorated
    billing in the event the customer cancels service mid-billing cycle.</p>
    <p className="termsText">
    </p><br/>
    <p className="termsText"><b>Links to Third
    Party Sites/Third Party Services</b></p>
    <p className="termsText">www.unlimitedinkclub.com
    may contain links to other websites (&ldquo;Linked Sites&rdquo;). The
    Linked Sites are not under the control of Driven Office Solutions,
    LLC DBA Unlimited Ink Club and</p>
    <p className="termsText">Driven Office
    Solutions, LLC DBA Unlimited Ink Club is not responsible for the
    contents of any</p>
    <p className="termsText">Linked Site,
    including without limitation any link contained in a Linked Site, or
    any changes or</p>
    <p className="termsText">updates to a Linked
    Site. Driven Office Solutions, LLC DBA Unlimited Ink Club is
    providing</p>
    <p className="termsText">these links to you
    only as a convenience, and the inclusion of any link does not imply</p>
    <p className="termsText">endorsement by
    Driven Office Solutions, LLC DBA Unlimited Ink Club of the site or
    any</p>
    <p className="termsText">association with its
    operators.</p>
    <p className="termsText">
    </p>
    <p className="termsText">Certain services
    made available via www.unlimitedinkclub.com are delivered by third
    party sites</p>
    <p className="termsText">and organizations.
    By using any product, service or functionality originating from the</p>
    <p className="termsText">www.unlimitedinkclub.com
    domain, you hereby acknowledge and consent that Driven Office</p>
    <p className="termsText">Solutions, LLC DBA
    Unlimited Ink Club may share such information and data with any third</p>
    <p className="termsText">party with whom
    Driven Office Solutions, LLC DBA Unlimited Ink Club has a contractual</p>
    <p className="termsText">relationship to
    provide the requested product, service or functionality on behalf of</p>
    <p className="termsText">www.unlimitedinkclub.com
    users and customers.</p>
    <p className="termsText">
    </p><br/>
    <p className="termsText"><b>No Unlawful or
    Prohibited Use/Intellectual Property</b></p>
    <p className="termsText">You are granted a
    non-exclusive, non-transferable, revocable license to access and use</p>
    <p className="termsText">www.unlimitedinkclub.com
    strictly in accordance with these terms of use. As a condition of
    your</p>
    <p className="termsText">use of the Site, you
    warrant to Driven Office Solutions, LLC DBA Unlimited Ink Club that
    you</p>
    <p className="termsText">will not use the
    Site for any purpose that is unlawful or prohibited by these Terms.
    You may not</p>
    <p className="termsText">use the Site in any
    manner which could damage, disable, overburden, or impair the Site or</p>
    <p className="termsText">interfere with any
    other party's use and enjoyment of the Site. You may not
    obtain or attempt to</p>
    <p className="termsText">obtain any materials
    or information through any means not intentionally made available or</p>
    <p className="termsText">provided for through
    the Site.</p>
    <p className="termsText">
    </p>
    <p className="termsText">All content included
    as part of the Service, such as text, graphics, logos, images, as
    well as the</p>
    <p className="termsText">compilation thereof,
    and any software used on the Site, is the property of Driven Office</p>
    <p className="termsText">Solutions, LLC DBA
    Unlimited Ink Club or its suppliers and protected by copyright and
    other</p>
    <p className="termsText">laws that protect
    intellectual property and proprietary rights. You agree to observe
    and abide by</p>
    <p className="termsText">all copyright and
    other proprietary notices, legends or other restrictions contained in
    any such</p>
    <p className="termsText">content and will not
    make any changes thereto.</p>
    <p className="termsText">
    </p>
    <p className="termsText">You will not modify,
    publish, transmit, reverse engineer, participate in the transfer or
    sale, create</p>
    <p className="termsText">derivative works, or
    in any way exploit any of the content, in whole or in part, found on
    the Site.</p>
    <p className="termsText">Driven Office
    Solutions, LLC DBA Unlimited Ink Club content is not for resale. Your
    use of the</p>
    <p className="termsText">Site does not
    entitle you to make any unauthorized use of any protected content,
    and in particular</p>
    <p className="termsText">you will not delete
    or alter any proprietary rights or attribution notices in any
    content. You will</p>
    <p className="termsText">use protected
    content solely for your personal use, and will make no other use of
    the content</p>
    <p className="termsText">without the express
    written permission of Driven Office Solutions, LLC DBA Unlimited Ink</p>
    <p className="termsText"><br/>

    </p>
    <p className="termsText">Club and the
    copyright owner. You agree that you do not acquire any ownership
    rights in any</p>
    <p className="termsText">protected content.
    We do not grant you any licenses, express or implied, to the
    intellectual</p>
    <p className="termsText">property of Driven
    Office Solutions, LLC DBA Unlimited Ink Club or our licensors except
    as</p>
    <p className="termsText">expressly authorized
    by these Terms.</p>
    <p className="termsText">
    </p><br/>
    <p className="termsText"><b>International
    Users</b></p>
    <p className="termsText">The Service is
    controlled, operated and administered by Driven Office Solutions, LLC
    DBA</p>
    <p className="termsText">Unlimited Ink Club
    from our offices within the USA. If you access the Service from a
    location</p>
    <p className="termsText">outside the USA, you
    are responsible for compliance with all local laws. You agree that
    you will</p>
    <p className="termsText">not use the Driven
    Office Solutions, LLC DBA Unlimited Ink Club Content accessed through</p>
    <p className="termsText">www.unlimitedinkclub.com
    in any country or in any manner prohibited by any applicable laws,</p>
    <p className="termsText">restrictions or
    regulations.</p>
    <p className="termsText">
    </p><br/>
    <p className="termsText"><b>Indemnification</b></p>
    <p className="termsText">You agree to
    indemnify, defend and hold harmless Driven Office Solutions, LLC DBA</p>
    <p className="termsText">Unlimited Ink Club,
    its officers, directors, employees, agents and third parties, for any
    losses,</p>
    <p className="termsText">costs, liabilities
    and expenses (including reasonable attorney&amp;#39;s fees) relating
    to or arising out of</p>
    <p className="termsText">your use of or
    inability to use the Site or services, any user postings made by you,
    your violation</p>
    <p className="termsText">of any terms of this
    Agreement or your violation of any rights of a third party, or your
    violation</p>
    <p className="termsText">of any applicable
    laws, rules or regulations. Driven Office Solutions, LLC DBA
    Unlimited Ink</p>
    <p className="termsText">Club reserves the
    right, at its own cost, to assume the exclusive defense and control
    of any matter</p>
    <p className="termsText">otherwise subject to
    indemnification by you, in which event you will fully cooperate with
    Driven</p>
    <p className="termsText">Office Solutions,
    LLC DBA Unlimited Ink Club in asserting any available defenses.</p>
    <p className="termsText">
    </p><br/>
    <p className="termsText"><b>Arbitration</b></p>
    <p className="termsText">In the event the
    parties are not able to resolve any dispute between them arising out
    of or</p>
    <p className="termsText">concerning these
    Terms and Conditions, or any provisions hereof, whether in contract,
    tort, or</p>
    <p className="termsText">otherwise at law or
    in equity for damages or any other relief, then such dispute shall be
    resolved</p>
    <p className="termsText">only by final and
    binding arbitration pursuant to the Federal Arbitration Act,
    conducted by a</p>
    <p className="termsText">single neutral
    arbitrator and administered by the American Arbitration Association,
    or a similar</p>
    <p className="termsText">arbitration service
    selected by the parties, in Orange County, California. The
    arbitrator's award shall be final, and judgment may be entered upon
    it in any court having jurisdiction. In the event that any legal or
    equitable action, proceeding or arbitration arises out of or concerns
    these Terms and Conditions, the prevailing party shall be entitled to
    recover its costs and reasonable attorney's fees. The parties agree
    to</p>
    <p className="termsText">arbitrate all
    disputes and claims in regards to these Terms and Conditions or any
    disputes arising</p>
    <p className="termsText">as a result of these
    Terms and Conditions, whether directly or indirectly, including Tort
    claims</p>
    <p className="termsText">that are a result of
    these Terms and Conditions. The parties agree that the Federal
    Arbitration Act</p>
    <p className="termsText">governs the
    interpretation and enforcement of this provision. The entire dispute,
    including the</p>
    <p className="termsText">scope and
    enforceability of this arbitration provision shall be determined by
    the Arbitrator. This</p>
    <p className="termsText">arbitration
    provision shall survive the termination of these Terms and
    Conditions.</p>
    <p className="termsText">
    </p><br/>
    <p className="termsText"><b>Class Action
    Waiver</b></p>
    <p className="termsText">Any arbitration
    under these Terms and Conditions will take place on an individual
    basis; class</p>
    <p className="termsText">arbitration and
    class/representative/collective actions are not permitted. THE
    PARTIES AGREE</p>
    <p className="termsText">THAT A PARTY MAY
    BRING CLAIMS AGAINST THE OTHER ONLY IN EACH&amp;#39;S</p>
    <p className="termsText"><br/>

    </p>
    <p className="termsText">INDIVIDUAL CAPACITY,
    AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY</p>
    <p className="termsText">PUTATIVE CLASS,
    COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH</p>
    <p className="termsText">AS IN THE FORM OF A
    PRIVATE ATTORNEY GENERAL ACTION AGAINST THE</p>
    <p className="termsText">OTHER. Further,
    unless both you and Driven Office Solutions, LLC DBA Unlimited Ink
    Club</p>
    <p className="termsText">agree otherwise, the
    arbitrator may not consolidate more than one person&amp;#39;s claims,
    and may not</p>
    <p className="termsText">otherwise preside
    over any form of a representative or class proceeding.</p>
    <p className="termsText">
    </p><br/>
    <p className="termsText"><b>Liability
    Disclaimer</b></p>
    <p className="termsText">THE INFORMATION,
    SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR</p>
    <p className="termsText">AVAILABLE THROUGH
    THE SITE MAY INCLUDE INACCURACIES OR</p>
    <p className="termsText">TYPOGRAPHICAL
    ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE</p>
    <p className="termsText">INFORMATION HEREIN.
    DRIVEN OFFICE SOLUTIONS, LLC DBA UNLIMITED INK</p>
    <p className="termsText">CLUB AND/OR ITS
    SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN</p>
    <p className="termsText">THE SITE AT ANY
    TIME.</p>
    <p className="termsText">
    </p>
    <p className="termsText">DRIVEN OFFICE
    SOLUTIONS, LLC DBA UNLIMITED INK CLUB AND/OR ITS</p>
    <p className="termsText">SUPPLIERS MAKE NO
    REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY,</p>
    <p className="termsText">AVAILABILITY,
    TIMELINESS, AND ACCURACY OF THE INFORMATION,</p>
    <p className="termsText">SOFTWARE, PRODUCTS,
    SERVICES AND RELATED GRAPHICS CONTAINED ON THE</p>
    <p className="termsText">SITE FOR ANY
    PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE</p>
    <p className="termsText">LAW, ALL SUCH
    INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED</p>
    <p className="termsText">GRAPHICS ARE
    PROVIDED &amp;quot;AS IS&amp;quot; WITHOUT WARRANTY OR CONDITION OF
    ANY</p>
    <p className="termsText">KIND. DRIVEN OFFICE
    SOLUTIONS, LLC DBA UNLIMITED INK CLUB AND/OR ITS</p>
    <p className="termsText">SUPPLIERS HEREBY
    DISCLAIM ALL WARRANTIES AND CONDITIONS WITH</p>
    <p className="termsText">REGARD TO THIS
    INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND</p>
    <p className="termsText">RELATED GRAPHICS,
    INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF</p>
    <p className="termsText">MERCHANTABILITY,
    FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-</p>
    <p className="termsText">INFRINGEMENT.</p>
    <p className="termsText">
    </p>
    <p className="termsText">TO THE MAXIMUM
    EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT</p>
    <p className="termsText">SHALL DRIVEN OFFICE
    SOLUTIONS, LLC DBA UNLIMITED INK CLUB AND/OR ITS</p>
    <p className="termsText">SUPPLIERS BE LIABLE
    FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL,</p>
    <p className="termsText">SPECIAL,
    CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER</p>
    <p className="termsText">INCLUDING, WITHOUT
    LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR</p>
    <p className="termsText">PROFITS, ARISING OUT
    OF OR IN ANY WAY CONNECTED WITH THE USE OR</p>
    <p className="termsText">PERFORMANCE OF THE
    SITE, WITH THE DELAY OR INABILITY TO USE THE SITE</p>
    <p className="termsText">OR RELATED SERVICES,
    THE PROVISION OF OR FAILURE TO PROVIDE SERVICES,</p>
    <p className="termsText">OR FOR ANY
    INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED</p>
    <p className="termsText">GRAPHICS OBTAINED
    THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE</p>
    <p className="termsText">USE OF THE SITE,
    WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT</p>
    <p className="termsText">LIABILITY OR
    OTHERWISE, EVEN IF DRIVEN OFFICE SOLUTIONS, LLC DBA</p>
    <p className="termsText">UNLIMITED INK CLUB
    OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE</p>
    <p className="termsText">POSSIBILITY OF
    DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT</p>
    <p className="termsText">ALLOW THE EXCLUSION
    OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR</p>
    <p className="termsText">INCIDENTAL DAMAGES,
    THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF</p>
    <p className="termsText">YOU ARE DISSATISFIED
    WITH ANY PORTION OF THE SITE, OR WITH ANY OF</p>
    <p className="termsText"><br/>

    </p>
    <p className="termsText">THESE TERMS OF USE,
    YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE</p>
    <p className="termsText">USING THE SITE.</p>
    <p className="termsText">
    </p><br/>
    <p className="termsText"><b>Miscellaneous</b></p>
    <p className="termsText">Driven Office
    Solutions, LLC DBA Unlimited Ink Club reserves the right, in its sole
    discretion,</p>
    <p className="termsText">to terminate your
    access to the Site and the related services or any portion thereof at
    any time,</p>
    <p className="termsText">without notice.</p>
    <p className="termsText">To the maximum
    extent permitted by law, this agreement is governed by the laws of
    the State of</p>
    <p className="termsText">California and you
    hereby consent to the exclusive jurisdiction and venue of courts in
    California</p>
    <p className="termsText">in all disputes
    arising out of or relating to the use of the Site. Use of the Site is
    unauthorized in</p>
    <p className="termsText">any jurisdiction
    that does not give effect to all provisions of these Terms,
    including, without</p>
    <p className="termsText">limitation, this
    section.</p>
    <p className="termsText">
    </p>
    <p className="termsText">You agree that no
    joint venture, partnership, employment, or agency relationship exists
    between</p>
    <p className="termsText">you and Driven
    Office Solutions, LLC DBA Unlimited Ink Club as a result of this
    agreement or</p>
    <p className="termsText">use of the Site.
    Driven Office Solutions, LLC DBA Unlimited Ink Club&amp;#39;s
    performance of this</p>
    <p className="termsText">agreement is subject
    to existing laws and legal process, and nothing contained in this
    agreement</p>
    <p className="termsText">is in derogation of
    Driven Office Solutions, LLC DBA Unlimited Ink Club&amp;#39;s right
    to comply</p>
    <p className="termsText">with governmental,
    court and law enforcement requests or requirements relating to your
    use of</p>
    <p className="termsText">the Site or
    information provided to or gathered by Driven Office Solutions, LLC
    DBA Unlimited</p>
    <p className="termsText">Ink Club with
    respect to such use. If any part of this agreement is determined to
    be invalid or</p>
    <p className="termsText">unenforceable
    pursuant to applicable law including, but not limited to, the
    warranty disclaimers</p>
    <p className="termsText">and liability
    limitations set forth above, then the invalid or unenforceable
    provision will be</p>
    <p className="termsText">deemed superseded by
    a valid, enforceable provision that most closely matches the intent
    of the</p>
    <p className="termsText">original provision
    and the remainder of the agreement shall continue in effect.</p>
    <p className="termsText">
    </p>
    <p className="termsText">Unless otherwise
    specified herein, this agreement constitutes the entire agreement
    between the</p>
    <p className="termsText">user and Driven
    Office Solutions, LLC DBA Unlimited Ink Club with respect to the Site
    and it</p>
    <p className="termsText">supersedes all prior
    or contemporaneous communications and proposals, whether electronic,
    oral</p>
    <p className="termsText">or written, between
    the user and Driven Office Solutions, LLC DBA Unlimited Ink Club with</p>
    <p className="termsText">respect to the Site.
    A printed version of this agreement and of any notice given in
    electronic form</p>
    <p className="termsText">shall be admissible
    in judicial or administrative proceedings based upon or relating to
    this</p>
    <p className="termsText">agreement to the
    same extent and subject to the same conditions as other business
    documents and</p>
    <p className="termsText">records originally
    generated and maintained in printed form. It is the express wish to
    the parties</p>
    <p className="termsText">that this agreement
    and all related documents be written in English.</p>
    <p className="termsText">
    </p><br/>
    <p className="termsText"><b>Changes to Terms</b></p>
    <p className="termsText">Driven Office
    Solutions, LLC DBA Unlimited Ink Club reserves the right, in its sole
    discretion,</p>
    <p className="termsText">to change the Terms
    under which www.unlimitedinkclub.com is offered. The most current</p>
    <p className="termsText">version of the Terms
    will supersede all previous versions. Driven Office Solutions, LLC
    DBA</p>
    <p className="termsText">Unlimited Ink Club
    encourages you to periodically review the Terms to stay informed of
    our</p>
    <p className="termsText">updates.</p>
    <p className="termsText">
    </p><br/>
    <p className="termsText"><b>Contact Us</b></p>
    <p className="termsText">
    </p>
    <p className="termsText">Driven Office
    Solutions, LLC DBA Unlimited Ink Club</p>
    <p className="termsText">9891 Irvine Center
    Drive, Suite 200</p>
    <p className="termsText">Irvine, California
    92618</p>
    <p className="termsText">
    </p>
    <p className="termsText">
    </p>
    <br/>
    <p className="termsText">info@drivenos.com</p>
    <p className="termsText">
    </p>
    <p className="termsText">800-690-0221</p>
    <p className="termsText">
    </p><br/>
    <p className="termsText">Effective as of
    February 01, 2019</p>
    <br/><br/><br/>
    </div>
    )
  }


}
