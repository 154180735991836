import React, { Component } from "react";
import ReactDOM from "react-dom"
import { Link, withRouter } from "react-router-dom";

import { Container, Table, Col, Row, Form, FormGroup, Label, Input, FormText, FormFeedback, } from 'reactstrap';
import { Button, Glyphicon } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";

import { Icon as BaseIcon } from "react-icons-kit";
import { print } from "react-icons-kit/fa/print";
import { plus } from "react-icons-kit/fa/plus";
import { minus } from "react-icons-kit/fa/minus";
import { check } from "react-icons-kit/fa/check";
import { creditCardAlt } from "react-icons-kit/fa/creditCardAlt";
import { ccVisa } from "react-icons-kit/fa/ccVisa";
import { ccMastercard } from "react-icons-kit/fa/ccMastercard";
import { ccAmex } from "react-icons-kit/fa/ccAmex";
import { ccDiscover } from "react-icons-kit/fa/ccDiscover";


import 'bootstrap/dist/css/bootstrap.min.css';
import './Signup.css';
import './Home.css';

import Papa from 'papaparse';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

//import { CSSTransition, transit } from 'react-transition-group';
import { CSSTransition, transit } from 'react-css-transition';

import * as firebase from 'firebase';
var printFleetHelper = require('../API_helpers/PrintFleetHelper')
var firebaseDbHelper = require('../API_helpers/FirebaseDbHelper')
var authorizeNetHelper = require('../API_helpers/AuthorizeNetHelper')
var shopifyHelper = require('../API_helpers/ShopifyHelper')

var axessProductsCsv = require("../products/Axess_Ink.csv");
var axessProducts = [];
var brandProducts = [];

var exemptPrinters = ["PIXMA iP2820", "PIXMA MG2420", "PIXMA MG2520", "PIXMA MG2522", "PIXMA MG2525", "PIXMA MG2555",
"PIXMA MG2920", "PIXMA MG2922", "PIXMA MG2924", "PIXMA MG3020", "PIXMA MG3022", "PIXMA MX490", "PIXMA MX492", "PIXMA TR4520",
"PIXMA TR4522", "PIXMA TR4550", "PIXMA TS202", "PIXMA TS302", "PIXMA TS3120", "PIXMA TS3122", "PIXMA MG2120", "PIXMA MG2220",
"PIXMA MG3120", "PIXMA MG3122", "PIXMA MG3220", "PIXMA MG3222", "PIXMA MG3520", "PIXMA MG3522", "PIXMA MG3620", "PIXMA MG4120",
"PIXMA MG4220", "PIXMA MX372", "PIXMA MX392", "PIXMA MX432", "PIXMA MX439", "PIXMA MX452", "PIXMA MX459", "PIXMA MX472", "PIXMA MX479",
"PIXMA MX512", "PIXMA MX522", "PIXMA MX532", "PIXMA TS5120",
"Deskjet 1000", "Deskjet 1010", "Deskjet 1011", "Deskjet 1012", "Deskjet 1015", "Deskjet 1050", "Deskjet 1051", "Deskjet 1055", "Deskjet 1056",
"Deskjet 1510", "Deskjet 1511", "Deskjet 1512", "Deskjet 1513", "Deskjet 1514", "Deskjet 2050", "Deskjet 2510", "Deskjet 2512", "Deskjet 2514",
"Deskjet 2540", "Deskjet 2541", "Deskjet 2542", "Deskjet 2543", "Deskjet 2544", "Deskjet 2545", "Deskjet 2547", "Deskjet 2549", "Deskjet 3000",
"Deskjet 3050", "Deskjet 3051A", "Deskjet 3051A", "Deskjet 3052A", "Deskjet 3052A", "Deskjet 3052A", "Deskjet 3052A", "Deskjet 3054",
"Deskjet 3054A", "Deskjet 3054A", "Deskjet 3054A", "Deskjet 3055A", "Deskjet 3056A", "Deskjet 3057A", "Deskjet 3059A", "Deskjet 3510",
"Deskjet 3511", "Deskjet 3512", "Deskjet 3516",
"ENVY 4500", "ENVY 4501", "ENVY 4502", "ENVY 4503", "ENVY 4504", "ENVY 4505", "ENVY 4507", "ENVY 4508", "ENVY 4509", "ENVY 5530", "ENVY 5531",
"ENVY 5532", "ENVY 5534", "ENVY 5535", "ENVY 5536 e-All-in-One", "ENVY 5539", "OfficeJet 2620", "OfficeJet 4630", "OfficeJet 4632", "OfficeJet 4634",
"OfficeJet 4635",
"ENVY 5540", "ENVY 5540 All-in-One", "ENVY 5541", "ENVY 5542", "ENVY 5543 All-in-One", "ENVY 5544", "ENVY 5545", "ENVY 5546", "ENVY 5547",
"ENVY 5548", "ENVY 5549", "ENVY 5640", "ENVY 5642", "ENVY 5643", "ENVY 5644", "ENVY 5646", "ENVY 5660", "ENVY 5661", "ENVY 5663", "ENVY 5664",
"ENVY 5665", "ENVY 7640", "ENVY 7644", "ENVY 7645", "ENVY 8000", "ENVY 8005", "OfficeJet 200 Mobile", "OfficeJet 250 Mobile", "OfficeJet 5740",
"OfficeJet 5741", "OfficeJet 5742", "OfficeJet 5743", "OfficeJet 5744", "OfficeJet 5745", "OfficeJet 5746", "OfficeJet 6301", "OfficeJet 8040",
"OfficeJet 8045",
"Deskjet 1110", "Deskjet 1111", "Deskjet 1112", "Deskjet 2130", "Deskjet 2131", "Deskjet 2132", "Deskjet 2133", "Deskjet 2134", "Deskjet 2136",
"Deskjet 3630", "Deskjet 3631", "Deskjet 3632", "Deskjet 3633", "Deskjet 3634", "Deskjet 3635", "Deskjet 3636", "Deskjet 3637", "Deskjet 3638",
"ENVY 4511", "ENVY 4512", "ENVY 4516", "ENVY 4520", "ENVY 4522", "ENVY 4523", "ENVY 4524", "ENVY 4525", "ENVY 4526", "ENVY 4527", "ENVY 4528",
"OfficeJet 3830", "OfficeJet 3831", "OfficeJet 3832", "OfficeJet 3833", "OfficeJet 3834", "OfficeJet 4650", "OfficeJet 4652", "OfficeJet 4654",
"OfficeJet 4655", "OfficeJet 5252", "OfficeJet 5255 All-in-One", "OfficeJet 5258",
"ENVY 6220", "ENVY 6230", "ENVY 6232", "ENVY 6252", "ENVY 6255", "ENVY 6258", "ENVY 7120", "ENVY 7130", "ENVY 7132", "ENVY 7155", "ENVY 7158",
"ENVY 7164", "ENVY 7800", "ENVY 7820", "ENVY 7830", "ENVY 7855", "ENVY 7858", "ENVY 7864", "Deskjet 2622", "Deskjet 2624", "Deskjet 2632",
"Deskjet 2633", "Deskjet 2634", "Deskjet 2635", "Deskjet 2652", "Deskjet 2655", "Deskjet 3720", "Deskjet 3722", "Deskjet 3730", "Deskjet 3732",
"Deskjet 3733", "Deskjet 3752", "Deskjet 3755", "Deskjet 3758", "ENVY 5010", "ENVY 5020", "ENVY 5032", "ENVY 5052", "ENVY 5055"];


var PulseLoader = require('halogen/PulseLoader');
var GridLoader = require('halogen/GridLoader');
var ScaleLoader = require('halogen/ScaleLoader');
var DotLoader = require('halogen/DotLoader');

const IconL = props => <BaseIcon size={56} icon={props.icon} />;
const IconM = props => <BaseIcon size={42} icon={props.icon} />;
const IconS = props => <BaseIcon size={34} icon={props.icon} />;
const IconXS = props => <BaseIcon size={26} icon={props.icon} />;


const options2 = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
];


export default class Signup extends Component {
  constructor(props) {
    super(props);

    this.numPgs = 5;
    this.mainHeight = 540;



    this.state = {
      isMobile: false,

      isLoading: false,
      hasLoaded: false,
      pageNum: 1,
      pageNumAnim: 1,

      specialContinue: false,

      showingPopover: false,
      popoverTitle: "",
      popoverDetails: "",

      // checkout
      hasCreatedAccount: false,
      uploadedToFirebase: false,
      uploadedToFirebaseInit: false,
      paymentSuccess: false,
      emailedReceipt: false,

      // user info
      firstName: "",
      lastName: "",
      email: "",
      phoneNum: "",
      password: "",
      passwordConfirm: "",
      verifyingEmail: false,
      verifiedEmail: false,

      // printers (new way)
      printerBrand: "",
      printerBrandEmpty: "",
      printerModel: "",
      printerModelEmpty: "",
      printersEntered: [],

      // printers parsing
      brandProductsFiltered: [],
      inkProductsToSend: [],

      // print fleet info
      pfGroupId: "",
      pfDcaId: "",
      pfDcaPin: "",
      pfDcaURLpc: "",
      pfDcaURLmac: "",
      pfHasDca: false,
      pfDcaIsConnected: false,
      getPfDcaIsActivated: false,
      pfNumChecks: 0,
      pfDoneCheckingPrinters: false,
      pfPrinters: [],
      pfPrintersSelected: [],

      // shipping info
      addressName: "",
      address1: "",
      address2: "",
      city: "",
      addrState: "",
      zipCode: "",
      country: "USA",

      // billing info
      paymentMethod: 0,
      cardNumber: "",
      cardName: "",
      cardMonth: "",
      cardYear: "",
      cardCvc: "",
      verifyingCard: false,
      authNetVerifiedCard: false,
      authNetProfileId: "",
      authNetPaymentId: "",
      authNetSubscriptionId: "",

      b_same: true,
      b_address1: "",
      b_address2: "",
      b_city: "",
      b_addrState: "",
      b_zipCode: "",
      b_country: "USA",



      validate: {
        firstNameState: '',
        lastNameState: '',
        emailState: '',
        phoneNumState: '',
        passwordState: '',
        passwordConfirmState: '',

        addressNameState: '',
        address1State: '',
        cityState: '',
        addrStateState: '',
        zipCodeState: '',
        countryState: '',

        cardNumberState: '',
        cardNameState: '',
        cardMonthState: '',
        cardYearState: '',
        cardCvcState: '',

        b_address1State: '',
        b_cityState: '',
        b_addrStateState: '',
        b_zipCodeState: '',
        b_countryState: '',
      },

    };





  }


  componentWillMount() {
    this.handleWindowSizeChange();
    window.addEventListener('resize', this.handleWindowSizeChange);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }
  handleWindowSizeChange = () => {
    let mobile = window.innerWidth <= 600;
    this.setState({ isMobile: mobile });
  };

  componentDidMount() {

    this.scrollToTop(2.0);

    this.setState({ hasLoaded: true });
    this.updatePageNumCanvas();

    // FINISHING SIGN UP
    if (this.props.isAuthenticated) {
      // get user Info
      let uid = firebase.auth().currentUser.uid;
      let dbRef = 'Users/' + uid;
      firebase.database().ref(dbRef).once('value', function (snapshot) {
        const userInfo = snapshot.val();

        // shouldn't be here!
        if (userInfo != null) {
          if (userInfo.payments != null) {
            if (userInfo.payments.length > 0) {
                this.props.history.push("/");
            }
            else { this.props.history.push("/"); }
          }
        }

        // set user state to whatever we have so far in the database
        this.setState({ specialContinue: true, verifiedEmail: true, hasCreatedAccount: true,
                        firstName: userInfo.firstName, lastName: userInfo.lastName, email: userInfo.email, phoneNum: userInfo.phoneNum,
        });
        if (userInfo.pfPrinters != null) {
          var printers = [];
          for (var i = 0; i < userInfo.pfPrinters.length; i++) {
            printers.push(userInfo.pfPrinters[i].name);
          }
          this.setState({ printersEntered: printers });
        }
        if (userInfo.pfDcas != null) {
          this.setState({ pfGroupId: userInfo.pfGroupId, pfDcaId: userInfo.pfDcas[0].id, pfDcaURLpc: userInfo.pfDcas[0].urlPC, pfDcaURLmac: userInfo.pfDcas[0].urlMAC });
        }
        if (userInfo.addresses != null) {
          this.setState({ addressName: userInfo.addresses[0].name, address1: userInfo.addresses[0].address1, address2: userInfo.addresses[0].address2,
                          city: userInfo.addresses[0].city, addrState: userInfo.addresses[0].state, zipCode: userInfo.addresses[0].zipCode, country: userInfo.addresses[0].country, });
        }
        if (userInfo.billings != null) {
          var bSame = (userInfo.addresses[0].address1 == userInfo.billings[0].address1) &&
            (userInfo.addresses[0].address2 == userInfo.billings[0].address2) &&
            (userInfo.addresses[0].city == userInfo.billings[0].city) &&
            (userInfo.addresses[0].addrState == userInfo.billings[0].addrState) &&
            (userInfo.addresses[0].zipCode == userInfo.billings[0].zipCode) &&
            (userInfo.addresses[0].country == userInfo.billings[0].country);
          var bAddress = {
            address1: this.state.b_same ? this.state.address1 : this.state.b_address1,
            address2: this.state.b_same ? this.state.address2 : this.state.b_address2,
            city: this.state.b_same ? this.state.city : this.state.b_city,
            state: this.state.b_same ? this.state.addrState : this.state.b_addrState,
            zipCode: this.state.b_same ? this.state.zipCode : this.state.b_zipCode,
            country: this.state.b_same ? this.state.country : this.state.b_country

          };
          this.setState({
            authNetVerifiedCard: true, authNetProfileId: userInfo.billings[0].authNetProfileId, authNetPaymentId: userInfo.billings[0].authNetPaymentId,
            cardName: userInfo.billings[0].name, cardNumber: "************" + userInfo.billings[0].lastFour, cardMonth: userInfo.billings[0].expMonth, cardYear: userInfo.billings[0].expYear, cardCvc: userInfo.billings[0].cvc,
            b_same: bSame, b_address1: userInfo.billings[0].address.address1, b_address2: userInfo.billings[0].address.address2,
            b_city: userInfo.billings[0].address.city, b_addrState: userInfo.billings[0].address.state, b_zipCode: userInfo.billings[0].address.zipCode, b_country: userInfo.billings[0].address.country,
          })
        }
        if (userInfo.paymentMethod != null) {
          this.setState({ paymentMethod: userInfo.paymentMethod });
        }


        this.didClickNextPage();

      }.bind(this));
    }


    // PARSE AXESS PRODUCTS LIST
    this.parseAxessProducts();


    if (false) {
      this.setState({
        firstName: "Tom",
        lastName: "Brady",
        email: "TomBrady33@gmail.com",
        phoneNum: "9491234567",
        password: "aA12345678",
        passwordConfirm: "aA12345678",

        //pfGroupId: '753a29ea-13e5-4cf6-a11d-3b610bdfad71',
        //pfDcaId: 'f06a9ab9-87df-4cf8-b8dc-0f0abd1ccb82',

        addressName: "Chad Hadzinsky",
        address1: "2921 Arroyo",
        city: "San Clemente",
        addrState: "CA",
        zipCode: "92673",
        country: "USA",

        cardName: "Tom Brady",
        cardNumber: "1234123412341234",
        cardMonth: "04",
        cardYear: "20",
        cardCvc: "123",

        b_address1: "2921 Arroyo",
        b_city: "San Clemente",
        b_addrState: "CA",
        b_zipCode: "92673",
        b_country: "USA",

      });
    }
  }

  componentDidUpdate() {
    this.updatePageNumCanvas();
  }

  componentWillUnmount() {
    this.setState({ hasLoaded: false });
  }

  async parseAxessProducts()
  {
    console.log("111");
    Papa.parse(axessProductsCsv, {
      header: true,
      download: true,
      skipEmptyLines: true,
      complete: this.gotAxessProducts
    });
  }

  gotAxessProducts(result)
  {
    axessProducts = [];
    for (var i = 0; i < result.data.length; i++) {
      const brand = result.data[i]["OEM"];
      axessProducts.push(result.data[i]);
    }
  }

  parseAxessProductsList(forBrand)
  {
    const forBrandStr = forBrand + " ";
    brandProducts = [];

    for (var i = 0; i < axessProducts.length; i++) {
      //console.log(axessProducts[i]); // 'Item #'
      if (forBrand == axessProducts[i]["OEM"]) {
        var printers = axessProducts[i]["Compatibility"].split(",");
        for (var j = 0; j < printers.length; j++) {
          const printerName = printers[j].replace(forBrandStr, "")
          if (!brandProducts.includes(printerName) && (printerName != "")) {

            // check exempt list here
            var exempt = false
            if (exemptPrinters.includes(printerName)) {
              exempt = true;
            }
            else {
              for (var n = 0; n < exemptPrinters.length; n++) {
                if (exemptPrinters[n].includes(printerName)) {
                  exempt = true;
                }
                else if (printerName.includes(exemptPrinters[n])) {
                  exempt = true;
                }
              }
            }

            if (!exempt) {
              brandProducts.push(printerName);
            }
          }
        }
      }

      /*
      if (result.data[i]["OEM"] == "Brother") {
        brotherProducts.push(result.data[i]);
        var printers = result.data[i]["Compatibility"].split(",");
        for (var j = 0; j < printers.length; j++) {
          if (!brotherPrinters.includes(printers[j])) {
            brotherPrinters.push(printers[j].replace("Brother ", ""));
          }
        }
      }
      else if (result.data[i]["OEM"] == "Canon") {
        canonProducts.push(result.data[i]);
      }
      else if (result.data[i]["OEM"] == "Dell") {
        dellProducts.push(result.data[i]);
      }
      else if (result.data[i]["OEM"] == "Epson") {
        epsonProducts.push(result.data[i]);
      }
      else if (result.data[i]["OEM"] == "HP") {
        hpProducts.push(result.data[i]);
      }
      else if (result.data[i]["OEM"] == "Kodak") {
        kodakProducts.push(result.data[i]);
      }
      */
    }

    brandProducts.sort();

    this.filterAxessProductList(this.state.printerModel);
  }

  filterAxessProductList(model)
  {
    var productsFiltered = [];

    for (var i = 0; i < brandProducts.length; i++) {
      if (brandProducts[i].toUpperCase().includes(model.toUpperCase()) || (model == "")) {
        productsFiltered.push(brandProducts[i]);
        //productsFiltered.push(brandProducts[i]);
      }
    }
    this.setState({ brandProductsFiltered: productsFiltered });
  }

  findInkProductsForPrinters()
  {
    var inkProducts = [];

    for (var i = 0; i < this.state.printersEntered.length; i++) {
      var inkProducts2 = this.findInkProductsForPrinter(this.state.printersEntered[i]);
      for (var j = 0; j < inkProducts2.length; j++) {
        inkProducts.push(inkProducts2[j]);
      }
    }
    this.setState({ inkProductsToSend: inkProducts });
  }

  findInkProductsForPrinter(printer)
  {
    var inkProducts = [];
    const brand = printer.split(" ")[0];

    for (var i = 0; i < axessProducts.length; i++) {
      if (brand == axessProducts[i]["OEM"]) {
        var supportedPrinters = axessProducts[i]["Compatibility"].split(",");
        for (var j = 0; j < supportedPrinters.length; j++) {
          if (printer == supportedPrinters[j]) {
            inkProducts.push(axessProducts[i]);
          }
        }
      }
    }
    return inkProducts;
  }


  validateLabel(e) {
    let _id = e.target.id;
    let _value = e.target.value;
    if (_id == 'cardNumber') {
      this.validateValue(_id, this.unformatCardNumber(_value));
    }
    else if (_id == 'phoneNum') {
      this.validateValue(_id, this.unformatPhoneNumber(_value));
    }
    else {
      this.validateValue(_id, _value);
    }
  }

  validateValue(_id, _value) {
    const { validate } = this.state
      if (_id == "firstName") {
        validate.firstNameState = (_value.length > 0) ? 'has-success' : 'has-danger';
      }
      else if (_id == "lastName") {
        validate.lastNameState = (_value.length > 0) ? 'has-success' : 'has-danger';
      }
      else if (_id == "email") {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const { validate } = this.state
          if (emailRex.test(_value)) {
            validate.emailState = 'has-success'
          } else {
            validate.emailState = 'has-danger'
          }
          this.setState({ validate })
      }
      else if (_id == "phoneNum") {
        validate.phoneNumState = (_value.length >= 3) ? 'has-success' : 'has-danger';
      }
      else if (_id == "password") {
        let lengthGood = (_value.length >= 8);
        let lowercaseGood =  (_value.match(/[a-z]/));
        let uppercaseGood =  (_value.match(/[A-Z]/));
        let numberGood =  (_value.match(/[0-9]/));
        let charsGood = lowercaseGood && uppercaseGood && numberGood;
        validate.passwordState = (lengthGood && charsGood) ? 'has-success' : 'has-danger';
      }
      else if (_id == "passwordConfirm") {
        this.validateValue("password", this.state.password);
        validate.passwordConfirmState = (_value == this.state.password && (validate.passwordState == 'has-success')) ? 'has-success' : 'has-danger';
      }

      // else if (_id == "addressName") {
      //   validate.addressNameState = (_value.length > 0) ? 'has-success' : 'has-danger';
      // }
      else if (_id == "address1") {
        validate.address1State = (_value.length > 0) ? 'has-success' : 'has-danger';
      }
      else if (_id == "city") {
        validate.cityState = (_value.length > 0) ? 'has-success' : 'has-danger';
      }
      else if (_id == "addrState") {
        validate.addrStateState = (_value.length > 0) ? 'has-success' : 'has-danger';
      }
      else if (_id == "zipCode") {
        validate.zipCodeState = (_value.length > 0) ? 'has-success' : 'has-danger';
      }
      else if (_id == "country") {
        validate.countryState = (_value.length > 0) ? 'has-success' : 'has-danger';
      }

      else if (_id == "cardNumber") {
        validate.cardNumberState = (_value.length >= 10 && _value.length <= 20) ? 'has-success' : 'has-danger';
      }
      else if (_id == "cardName") {
        validate.cardNameState = (_value.length > 0) ? 'has-success' : 'has-danger';
      }
      else if (_id == "cardMonth") {
        validate.cardMonthState = (_value.length == 2) ? 'has-success' : 'has-danger';
      }
      else if (_id == "cardYear") {
        validate.cardYearState = (_value.length == 2) ? 'has-success' : 'has-danger';
      }
      else if (_id == "cardCvc") {
        validate.cardCvcState = (_value.length >= 3 && _value.length <= 5) ? 'has-success' : 'has-danger';
      }

      else if (_id == "b_address1") {
        validate.b_address1State = (_value.length > 0) ? 'has-success' : 'has-danger';
      }
      else if (_id == "b_city") {
        validate.b_cityState = (_value.length > 0) ? 'has-success' : 'has-danger';
      }
      else if (_id == "b_addrState") {
        validate.b_addrStateState = (_value.length > 0) ? 'has-success' : 'has-danger';
      }
      else if (_id == "b_zipCode") {
        validate.b_zipCodeState = (_value.length > 0) ? 'has-success' : 'has-danger';
      }
      else if (_id == "b_country") {
        validate.b_countryState = (_value.length > 0) ? 'has-success' : 'has-danger';
      }

      this.setState({ validate })
  }

  validatePage1() {
    this.validateValue("firstName", this.state.firstName);
    this.validateValue("lastName", this.state.lastName);
    this.validateValue("email", this.state.email);
    this.validateValue("phoneNum", this.state.phoneNum);
    this.validateValue("password", this.state.password);
    this.validateValue("passwordConfirm", this.state.passwordConfirm);

    const { validate } = this.state
    let itsGood = (validate.firstNameState === 'has-success')
                  && (validate.lastNameState === 'has-success')
                  && (validate.emailState  === 'has-success')
                  && (validate.phoneNumState  === 'has-success')
                  && (validate.passwordState  === 'has-success')
                  && (validate.passwordConfirmState  === 'has-success');

    return itsGood;
  }

  validatePage2() {
    let hasPrinter = this.state.printersEntered.length > 0;
    return hasPrinter;
  }

  validatePage2_old() {
    let hasPrinter = this.state.pfPrintersSelected.length > 0;
    return hasPrinter;
  }

  validatePage3() {
    //this.validateValue("addressName", this.state.addressName);
    this.validateValue("address1", this.state.address1);
    this.validateValue("city", this.state.city);
    this.validateValue("addrState", this.state.addrState);
    this.validateValue("zipCode", this.state.zipCode);
    this.validateValue("country", this.state.country);

    const { validate } = this.state
    let itsGood = //(validate.addressNameState === 'has-success')
                  (validate.address1State === 'has-success')
                  && (validate.cityState === 'has-success')
                  && (validate.addrStateState  === 'has-success')
                  && (validate.zipCodeState  === 'has-success')
                  && (validate.countryState  === 'has-success');

    return itsGood;
  }

  validatePage4() {
    this.validateValue("cardNumber", this.state.cardNumber);
    this.validateValue("cardName", this.state.cardName);
    this.validateValue("cardMonth", this.state.cardMonth);
    this.validateValue("cardYear", this.state.cardYear);
    this.validateValue("cardCvc", this.state.cardCvc);

    const { validate } = this.state

    var addrGood = true;
    if (!this.state.b_same) {
      this.validateValue("b_address1", this.state.b_address1);
      this.validateValue("b_city", this.state.b_city);
      this.validateValue("b_addrState", this.state.b_addrState);
      this.validateValue("b_zipCode", this.state.b_zipCode);
      this.validateValue("b_country", this.state.b_country);
      addrGood = (validate.b_address1State === 'has-success')
                    && (validate.b_cityState === 'has-success')
                    && (validate.b_addrStateState  === 'has-success')
                    && (validate.b_zipCodeState  === 'has-success')
                    && (validate.b_countryState  === 'has-success')
    }

    let selectedPaymentMethod = this.state.paymentMethod > 0;
    let itsGood = (validate.cardNumberState === 'has-success')
                  && (validate.cardNameState === 'has-success')
                  && (validate.cardMonthState  === 'has-success')
                  && (validate.cardYearState  === 'has-success')
                  && (validate.cardCvcState  === 'has-success')
                  && addrGood
                  && selectedPaymentMethod;

    if (!selectedPaymentMethod) {
      this.setState({showingPopover: true, popoverTitle: 'WHOOPS', popoverDetails: "Please select a payment plan"});
      this.scrollToTop(2.0);
    }

    let valid = (itsGood && !this.state.verifyingCard);
    return valid;
  }

  validateForm() {
    return (
      this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }


  handleChange = async event => {
    if (event.target.id.includes('card') && this.state.authNetVerifiedCard) {
      return;
    }
    if (event.target.id.includes('b_') && this.state.authNetVerifiedCard) {
      return;
    }

    if (event.target.id == 'printerBrand') {
      this.parseAxessProductsList(event.target.value);
    }
    if (event.target.id == "printerModel") {

      this.setState({
        printerModel: event.target.value
      });
      this.filterAxessProductList(event.target.value);
    }


    let dLength = Math.abs(this.state[event.target.id].length - event.target.value.length);

    if (event.target.id == 'b_same') {
      this.setState({ [event.target.id]: !this.state.b_same });
    }
    else {
      if (event.target.id == 'cardNumber') {
        this.setState({ [event.target.id]: this.unformatCardNumber(event.target.value) });
      }
      else if (event.target.id == 'phoneNum') {
        this.setState({ [event.target.id]: this.unformatPhoneNumber(event.target.value) });
      }
      else {
        this.setState({ [event.target.id]: event.target.value });
      }

      if (dLength > 1) {
        this.validateLabel(event);
      }
    }
  }


  handleSubmit = async event => {
    //event.preventDefault();
    // console.log("----- SUBMITTING NEW USER -----");
    this.setState({ isLoading: true });

    // MAKE AUTHORIZE.NET SUBSCRIPTION PAYMENT
    if (this.state.uploadedToFirebase && !this.state.paymentSuccess) {
      let amount = this.getPriceForNumPrinters(this.state.printersEntered.length);
      let orderInfo = { name: this.state.firstName + ' ' + this.state.lastName, amount: amount, };
      // MONTHLY SUBSCRIPTION
      if (this.state.paymentMethod == 1) {
        authorizeNetHelper.createSubscriptionFromCustomerProfile(orderInfo, this.state.authNetProfileId, this.state.authNetPaymentId, 'addressId', function(response) {
          if (response.success) { this.confirmSignupSuccessful(); }
          else { this.confirmSignupFailed(response); }
        }.bind(this));
      }
      // ANNUAL SUBSCRIPTION
      else {
        authorizeNetHelper.chargeCustomerProfile(this.state.authNetProfileId, this.state.authNetPaymentId, amount, async function(response) {
          if (response.success) { this.confirmSignupSuccessful(); }
          else { this.confirmSignupFailed(response); }
        }.bind(this));
      }
    }
    else if (this.state.uploadedToFirebase && this.state.paymentSuccess) {
      this.setState({ isLoading: false});
      this.didClickNextPage();
    }
    else {
      this.setState({ isLoading: false });
    }
  }

  async confirmSignupSuccessful() {
    await this.pushUserDataToFirebase(true);
    this.putOrderToShopify();
    this.setState({ paymentSuccess: true });
    this.setState({ isLoading: false});
    this.didClickNextPage();
  }
  confirmSignupFailed(response) {
    this.sendFailedPaymentEmail(response.message);
    if (response.message.includes("duplicate")) {
      this.confirmSignupSuccessful();
    }
    else {
      this.setState({ isLoading: false });
      this.setState({showingPopover: true, popoverTitle: 'WHOOPS', popoverDetails: response.message});
      this.scrollToTop(2.0);
    }
  }



  async attemptCreateUserFirebase(forceUpdate) {
    var user = firebaseDbHelper.getCurrentUser();

    var response = await firebaseDbHelper.createNewUser(this.state.email, this.state.password);
    if (response.success) {
      var user = firebaseDbHelper.getCurrentUser();
      if (user != null) {
        // console.log("----- NEW USER CREATED -----");
        // console.log("UID: " + ;
        // console.log("Email: " + user['email']);
      }
      this.setState({ hasCreatedAccount: true });
      this.props.userHasAuthenticated(true);
    }
    else {
      this.setState({showingPopover: true, popoverTitle: 'WHOOPS', popoverDetails: response.errorMsg})
      this.scrollToTop(2.0);
    }
  }

  async pushUserDataToFirebase(forFinalCheckout)
  {
    var user = firebaseDbHelper.getCurrentUser();
    if (user != null) {
      var printersDb = [];
      for (var i = 0; i < this.state.printersEntered.length; i++) {
        let randUid = (Math.floor(Math.random() * 8999999) + 1000000).toString();
        let printerInfo = { serial: "", name: this.state.printersEntered[i], ipAddress: "", uuid: randUid };
        printersDb.push(printerInfo);
      }



      /*
      if (withPrinters) {
        for (var i = 0; i < this.state.pfPrintersSelected.length; i++) {
          let printer = this.state.pfPrintersSelected[i];
          let printerInfo = {
            serial: printer.serialNumber,
            name: printer.name,
            ipAddress: printer.ipAddress,
            uuid: printer.id,
          };
          printersDb.push(printerInfo);
        }
      }
      */
      /*
      else {
        let printerInfo = {
          serial: "",
          name: "",
          ipAddress: "",
          uuid: "",
        };
        printersDb.push(printerInfo);
      }
      */

      var pfDcas = [];
      let dca = {
        id: this.state.pfDcaId,
        urlPC: this.state.pfDcaURLpc,
        urlMAC: this.state.pfDcaURLmac,
      }
      pfDcas.push(dca);

      var addresses = [];
      let address = {
        name: this.state.addressName,
        address1: this.state.address1,
        address2: this.state.address2,
        city: this.state.city,
        state: this.state.addrState,
        zipCode: this.state.zipCode,
        country: this.state.country
      }
      addresses.push(address);

      var billings = [];
      if (this.state.authNetVerifiedCard) {
        let b_address = {
          address1: this.state.b_same ? this.state.address1 : this.state.b_address1,
          address2: this.state.b_same ? this.state.address2 : this.state.b_address2,
          city: this.state.b_same ? this.state.city : this.state.b_city,
          state: this.state.b_same ? this.state.addrState : this.state.b_addrState,
          zipCode: this.state.b_same ? this.state.zipCode : this.state.b_zipCode,
          country: this.state.b_same ? this.state.country : this.state.b_country
        };
        let billing = {
          authNetProfileId: this.state.authNetProfileId,
          authNetPaymentId: this.state.authNetPaymentId,
          name: this.state.cardName,
          lastFour: this.state.cardNumber.substring(this.state.cardNumber.length - 4, this.state.cardNumber.length),
          expMonth: this.state.cardMonth,
          expYear: this.state.cardYear,
          cvc: this.state.cardCvc,
          address: b_address,
        }
        billings.push(billing);
      }
      /*
      else {
        let billing = {
          authNetProfileId: "",
          authNetPaymentId: "",
          name: "",
          lastFour: "",
          b_address: {
            address1: "",
            address2: "",
            city: "",
            state: "",
            zipCode: "",
            country: ""
          }
        }
        billings.push(billing);
      }
      */

      var subscriptionId = "";

      var payments = [];
      if (forFinalCheckout) {
        var date = new Date();
        var options = {year: 'numeric', month: 'short', day: 'numeric' };
        var dateStr = date.toLocaleDateString("en-US", options);
        let payment = {
          date: dateStr,
          price: this.getPriceForNumPrinters(this.state.printersEntered.length),
          devices: printersDb,
        }
        payments.push(payment);
      }

      var shipments = [];
      if (forFinalCheckout) {
        for (var i = 0; i < this.state.printersEntered.length; i++) {
          var partInfo = {serial: "", type: "Full Ink Set"};
          var deviceInfo = {name: this.state.printersEntered[i]};

          var date = new Date();
          var options = {year: 'numeric', month: 'short', day: 'numeric' };
          var dateShipped = date.toLocaleDateString("en-US", options);
          var dateArrived = "IN TRANSIT";

          var shipment = {partInfo: partInfo, deviceInfo: deviceInfo, dateShipped: dateShipped, dateArrived: dateArrived};
          shipments.push(shipment);
        }
      }


      await firebaseDbHelper.writeUserData(user['uid'], this.state.email, this.state.firstName, this.state.lastName, this.state.phoneNum,
        this.state.pfGroupId, printersDb,
        0, pfDcas,
        0, addresses,
        0, billings,
        subscriptionId,
        payments, this.state.paymentMethod,
        shipments,
      );

      var user = firebaseDbHelper.getCurrentUser();
      var date = new Date(user.metadata.creationTime);
      var options = {year: 'numeric', month: 'short', day: 'numeric' };
      var dateRegisteredStr = date.toLocaleDateString("en-US", options);
      await firebaseDbHelper.updateDateRegistered(user['uid'], dateRegisteredStr);

      // console.log("----- PUSHED USER DATA TO DB -----");




      if (this.state.pageNum <= 2) { this.setState({ uploadedToFirebaseInit: true }); }
      else if (this.state.pageNum >= 4) { this.setState({ uploadedToFirebase: true }); }
    }
    else {
      // console.log("----- NO USER LOGGED IN -----");
    }

  }


  async postUserToShopify() {
    let result = await shopifyHelper.postNewUser(this.state.email, this.state.firstName, this.state.lastName, this.state.phoneNum);
    if (result == "-3") {
      await shopifyHelper.postNewUser(this.state.email, this.state.firstName, this.state.lastName, "NO_PHONE");
    }
  }

  async updateShopifyUser() {
    let customer = await shopifyHelper.searchForCustomer(this.state.email)
    if (customer == null) { return }

    var addressId = null
    if (customer.addresses != null) {
      if (customer.addresses.length > 0) {
        addressId = customer.addresses[0].id
      }
    }

    let address = {
      name: this.state.addressName,
      address1: this.state.address1,
      address2: this.state.address2,
      city: this.state.city,
      state: this.state.addrState,
      zipCode: this.state.zipCode,
      country: this.state.country
    }
    shopifyHelper.updateCustomer(customer.id, addressId, this.state.firstName, this.state.lastName, address);
  }

  async putOrderToShopify() {
    // get shopify customer ID
    let customer = await shopifyHelper.searchForCustomer(this.state.email)
    if (customer == null) { return }

    // post new order
    let price = this.state.paymentMethod == 1 ? "10.00" : "75.00"
    var printers = []
    for (var i = 0; i < this.state.printersEntered.length; i++) {
      let printer = this.state.printersEntered[i]
      printers.push(printer)
    }

    shopifyHelper.postNewOrder(customer.id, printers, price);
  }


  updatePageNumCanvas() {

    const canvas = this.refs.canvas;
    const ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (this.state.pageNum == 6) { return; }

    let mobile = window.innerWidth <= 600;


    ctx.strokeStyle = '#27AAEA';
    ctx.fillStyle = '#27AAE2';
    ctx.font = '14px Roboto Condensed';
    ctx.textAlign = 'center';

    let padding = canvas.width*0.1;
    let canvasDrawH = canvas.height;
    let size = canvasDrawH*0.315;
    let dx = (canvas.width - (2.0*padding)) / (this.numPgs-1)

    let controlDx = 0.225*dx;
    let controlDy = 0.575;

    for (var i = 0; i < this.numPgs; i++) {
      let x1 = padding + (i*dx);
      let y1 = canvasDrawH*0.5 - size;

      let x2 = x1 + 0.5*dx;
      let y2 = canvasDrawH*0.5 - controlDy*size;

      let x3 = x1 + dx;

      let nY1 = canvasDrawH*0.5 + size;
      let nY2 = canvasDrawH*0.5 + controlDy*size;

      // circles
      ctx.fillStyle = '#27AAE2';
      ctx.beginPath();
      ctx.ellipse(x1, canvasDrawH*0.5, size, size, 0, 0, 90);
      ctx.fill();

      // between curves
      if (i < this.numPgs-1) {
        ctx.beginPath();
        ctx.moveTo(x1, y1);
        ctx.bezierCurveTo(x1+controlDx, y1, x2-controlDx, y2,  x2, y2);
        ctx.bezierCurveTo(x2+controlDx, y2, x3-controlDx, y1,  x3, y1);
        ctx.lineTo(x3, nY1);
        ctx.bezierCurveTo(x3-controlDx, nY1, x2+controlDx, nY2,  x2, nY2);
        ctx.bezierCurveTo(x2-controlDx, nY2, x1+controlDx, nY1,  x1, nY1);
        ctx.lineTo(x1, y1);
        ctx.fill();
      }

      // clear out
      ctx.fillStyle = '#d7d7d7';
      ctx.beginPath();
      ctx.ellipse(x1, canvasDrawH*0.5, 0.675*size, 0.675*size, 0, 0, 90);
      ctx.fill();

      // label text
      ctx.fillStyle = '#000000';
      let labelText = (i==0) ? 'User' : (i==1) ? 'Printers' : (i==2) ? 'Shipping' : (i==3) ? 'Billing' : (i==4) ? 'Confirm' : '';
      ctx.fillText(labelText, x1, canvasDrawH*0.5 + size + canvasDrawH*0.14);
    }

    // selected page
    let xAnim = padding + ((this.state.pageNumAnim-1)*dx);
    let yAnim = this.state.pageNumAnim - parseInt(this.state.pageNumAnim);
    if (yAnim < 0.5) { yAnim = 1.0 - yAnim; }
    yAnim = Math.pow(yAnim, 0.9);
    yAnim = Math.max(0.635, yAnim);
    ctx.fillStyle = '#000000';
    ctx.beginPath();
    ctx.ellipse(xAnim, canvasDrawH*0.5, 0.675*size, 0.675*size*yAnim, 0, 0, 90);
    ctx.fill();

  }


  scrollToTop(scrollDuration) {
      var scrollStep = -window.scrollY / (scrollDuration / 15),
          scrollInterval = setInterval(function(){
          if ( window.scrollY != 0 ) {
              window.scrollBy( 0, scrollStep );
          }
          else clearInterval(scrollInterval);
      },15);
  }

  didClickPrevPage() {
    if (this.state.pageNum == 2) {
      return;
    }

    this.setState({ pageNum: this.state.pageNum - 1 });
    this.startTimer();
  }

  async didClickNextPage() {
    // VALIDATE PAGE
    if (this.state.pageNum == 1) {
      if (!this.state.specialContinue) {
        if (!this.validatePage1()) {
          return;
        }
      }
    }
    else if (this.state.pageNum == 2) {
      //if (!this.state.pfHasDca) {
      if (this.state.printersEntered.length <= 0) {
        this.setState({showingPopover: true, popoverTitle: 'WHOOPS', popoverDetails: 'Please enter at least 1 printer first'})
        this.scrollToTop(2.0);
        return;
      }
      this.findInkProductsForPrinters();
      if (!this.validatePage2()) {
        //this.setState({showingPopover: true, popoverTitle: 'WHOOPS', popoverDetails: 'must select at least one printer'})
        //return;
      }
    }
    else if (this.state.pageNum == 3) {
      if (!this.validatePage3()) {
        return;
      }
    }
    else if (this.state.pageNum == 4) {
      //if (!this.validatePage2()) {
        //this.setState({showingPopover: true, popoverTitle: 'WHOOPS', popoverDetails: 'Must select at least 1 printer'})
        //return;
      //}
      if (!this.validatePage4()) {
        return;
      }
    }

    // HANDLE STUFF
    if (this.state.pageNum == 1) {
      if (!this.state.verifiedEmail) {
        this.handleAfterPage1();
        return;
      }
      this.handleAfterPage1_part2();
    }
    else if (this.state.pageNum == 2) {
      this.pushUserDataToFirebase(false);
    }
    else if (this.state.pageNum == 3) {
      this.pushUserDataToFirebase(false);
      this.updateShopifyUser();
    }
    else if (this.state.pageNum == 4) {
      if (!this.state.authNetVerifiedCard) {
        this.handleAfterPage4();
        return;
      }
      this.pushUserDataToFirebase(false);
    }
    else if (this.state.pageNum == 5) {
      this.sendSignupEmail();
    }

    // skip page 5
    /*
    if (this.state.pageNum == 4) {
      this.sendSignupEmail();
      this.setState({ pageNum: this.state.pageNum + 1 });
    }
    */

    this.scrollToTop(2.0);
    this.setState({ pageNum: this.state.pageNum + 1 });
    if (this.state.pageNum <= 5) {
      this.startTimer();
    }
  }

  sendFailedPaymentEmail(message) {
    var user = firebaseDbHelper.getCurrentUser();
    var email = this.state.email;
    var firstName = this.state.firstName;
    var lastName = this.state.lastName;
    var phoneNum = this.state.phoneNum;
    var authNetProfileId = this.state.authNetProfileId;
    var authNetPaymentId = this.state.authNetPaymentId;
    //var message = response.message;
    var uuid = user['uid'];
    window.emailjs.send(
      'mailgun',
      process.env.REACT_APP_EMAILJS_TEMPLATEID_DUPLICATE_PAYMENT,
      { email, firstName, lastName, phoneNum, authNetProfileId, authNetPaymentId, message, uuid }
    )
  }

  sendRegisteredEmail() {
    // USER SIGNED UP! SEND EMAIL
    var user = firebaseDbHelper.getCurrentUser();
    var email = this.state.email;
    var firstName = this.state.firstName;
    var lastName = this.state.lastName;
    var phoneNum = this.state.phoneNum;
    var uuid = user['uid'];
    window.emailjs.send(
      'mailgun',
      process.env.REACT_APP_EMAILJS_TEMPLATEID_REGISTERED,
      { email, firstName, lastName, phoneNum, uuid,  }
    )
  }

  sendSignupEmail() {
    // USER SIGNED UP! SEND EMAIL
    var user = firebaseDbHelper.getCurrentUser();
    var email = this.state.email;
    var firstName = this.state.firstName;
    var lastName = this.state.lastName;
    var phoneNum = this.state.phoneNum;
    var address = this.state.firstName + " " + this.state.lastName + "<br/>" + this.state.addressName + "<br/>" + this.state.address1 + ", " + this.state.address2 + "<br/>" + this.state.city + ", " + this.state.addrState + " " + this.state.zipCode;
    var pfGroupId = this.state.pfGroupId;
    var pfDcaId = this.state.pfDcaId;
    var uuid = user['uid'];
    var pfPrinters = ""
    for (var i = 0; i < this.state.printersEntered.length; i++) {
      let printer = this.state.printersEntered[i];
      //var str = printer.name + " , " + printer.serialNumber + " , " + printer.ipAddress + " , " + printer.id + "<br>";
      var str = printer + "  <br/>  ";
      pfPrinters = pfPrinters + str;
    }
    window.emailjs.send(
      'mailgun',
      process.env.REACT_APP_EMAILJS_TEMPLATEID_SIGNUP,
      { email, firstName, lastName, phoneNum, uuid, address, pfGroupId, pfDcaId, pfPrinters }
    )
  }

  renderPopover() {
    return (
      <div className="popoverDiv centerStuffClass">
        <div className="popoverDivInner centerStuffClass" style={{'left': 'calc(50vw - 220px)'}}>
          <div style={{'margin': '16px 12px'}}>
            <h2>{this.state.popoverTitle}</h2>
            <p>{this.state.popoverDetails}</p>

            <div style={{'marginTop': '35px'}}>
              <Button style={{'padding':"12px 38px", 'margin': '0 auto'}} bsStyle="primary" bsSize="large" onClick={this.didTapAlertOk.bind(this)}>OK</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  didTapAlertOk() {
    this.setState({ showingPopover: false });
  }

  renderMainPane()
  {
    if (this.state.pageNum == 1) {
      return this.renderPage1();
    }
    else if (this.state.pageNum == 2) {
      return this.renderPage2();
    }
    else if (this.state.pageNum == 3) {
      return this.renderPage3();
    }
    else if (this.state.pageNum == 4) {
      return this.renderPage4();
    }
    else if (this.state.pageNum == 5) {
      //this.handleAfterPage5();
      return this.renderPage5();
    }
    else if (this.state.pageNum == 6) {
      return this.renderConfirmationPage();
    }
  }


  renderPage1() {
    return  (
      <div>
        <h2 className="centerStuffClass"><b>User Info</b></h2>
        <Form className="form" onSubmit={ (e) => null }>
            <Col>
              <td>
                <FormGroup style={{'width': '95%'}}>
                  <Label>First Name</Label>
                  <Input style={{'padding-right': '50px'}}
                    type = "text"
                    name = "firstName"
                    id = "firstName"
                    value={ this.state.firstName }
                    valid={ this.state.validate.firstNameState === 'has-success' }
                    invalid={ this.state.validate.firstNameState === 'has-danger' }
                    onChange={ (e) => { this.handleChange(e) } }
                    onBlur={ (e) => { this.validateLabel(e) }}
                  />
                </FormGroup>
              </td>
              <td>
                <FormGroup style={{'width': '95%', 'margin-left': '5%'}}>
                  <Label>Last Name</Label>
                  <Input style={{'padding-right': '50px'}}
                    type = "text"
                    name = "lastName"
                    id = "lastName"
                    value={ this.state.lastName }
                    valid={ this.state.validate.lastNameState === 'has-success' }
                    invalid={ this.state.validate.lastNameState === 'has-danger' }
                    onChange={ (e) => { this.handleChange(e) }}
                    onBlur={ (e) => { this.validateLabel(e) }}
                  />
                {/* <FormFeedback valid>Cool name!</FormFeedback> */}
                </FormGroup>
              </td>
            </Col>
            <Col>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  type = "email"
                  name = "email"
                  id = "email"
                  value={ this.state.email }
                  valid={ this.state.validate.emailState === 'has-success' }
                  invalid={ this.state.validate.emailState === 'has-danger' }
                  onChange={ (e) => { this.handleChange(e) }}
                  onBlur={ (e) => { this.validateLabel(e) }}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Phone Number</Label>
                <Input
                  type = "text"
                  name = "phoneNum"
                  id = "phoneNum"
                  value={ this.formatPhoneNumber(this.state.phoneNum) }
                  valid={ this.state.validate.phoneNumState === 'has-success' }
                  invalid={ this.state.validate.phoneNumState === 'has-danger' }
                  onChange={ (e) => { this.handleChange(e) }}
                  onBlur={ (e) => { this.validateLabel(e) }}
                />
              </FormGroup>
            </Col>
            <Col>
              <td>
                <FormGroup style={{'width': '95%'}}>
                  <Label>Password</Label>
                  <Input style={{'padding-right': '50px'}}
                    type="password"
                    name="password"
                    id="password"
                    placeholder=""
                    value={ this.state.password }
                    valid={ this.state.validate.passwordState === 'has-success' }
                    invalid={ this.state.validate.passwordState === 'has-danger' }
                    onChange={ (e) => { this.handleChange(e) }}
                    onBlur={ (e) => { this.validateLabel(e) }}
                />
                </FormGroup>
              </td>
              <td>
                <FormGroup style={{'width': '95%', 'margin-left': '5%'}}>
                  <Label>{this.state.isMobile ? 'Pwd Confirm' : 'Password Confirm'}</Label>
                  <Input style={{'padding-right': '50px'}}
                    type="password"
                    name="passwordConfirm"
                    id="passwordConfirm"
                    placeholder=""
                    value={ this.state.passwordConfirm }
                    valid={ this.state.validate.passwordConfirmState === 'has-success' }
                    invalid={ this.state.validate.passwordConfirmState === 'has-danger' }
                    onChange={ (e) => { this.handleChange(e) }}
                    onBlur={ (e) => { this.validateLabel(e) }}
                />
                </FormGroup>
              </td>
              <FormText style={{'marginTop': '-8px'}} className="passwordHintText">Password must:</FormText>
              <FormText className="passwordHintText">   - be at least 8 characters</FormText>
              <FormText className="passwordHintText">   - contain one lowercase letter, uppercase letter, and number</FormText>
            </Col>
            <br/>

            {(this.state.verifyingEmail) ?
              <Col>
              <div className="centerStuffClass">
                <br/><br/>
                <h2><b>VERIFYING EMAIL...</b></h2>
                <ScaleLoader loading={true} color="#27AAEA" size="16px" margin="4px"/>
              </div>
              </Col>
              :
              null
            }

            {!this.state.verifyingEmail ?
              <div>
                <Col>
                  <div style={{'height':'32px'}} />
                </Col>
                <div className="centerStuffClass" style={{'marginTop':'0px'}}>
                  <br/><br/><br/>
                  <p className="mainText" style={{'lineHeight':'25px', 'fontSize':'0.85em'}}>By creating an account, you agree<br/>to our <Link to="/terms-and-conditions" target="_blank">terms & conditions</Link></p>
                  <br/>
                </div>
              </div>
            :
              null
            }
          </Form>

        </div>
    )
  }

  testFunction() {
    console.log("ayds");
  }

  async handleAfterPage1() {

    this.setState({ verifyingEmail: true });

    if (!this.state.hasCreatedAccount) {
        await this.attemptCreateUserFirebase(true);
        if (this.state.hasCreatedAccount) {
          this.sendRegisteredEmail();
          this.postUserToShopify();
        }
    }

    if (this.state.hasCreatedAccount) {
      await this.pushUserDataToFirebase(false);
      if (this.state.uploadedToFirebaseInit)   {
        this.setState({ verifiedEmail: true, verifyingEmail: false });
        this.didClickNextPage();
      }
    }
    this.setState({ verifyingEmail: false });
  }

  async handleAfterPage1_part2()
  {
    if ((this.state.pfGroupId != "") && (this.state.pfDcaId != "")) {
      //this.checkDcaStatus();
      return;
    }

    //if (pfGroupName == null) { }
    let groupName = this.state.lastName + '_' + this.state.firstName;
    let groupId = await printFleetHelper.createPfGroup(groupName);
    this.setState({ pfGroupId: groupId });

    if (groupId) {
      let dcaResponse = await printFleetHelper.createPfDCA(groupName, groupId);
      if (dcaResponse) {
        this.setState({
          pfDcaId: dcaResponse.dcaId,
          pfDcaURLpc: dcaResponse.dcaURLpc,
          pfDcaURLmac: dcaResponse.dcaURLmac,
        });
        // console.log("*************************************");
        // console.log("************ DCA CREATED ************");
        // console.log("*************************************");

        await this.pushUserDataToFirebase(false);

        //setTimeout(function() {
            //this.checkDcaStatus();
        //}.bind(this), 18000);
      }
    }
  }

  async checkDcaStatus() {
    if (this.state.pfHasDca) {
      //if (!this.state.pfDoneCheckingPrinters) {
        if (this.state.pfNumChecks >= 65) {
          //this.setState({ pfDoneCheckingPrinters: true });
        }
        let printers = await printFleetHelper.getPfDcaDevices(this.state.pfGroupId);
        this.setState({ pfPrinters: printers, pfNumChecks: this.state.pfNumChecks+1 });
        // console.log("*************************************");
        // console.log(printers);
        // console.log("*************************************");
      //}
    }
    else {
      let isActivated = await printFleetHelper.getPfDcaIsActivated(this.state.pfGroupId);
      let isConnected = await printFleetHelper.getPfDcaIsConnected(this.state.pfDcaId);
      this.setState({
        pfDcaIsConnected: isConnected,
        getPfDcaIsActivated: isActivated,
        pfHasDca: isActivated, // && isConnected;
      });
      // console.log("*************************************");
      // console.log("DCA IS ACTIVATED : " + isActivated);
      // console.log("DCA IS CONNECTED : " + isConnected);
      // console.log("*************************************");
    }


    if (this.state.pageNum >= 2 && this.state.pageNum <= 4) {
      setTimeout(function() {
          //this.checkDcaStatus();
      }.bind(this), 5000);
    }
  }



  renderPage2() {
    return  (
      <div className="centerStuffClass">
        <div>
          <h2><b>Add your printer(s)</b></h2>
          <Form className="form" onSubmit={this.preventSubmit}>
            <Col style={{'width': '95%', 'margin':'0 px'}}>
              <div className="centerStuffClass suggestHolder">
              <td>
                <FormGroup style={{'textAlign': 'left', 'lineHeight': '1.0em', 'min-width': '110px',  'margin-right': '5%', 'width': '98%' }}>
                  <Label>Brand</Label>
                  <Input style={{'padding-right': '50px'}}
                    type = "select"
                    name = "printerBrand"
                    id = "printerBrand"
                    value={ this.state.printerBrand }
                    onChange={ (e) => { this.handleChange(e) }}
                    invalid={ this.state.printerBrandEmpty === 'has-danger' }
                    onBlur={ (e) => { this.validateLabel(e) }}
                  >
                    <option value="">--</option>
                    <option value="Brother">Brother</option>
                    <option value="Canon">Canon</option>
                    <option value="Dell">Dell</option>
                    <option value="Epson">Epson</option>
                    <option value="HP">HP</option>
                    <option value="Kodak">Kodak</option>
                  </Input>
                </FormGroup>
              </td>
              <td>
                <FormGroup style={{'textAlign': 'left', 'lineHeight': '1.0em', 'width': '100%', 'min-width': '110px', 'margin-left': '5%'}}>
                  <Label>Model</Label>
                  <Input style={{'padding-right': '50px'}}
                    type = "text"
                    placeholder = {this.state.isMobile ? "Search..." : "Search model..."}
                    name = "printerModel"
                    id = "printerModel"
                    value={ this.state.printerModel }
                    onChange={ (e) => { this.handleChange(e) }}
                    invalid={ this.state.printerModelEmpty === 'has-danger' }
                    onKeyPress={event => {
                    }}
                  />
                </FormGroup>
              </td>
              </div>
            </Col>
            {/*<Button height='25px' style={{'padding':"12px 30px", 'margin': '0 auto'}} onClick={this.didAddPrinter.bind(this)}>ADD PRINTER</Button>*/}
          </Form>

          <div className="centerStuffClass autoSuggestTable" style={{'height': this.state.isMobile ? '180px' : '265px' }}>
            <table className="centerStuffClass">
              <tbody>
                {this.state.brandProductsFiltered.map((product, i) => {
                  return (
                    <tr className="autoSuggestTr" >
                      <td id="suggestTableName">
                        <p>{product}</p>
                      </td>
                      <td id="suggestTableButton">
                        <Button height='16px' style={{'fontSize': this.state.isMobile ? '0.670em' : '0.70em', 'padding': this.state.isMobile ? "6px 10px" : "8px 10px", 'margin': '0 auto'}} onClick={this.didAddPrinter.bind(this, product)}>ADD PRINTER</Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <br/>
          {this.state.printersEntered.length > 0 ?
            <div id="orderInfoDiv">
              {this.state.isMobile ?
                <table className="printersTable printersTable2 centerStuffClass" style={{'width':0.9*window.innerWidth}}>
                  <tbody>
                    {this.state.printersEntered.map((printer, i) => {
                      return (
                        <tr>
                          <td style={{'width':0.15*window.innerWidth, 'minWidth':0.15*window.innerWidth, 'maxWidth':0.15*window.innerWidth}}>
                            <IconL icon={print} />
                          </td>
                          <td style={{'width':0.5*window.innerWidth, 'minWidth':0.5*window.innerWidth, 'maxWidth':0.5*window.innerWidth}}>
                            <div>
                              <p><b>{printer}</b></p>
                            </div>
                          </td>
                          <td style={{'width':0.15*window.innerWidth, 'minWidth':0.15*window.innerWidth, 'maxWidth':0.15*window.innerWidth}}>
                            <Button height='15px' style={{'padding':"6px 16px"}} onClick={this.didRemovePrinter.bind(this, i)}><IconS icon={minus} /></Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              :
                <table className="printersTable printersTable2 centerStuffClass">
                  <tbody>
                    {this.state.printersEntered.map((printer, i) => {
                      return (
                        <tr>
                          <td className="printersTableCol1">
                            <IconL icon={print} />
                          </td>
                          <td className="printersTableCol2">
                            <div>
                              <p><b>{printer}</b></p>
                            </div>
                          </td>
                          <td className="printersTableCol3">
                            <Button height='15px' style={{'padding':"6px 16px"}} onClick={this.didRemovePrinter.bind(this, i)}><IconS icon={minus} /></Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              }
            </div>
          :
            null
          }
        </div>

      </div>
    )
  }
  preventSubmit(e) {
    e.preventDefault();
  }
  didAddPrinter(name) {
    if (this.state.printerBrand == "" || name == "") {
      if (this.state.printerBrand == "") { this.setState({ printerBrandEmpty: 'has-danger'}); }
      if (name == "") { this.setState({ printerModelEmpty: 'has-danger'}); }
      return;
    }
    this.setState({ printersEntered: this.state.printersEntered.reverse().concat(this.state.printerBrand + " " + name).reverse() });
  }
  didRemovePrinter(index) {
    this.setState({ printersEntered: this.state.printersEntered.filter((_, i) => i !== index) });
  }

  renderPage2_old() {
    return  (
      <div className="centerStuffClass">
        {((this.state.pfGroupId == "") || (this.state.pfDcaId == "")) ?
          <div>
            <br/>
            <h2><b>Generating DCA</b></h2>
            <div className="centerStuffClass">
              <ScaleLoader loading={true} color="#27AAEA" size="16px" margin="4px"/>
            </div>
          </div>
          :
        (!this.state.pfHasDca) ?
          <div>
            <br/>
            <h2><b>Download and Install the DCA</b></h2>
            <h3 className="twoButtonStyle twoButtonStyleL"><a href={this.state.pfDcaURLpc}><b>WINDOWS</b></a></h3>
            <h3 className="twoButtonStyle twoButtonStyleR"><a href={this.state.pfDcaURLmac}><b>MAC</b></a></h3>
            <br/><br/>
          </div>
          :
          <div>
            <div>
              <br/>
              <h2><b>DCA connected!</b></h2>
              <h2><b>Data Collector Agent is scanning your local network for printers</b></h2>
              <h2 className="mainText">It will also monitor your ink and toner levels so we can<br/>ship you more when you are close to running out!</h2>
              <br/>
              <h2>Make sure printers are <u>turned on</u> and <u>awake</u></h2>
              <br/>
            </div>
            <div style={{'borderTop': '1px solid black'}}>
              <h2 className="mainText">This usually takes just a couple minutes,<br/>but can occasionally take up to 10-15 minutes</h2>

              <h2 className="mainText">Please continue to the next page of the signup.<br/>You will be able to select your printer(s) when the scan has completed</h2>
            </div>
          </div>
        }

        {(!this.state.pfHasDca) ?
          <div>
            <br/><br/>
            <h2><b>Data Collector Agent will scan your local network for printers</b></h2>
            <h2 className="mainText">It will also monitor your ink and toner levels so we can<br/>ship you more when you are close to running out!</h2>
            <br/>
            <h2>Make sure printers are <u>turned on</u> and <u>awake</u></h2>
          </div>
          :
          null
        }
        <br/>
      </div>
    )
  }


  didSelectPfPrinter(printer) {
    if (printer.name == null) { return; }

    let index = this.printerIsSelected(printer);
    if (index >= 0) {
      this.setState({ pfPrintersSelected: this.state.pfPrintersSelected.filter((x,i) => i != index) });
    }
    else {
      this.setState({ pfPrintersSelected: this.state.pfPrintersSelected.concat(printer) });
    }
  }

  printerIsSelected(printer) {
    for (var i = 0; i < this.state.pfPrintersSelected.length; i++) {
        if (this.state.pfPrintersSelected[i].serialNumber == printer.serialNumber) {
            return i;
        }
    }
    return -1;
  }

  renderPage3() {
    return  (
      <div>
        <h2 className="centerStuffClass"><b>Shipping Info</b></h2>
        <Form className="form" onSubmit={ (e) => null }>
            <Col>
              <FormGroup>
                <Label>Company Name (optional)</Label>
                <Input
                  type = "text"
                  name = "addressName"
                  id = "addressName"
                  value={ this.state.addressName }
                  onChange={ (e) => { this.handleChange(e) }}
                  onBlur={ (e) => { this.validateLabel(e) }}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Address 1</Label>
                <Input
                  type = "text"
                  name = "address1"
                  id = "address1"
                  value={ this.state.address1 }
                  valid={ this.state.validate.address1State === 'has-success' }
                  invalid={ this.state.validate.address1State === 'has-danger' }
                  onChange={ (e) => { this.handleChange(e) }}
                  onBlur={ (e) => { this.validateLabel(e) }}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Address 2</Label>
                <Input
                  type = "text"
                  name = "address2"
                  id = "address2"
                  value={ this.state.address2 }
                  onChange={ (e) => { this.handleChange(e) } }
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>City</Label>
                <Input
                  type = "text"
                  name = "city"
                  id = "city"
                  value={ this.state.city }
                  valid={ this.state.validate.cityState === 'has-success' }
                  invalid={ this.state.validate.cityState === 'has-danger' }
                  onChange={ (e) => { this.handleChange(e) }}
                  onBlur={ (e) => { this.validateLabel(e) }}
                />
              </FormGroup>
            </Col>
            <Col>
              <td>
                <FormGroup style={{'width': '94%', 'min-width': '110px', 'margin-right': '6%'}}>
                  <Label>State</Label>
                  <Input
                    type = "select"
                    name = "addrState"
                    id = "addrState"
                    value={ this.state.addrState }
                    invalid={ this.state.validate.addrStateState === 'has-danger' }
                    onChange={ (e) => { this.handleChange(e) }}
                    onBlur={ (e) => { this.validateLabel(e) }}
                  >
                    <option value="">--</option>
                    <option value="AK">AK</option>
                    <option value="AL">AL</option>
                    <option value="AR">AR</option>
                    <option value="AZ">AZ</option>
                    <option value="CA">CA</option>
                    <option value="CO">CO</option>
                    <option value="CT">CT</option>
                    <option value="DE">DE</option>
                    <option value="FL">FL</option>
                    <option value="GA">GA</option>
                    <option value="HI">JI</option>
                    <option value="IA">IA</option>
                    <option value="ID">ID</option>
                    <option value="IL">IL</option>
                    <option value="IN">IN</option>
                    <option value="KS">KS</option>
                    <option value="KY">KY</option>
                    <option value="LA">LA</option>
                    <option value="MA">MA</option>
                    <option value="MD">MD</option>
                    <option value="ME">ME</option>
                    <option value="MI">MI</option>
                    <option value="MN">MN</option>
                    <option value="MO">MO</option>
                    <option value="MS">MS</option>
                    <option value="MT">MT</option>
                    <option value="NC">NC</option>
                    <option value="ND">ND</option>
                    <option value="NE">NE</option>
                    <option value="NH">NH</option>
                    <option value="NJ">NJ</option>
                    <option value="NM">NM</option>
                    <option value="NV">NV</option>
                    <option value="NY">NY</option>
                    <option value="OH">OH</option>
                    <option value="OK">OK</option>
                    <option value="OR">OR</option>
                    <option value="PA">PA</option>
                    <option value="RI">RI</option>
                    <option value="SC">SC</option>
                    <option value="SD">SD</option>
                    <option value="TN">TN</option>
                    <option value="TX">TX</option>
                    <option value="UT">UT</option>
                    <option value="VA">VA</option>
                    <option value="VT">VT</option>
                    <option value="WA">WA</option>
                    <option value="WI">WI</option>
                    <option value="WV">WV</option>
                    <option value="WY">WY</option>
                  </Input>
                </FormGroup>
              </td>
              <td>
                <FormGroup style={{'width': '94%', 'margin-left': '6%', 'margin-right': '3%'}}>
                  <Label>Zip Code</Label>
                  <Input
                    type="text"
                    name="zipCode"
                    id="zipCode"
                    value={ this.state.zipCode }
                    valid={ this.state.validate.zipCodeState === 'has-success' }
                    invalid={ this.state.validate.zipCodeState === 'has-danger' }
                    onChange={ (e) => { this.handleChange(e) }}
                    onBlur={ (e) => { this.validateLabel(e) }}
                />
                </FormGroup>
              </td>
              <td>
                <FormGroup style={{'width': '94%', 'margin-left': '6%'}}>
                  <Label>Country</Label>
                  <Input
                    type="text"
                    name="country"
                    id="country"
                    value={ this.state.country }
                    valid={ this.state.validate.countryState === 'has-success' }
                    invalid={ this.state.validate.countryState === 'has-danger' }
                    onBlur={ (e) => { this.validateLabel(e) }}
                />
                </FormGroup>
              </td>
            </Col>

            <br/>

          </Form>
        </div>
    )
  }

  renderPage4() {
    return  (
      <div>
        <h2 className="centerStuffClass"><b>Billing Info</b></h2>
        <div className="paymentPlanDiv paymentPlanL">
          <h2><b>Monthly</b></h2>
          <p>$10/month</p>
          <p>Complete set of ink shipped immediately</p>
          <p>After 12 months, cancel any time</p>
          <Button height='15px' style={{'padding':"6px 14px", 'margin': '0 auto'}} onClick={this.didSelectPaymentPlan.bind(this, 1)}>{this.state.paymentMethod == 1 ? <IconXS icon={check} /> : "SELECT"}</Button>
          <p></p>
        </div>
        <div className="paymentPlanDiv paymentPlanR">
          <h2><b>Annually</b></h2>
          <p>$75 first year, $120 each year after</p>
          <p>Complete set of ink shipped immediately</p>
          <p>Cancel any time</p>
          <Button height='15px' style={{'padding':"6px 14px", 'margin': '0 auto'}} onClick={this.didSelectPaymentPlan.bind(this, 2)}>{this.state.paymentMethod == 2 ? <IconXS icon={check} /> : "SELECT"}</Button>
          <p></p>
        </div>
        <br/><br/><br/><br/><br/><br/><br/><br/>
        <Form className="form" onSubmit={ (e) => null }>
            <Col>
              <FormGroup>
                <Label>Card Number</Label>
                <Input
                  type = "text"
                  name = "cardNumber"
                  id = "cardNumber"
                  value={ this.formatCardNumber(this.state.cardNumber) }
                  valid={ this.state.validate.cardNumberState === 'has-success' }
                  invalid={ this.state.validate.cardNumberState === 'has-danger' }
                  onChange={ (e) => { this.handleChange(e) }}
                  onBlur={ (e) => { this.validateLabel(e) }}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Name on Card</Label>
                <Input
                  type = "text"
                  name = "cardName"
                  id = "cardName"
                  value={ this.state.cardName }
                  valid={ this.state.validate.cardNameState === 'has-success' }
                  invalid={ this.state.validate.cardNameState === 'has-danger' }
                  onChange={ (e) => { this.handleChange(e) }}
                  onBlur={ (e) => { this.validateLabel(e) }}
                />
              </FormGroup>
            </Col>
            <Col>
              <td>
                <FormGroup style={{'width': '30%', 'min-width': '105px', 'margin-right': '10px'}} controlId="formControlsSelect">
                  <Label>Month</Label>
                  <Input type="select" className="centerStuffClass"
                    name = "cardMonth"
                    id = "cardMonth"
                    value={ this.state.cardMonth }
                    invalid={ this.state.validate.cardMonthState === 'has-danger' }
                    onChange={ (e) => { this.handleChange(e) }}
                    onBlur={ (e) => { this.validateLabel(e) }}
                    >
                    <option value="">--</option>
                    <option value="01">01</option>
                    <option value="02">02</option>
                    <option value="03">03</option>
                    <option value="04">04</option>
                    <option value="05">05</option>
                    <option value="06">06</option>
                    <option value="07">07</option>
                    <option value="08">08</option>
                    <option value="09">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </Input>
                </FormGroup>
              </td>
              <td>
                <FormGroup style={{'width': '30%', 'min-width': '105px', 'margin-left': '10px', 'margin-right': '50px'}} controlId="formControlsSelect">
                  <Label>Year</Label>
                  <Input type="select" className="centerStuffClass"
                    name="cardYear"
                    id="cardYear"
                    value={ this.state.cardYear }
                    invalid={ this.state.validate.cardYearState === 'has-danger' }
                    onChange={ (e) => { this.handleChange(e) }}
                    onBlur={ (e) => { this.validateLabel(e) }}
                    >
                    <option value="">--</option>
                    <option value="19">2019</option>
                    <option value="20">2020</option>
                    <option value="21">2021</option>
                    <option value="22">2022</option>
                    <option value="23">2023</option>
                    <option value="24">2024</option>
                    <option value="25">2025</option>
                    <option value="26">2026</option>
                    <option value="27">2027</option>
                  </Input>
                </FormGroup>
              </td>
              {!this.state.isMobile ?
                <td>
                  <FormGroup style={{'width': '95%', 'marginLeft': '10px'}}>
                    <Label>CVV</Label>
                    <Input style={{'padding-right': '50px'}}
                      type = "text"
                      name = "cardCvc"
                      id = "cardCvc"
                      value={ this.state.cardCvc }
                      valid={ this.state.validate.cardCvcState === 'has-success' }
                      invalid={ this.state.validate.cardCvcState === 'has-danger' }
                      onChange={ (e) => { this.handleChange(e) }}
                      onBlur={ (e) => { this.validateLabel(e) }}
                    />
                  </FormGroup>
                </td>
              : null }
            </Col>
            {this.state.isMobile ?
              <Col>
                <FormGroup style={{'width': '135px'}}>
                  <Label>CVV</Label>
                  <Input style={{'padding-right': '50px'}}
                    type = "text"
                    name = "cardCvc"
                    id = "cardCvc"
                    value={ this.state.cardCvc }
                    valid={ this.state.validate.cardCvcState === 'has-success' }
                    invalid={ this.state.validate.cardCvcState === 'has-danger' }
                    onChange={ (e) => { this.handleChange(e) }}
                    onBlur={ (e) => { this.validateLabel(e) }}
                  />
                </FormGroup>
              </Col>
            : null
            }

            <div className="centerStuffClass" >
              <img src={require('../imgs/visa.png')} className="creditCardIcon" id="visa" />
              <img src={require('../imgs/mastercard.png')} className="creditCardIcon" id="mastercard" />
              <img src={require('../imgs/amex.png')} className="creditCardIcon" id="amex" />
              <img src={require('../imgs/discover.png')} className="creditCardIcon" id="discover" />
            </div>

            <br/>
            <Col>
              <FormGroup style={{'margin-top': '5px'}} check>
                <Label check>
                  <Input
                    type="checkbox"
                    name="b_same"
                    id="b_same"
                    checked={ this.state.b_same }
                    onChange={ (e) => { this.handleChange(e) }}
                  />
                  <p style={{'margin-top': '0.20em'}}>Billing address is same as shipping address</p>
                </Label>
              </FormGroup>
            </Col>

            {!this.state.b_same ?
              <div>{this.renderPage4address()}</div>
            :
              null
            }

            {(this.state.verifyingCard) ?
              <div className="centerStuffClass marginTopClass">
                <h2><b>VERIFYING CARD...</b></h2>
                <ScaleLoader loading={true} color="#27AAEA" size="16px" margin="4px"/>
              </div>
              :
              <div className="centerStuffClass marginTopClass">
                <p style={{'marginTop': '28px', 'marginBottom': '12px', 'lineHeight': '1.0em'}}><b>You will not be charged until you confirm signup.</b></p>
                <p style={{'marginTop': '3px', 'marginBottom': '25px', 'lineHeight': '1.0em'}}><b>A $0.01 validation charge may appear briefly</b></p>
                <img src={require('../imgs/authNetSeal.gif')} className="authNetIcon" id="discover" />
              </div>
            }

          </Form>
        </div>
    )
  }

  renderPage4address() {
    return (
      <div>
      <Col>
        <FormGroup>
          <Label>Address 1</Label>
          <Input
            type = "text"
            name = "b_address1"
            id = "b_address1"
            value={ this.state.b_address1 }
            valid={ this.state.validate.b_address1State === 'has-success' }
            invalid={ this.state.validate.b_address1State === 'has-danger' }
            onChange={ (e) => { this.handleChange(e) }}
            onBlur={ (e) => { this.validateLabel(e) }}
          />
        </FormGroup>
      </Col>
      <Col>
        <FormGroup>
          <Label>Address 2</Label>
          <Input
            type = "text"
            name = "b_address2"
            id = "b_address2"
            value={ this.state.b_address2 }
            onChange={ (e) => { this.handleChange(e) } }
          />
        </FormGroup>
      </Col>
      <Col>
        <FormGroup>
          <Label>City</Label>
          <Input
            type = "text"
            name = "b_city"
            id = "b_city"
            value={ this.state.b_city }
            valid={ this.state.validate.b_cityState === 'has-success' }
            invalid={ this.state.validate.b_cityState === 'has-danger' }
            onChange={ (e) => { this.handleChange(e) }}
            onBlur={ (e) => { this.validateLabel(e) }}
          />
        </FormGroup>
      </Col>
      <Col>
        <td>
          <FormGroup style={{'width': '94%', 'min-width': '110px', 'margin-right': '6%'}}>
            <Label>State</Label>
            <Input
              type = "select"
              name = "b_addrState"
              id = "b_addrState"
              value={ this.state.b_addrState }
              invalid={ this.state.validate.b_addrStateState === 'has-danger' }
              onChange={ (e) => { this.handleChange(e) }}
              onBlur={ (e) => { this.validateLabel(e) }}
            >
              <option value="">--</option>
              <option value="AK">AK</option>
              <option value="AL">AL</option>
              <option value="AR">AR</option>
              <option value="AZ">AZ</option>
              <option value="CA">CA</option>
              <option value="CO">CO</option>
              <option value="CT">CT</option>
              <option value="DE">DE</option>
              <option value="FL">FL</option>
              <option value="GA">GA</option>
              <option value="HI">JI</option>
              <option value="IA">IA</option>
              <option value="ID">ID</option>
              <option value="IL">IL</option>
              <option value="IN">IN</option>
              <option value="KS">KS</option>
              <option value="KY">KY</option>
              <option value="LA">LA</option>
              <option value="MA">MA</option>
              <option value="MD">MD</option>
              <option value="ME">ME</option>
              <option value="MI">MI</option>
              <option value="MN">MN</option>
              <option value="MO">MO</option>
              <option value="MS">MS</option>
              <option value="MT">MT</option>
              <option value="NC">NC</option>
              <option value="ND">ND</option>
              <option value="NE">NE</option>
              <option value="NH">NH</option>
              <option value="NJ">NJ</option>
              <option value="NM">NM</option>
              <option value="NV">NV</option>
              <option value="NY">NY</option>
              <option value="OH">OH</option>
              <option value="OK">OK</option>
              <option value="OR">OR</option>
              <option value="PA">PA</option>
              <option value="RI">RI</option>
              <option value="SC">SC</option>
              <option value="SD">SD</option>
              <option value="TN">TN</option>
              <option value="TX">TX</option>
              <option value="UT">UT</option>
              <option value="VA">VA</option>
              <option value="VT">VT</option>
              <option value="WA">WA</option>
              <option value="WI">WI</option>
              <option value="WV">WV</option>
              <option value="WY">WY</option>
            </Input>
          </FormGroup>
        </td>
        <td>
          <FormGroup style={{'width': '94%', 'margin-left': '6%', 'margin-right': '3%'}}>
            <Label>Zip Code</Label>
            <Input
              type="text"
              name="b_zipCode"
              id="b_zipCode"
              value={ this.state.b_zipCode }
              valid={ this.state.validate.b_zipCodeState === 'has-success' }
              invalid={ this.state.validate.b_zipCodeState === 'has-danger' }
              onChange={ (e) => { this.handleChange(e) }}
              onBlur={ (e) => { this.validateLabel(e) }}
          />
          </FormGroup>
        </td>
        <td>
          <FormGroup style={{'width': '94%', 'margin-left': '6%'}}>
            <Label>Country</Label>
            <Input
              type="text"
              name="b_country"
              id="b_country"
              value={ this.state.b_country }
              valid={ this.state.validate.b_countryState === 'has-success' }
              invalid={ this.state.validate.b_countryState === 'has-danger' }
              onBlur={ (e) => { this.validateLabel(e) }}
          />
          </FormGroup>
        </td>
      </Col>
      </div>
    )
  }

  didSelectPaymentPlan(i) {
    this.setState({ paymentMethod: i });
  }


  async handleAfterPage4() {
    if (this.state.authNetVerifiedCard) {
      this.didClickNextPage();
      return;
    }

    this.setState({ verifyingCard: true });

    let cardInfo = {
      cardNumber: this.state.cardNumber,
      cardMonth: this.state.cardMonth,
      cardYear: this.state.cardYear,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phoneNum: this.state.phoneNum,
      address1: this.state.b_same ? this.state.address1 : this.state.b_address1,
      address2: this.state.b_same ? this.state.address2 : this.state.b_address2,
      city: this.state.b_same ? this.state.city : this.state.b_city,
      addrState: this.state.b_same ? this.state.addrState : this.state.b_addrState,
      zipCode: this.state.b_same ? this.state.zipCode : this.state.b_zipCode,
      country: this.state.b_same ? this.state.country : this.state.b_country,

      email: this.state.email,
    };


    authorizeNetHelper.createCustomerProfile(cardInfo, function(response) {
      if (response.success) {
        // console.log("*************************************");
        // console.log("***** AUTH.NET CUSTOMER CREATED ******");
        // console.log("*************************************");
        this.setState({ authNetProfileId: response.profileId, authNetPaymentId: response.paymentId });

        authorizeNetHelper.validateCustomerPaymentProfile(this.state.authNetProfileId, this.state.authNetPaymentId, this.state.cardCvc, function(response2) {
          if (response2.success) {
            this.setState({ authNetVerifiedCard: true });
            // console.log("*************************************");
            // console.log("***** AUTH.NET PAYMENT VERIFIED *****");
            // console.log("*************************************");
            setTimeout(function() {
              this.createCustomerProfileSuccess();
            }.bind(this), 300);
          } else {
            this.createCustomerProfileFailed(response2.message);
          }
        }.bind(this));
      } else {
        this.createCustomerProfileFailed(response.message);
      }
    }.bind(this));
  }

  createCustomerProfileSuccess() {
    this.setState({ verifyingCard: false });
    this.didClickNextPage();
  }

  createCustomerProfileFailed(message) {
    this.sendFailedPaymentEmail(message);
    this.setState({ verifyingCard: false });
    this.setState({showingPopover: true, popoverTitle: 'WHOOPS', popoverDetails: message})
    this.scrollToTop(2.0);
  }

  renderPage5() {
    const priceToUse = this.state.paymentMethod == 1 ? 10.0 : 75.0;
    return  (
      <div>
        <h2 className="centerStuffClass"><b>Confirm Signup</b></h2>
        <div id="orderInfoDiv" className="">
          {this.state.isMobile ?
            <table className="printersTable printersTable2 centerStuffClass" style={{'width':0.9*window.innerWidth}}>
              <tbody>
                {this.state.printersEntered.map((printer, i) => {
                  return (
                    <tr>
                      <td style={{'width':0.15*window.innerWidth, 'minWidth':0.15*window.innerWidth, 'maxWidth':0.15*window.innerWidth}}>
                        <IconL icon={print} />
                      </td>
                      <td style={{'width':0.5*window.innerWidth, 'minWidth':0.5*window.innerWidth, 'maxWidth':0.5*window.innerWidth}}>
                        <div>
                          <p><b>{printer}</b></p>
                        </div>
                      </td>
                      <td style={{'width':0.15*window.innerWidth, 'minWidth':0.15*window.innerWidth, 'maxWidth':0.15*window.innerWidth}}>
                        <p>{this.state.paymentMethod == 1 ? "$10.00" : "$75.00"}</p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          :
            <table className="printersTable printersTable2 centerStuffClass">
              <tbody>
                {this.state.printersEntered.map((printer) => {
                  return (
                    <tr>
                      <td className="printersTableCol1">
                        <IconL icon={print} />
                      </td>
                      <td className="printersTableCol2">
                        <div>
                          <p><b>{printer}</b></p>
                        </div>
                      </td>
                      <td className="printersTableCol4">
                        <p>{this.state.paymentMethod == 1 ? "$10.00" : "$75.00"}</p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          }
        </div>

        {this.state.isMobile ?
          <div>
            <div id="confirmOrderTableMobile" className="confirmDiv centerStuffClass">
              <h3><b>User Info</b></h3>
              <p>{this.state.firstName} {this.state.lastName}</p>
              <p>{this.state.email}</p>
              <p>{this.formatPhoneNumber(this.state.phoneNum)}</p>
            </div>
            <div id="confirmOrderTableMobile" className="confirmDiv">
              <h3><b>Shipping Info</b></h3>
              <p>{this.state.firstName} {this.state.lastName}</p>
              <p>{this.state.addressName}</p>
              <p>{this.state.address1}</p>
              <p>{this.state.address2}</p>
              <p>{this.state.city}, {this.state.addrState} {this.state.zipCode}</p>
            </div>
            <div id="confirmOrderTableMobile" className="confirmDiv">
              <h3><b>Billing Info</b></h3>
              <p>Payment Method: <b>{this.state.paymentMethod == 1 ? "Monthly" : "Annually"}</b></p>
              <p>{this.state.cardName}</p>
              <p style={{'marginTop':'-10px'}}><IconM icon={creditCardAlt}/>  ending in {this.state.cardNumber.substring(this.state.cardNumber.length-4)}</p>
            </div>
          </div>
        :
          <table id="confirmOrderTable">
            <tr>
              <td style={{'width': '33%'}}>
                <div id="userInfoDiv" className="confirmDiv">
                  <h3><b>User Info</b></h3>
                  <p>{this.state.firstName} {this.state.lastName}</p>
                  <p>{this.state.email}</p>
                  <p>{this.formatPhoneNumber(this.state.phoneNum)}</p>
                </div>
              </td>
              <td style={{'width': '33%'}}>
                <div id="shippingInfoDiv" className="confirmDiv">
                  <h3><b>Shipping Info</b></h3>
                  <p>{this.state.firstName} {this.state.lastName}</p>
                  <p>{this.state.addressName}</p>
                  <p>{this.state.address1}</p>
                  <p>{this.state.address2}</p>
                  <p>{this.state.city}, {this.state.addrState} {this.state.zipCode}</p>
                </div>
              </td>
              <td style={{'width': '33%'}}>
                <div id="billingInfoDiv" className="confirmDiv">
                  <h3><b>Billing Info</b></h3>
                  <p>Payment Method: <b>{this.state.paymentMethod == 1 ? "Monthly" : "Annually"}</b></p>
                  <p>{this.state.cardName}</p>
                  <p style={{'marginTop':'-10px'}}><IconM icon={creditCardAlt}/>  ending in {this.state.cardNumber.substring(this.state.cardNumber.length-4)}</p>
                </div>
              </td>
            </tr>
          </table>
        }


        {this.state.isMobile ?
          <div id="orderTotalDiv" className="centerStuffClass">
            <tr>
              <td className="orderTableMobileTdL">Subtotal:</td>
              <td className="orderTableMobileTdR">${(priceToUse * this.state.printersEntered.length).toFixed(2)}</td>
            </tr>
            <tr>
              <td className="orderTableMobileTdL">Tax:</td>
              <td className="orderTableMobileTdR">${(0.0725 * priceToUse * this.state.printersEntered.length).toFixed(2)}</td>
            </tr>
            <tr>
              <td className="orderTableMobileTdL">Shipping:</td>
              <td className="orderTableMobileTdR">FREE</td>
            </tr>
            <tr>
              <td className="lastRow orderTableMobileTdL"><b>Total:</b></td>
              <td className="lastRow orderTableMobileTdR"><b>${this.getPriceForNumPrinters(this.state.printersEntered.length)}</b></td>
            </tr>
          </div>
        :
          <div id="orderTotalDiv">
            <table cellSpacing="0" cellPadding="0">
              <tr>
                <td>Subtotal:</td>
                <td className="tdR">${(priceToUse * this.state.printersEntered.length).toFixed(2)}</td>
              </tr>
              <tr>
                <td>Tax:</td>
                <td className="tdR">${(0.0725 * priceToUse * this.state.printersEntered.length).toFixed(2)}</td>
              </tr>
              <tr>
                <td>Shipping:</td>
                <td className="tdR">FREE</td>
              </tr>
              <tr className="lastRow">
                <td><b>Total:</b></td>
                <td className="tdR"><b>${this.getPriceForNumPrinters(this.state.printersEntered.length)}</b></td>
              </tr>
            </table>
          </div>
        }

        {this.state.isLoading ?
          <div className="centerStuffClass">
            <h2><b>REGISTERING</b></h2>
            {/* <PulseLoader loading={true} color="#27AAEA" size="16px" margin="4px"/> */}
            <ScaleLoader loading={true} color="#27AAEA" size="16px" margin="4px"/>
          </div>
          : null
        }

      </div>
    )
  }



  tick() {
    let dist = (this.state.pageNumAnim - this.state.pageNum);
    if (Math.abs(dist) <= 0.125) {
      this.setState({ pageNumAnim: this.state.pageNum });
      this.stopTimer();
    }
    else {
      let dx = 0.130 * Math.max(0.625, (1.35*Math.abs(dist)));
      let dir = -(dist / Math.abs(dist));
      this.setState({pageNumAnim: (this.state.pageNumAnim + dir*dx)});
    }
  }
  startTimer() {
    clearInterval(this.timer)
    this.timer = setInterval(this.tick.bind(this), 50);
  }
  stopTimer() {
    clearInterval(this.timer)
  }

  renderBottomNav() {
    return (
      this.state.isMobile ?
        <div>
          {(this.state.pageNum >= 3 && this.state.pageNum <= 5) ? <Button id="mobileBack" bsStyle="primary" onClick={ () => this.didClickPrevPage() }>Back</Button> : null}
          {(this.state.pageNum == 6) ? null : (this.state.pageNum != 5) ? <Button id="mobileNext" bsStyle="primary" onClick={ () => this.didClickNextPage() }>Next</Button> : <LoaderButton id="mobileNext" className="mobileNextConfirm" disabled={false} type="submit" text="CONFIRM" loadingText="CONFIRMING…" onClick={ () => this.handleSubmit() } isLoading={this.state.isLoading}  />}
          <div className="centerStuffClass" style={{'marginTop':'10px'}}>
            <canvas id="pageNumCanvas" ref="canvas" width={0.8*window.innerWidth} height="75"/>
            <br/>
          </div>
        </div>
      :
        <table className="Signup" id="pageNumTable" ref="pageNumTable">
          <tbody>
            <tr>
              <td className="pageNumTableTdSide">{(this.state.pageNum >= 3 && this.state.pageNum <= 5) ? <Button bsStyle="primary" onClick={ () => this.didClickPrevPage() }>Back</Button> : null}</td>
              <td className="pageNumTableTdMid"><canvas id="pageNumCanvas" ref="canvas" width="520" height="120"/></td>
              <td className="pageNumTableTdSide">{(this.state.pageNum == 6) ? null : (this.state.pageNum != 5) ? <Button bsStyle="primary" onClick={ () => this.didClickNextPage() }>Next</Button> : <LoaderButton block bsSize="large" disabled={false} type="submit" onClick={ () => this.handleSubmit() } isLoading={this.state.isLoading} text="Confirm" loadingText="Confirming…" />}</td>
            </tr>
          </tbody>
        </table>

    )
  }

  renderConfirmationPage() {
    return (
      <div id="confirmationPageDiv">
        <br/>
        <h2>Signup Successful!</h2>
        <p>Welcome to the Unlimited Ink Club</p>
        <br/>
        <Link to="/"><Button style={{'margin':'0 auto'}}>Go to Portal</Button></Link>
      </div>
    )
  }

  render() {
    return (
      <div>
        <div className="landingDiv">
        </div>

        {this.state.showingPopover ?
          this.renderPopover()
        :
          null
        }
        <CSSTransition
            defaultStyle={{ transform: "translate(-7px, -28px)", opacity: 0 }}
            enterStyle={{ transform: transit("translate(0, 0)", 300, "ease-in-out"),  opacity: transit(1.0, 350, 'ease-in-out') }}
            leaveStyle={{ transform: transit("translate(0, 0)", 500, "ease-out"),  opacity: transit(0.0, 500, 'ease-in') }}
            activeStyle={{ transform: "translate(0, 0)", opacity: 1.0 }}
            active={this.state.hasLoaded}
            >
        <div className="Signup" ref="MainPane" style={{minHeight: this.mainHeight}}>
          {this.renderMainPane()}
        </div>
      </CSSTransition>

      <CSSTransition
          defaultStyle={{ transform: "translate(0px, 18px)", opacity: 0 }}
          enterStyle={{ transform: transit("translate(0, 0)", 300, "ease-in-out"),  opacity: transit(1.0, 350, 'ease-in-out') }}
          leaveStyle={{ transform: transit("translate(0, 0)", 500, "ease-out"),  opacity: transit(0.0, 500, 'ease-in') }}
          activeStyle={{ transform: "translate(0, 0)", opacity: 1.0 }}
          active={this.state.hasLoaded}
          >

        {this.renderBottomNav()}

      </CSSTransition>

      </div>
    )
  }



  formatPhoneNumber(number) {
    if (number.length == 10 && number[0] != "1") {
      let numberFormatted = "(" + number.substring(0,3) + ") " + number.substring(3,6) + "-" + number.substring(6,10);
      return numberFormatted;
    }
    else if (number.length == 11 && number[0] == "1") {
      let numberFormatted = "1 (" + number.substring(1,4) + ") " + number.substring(4,7) + "-" + number.substring(7,11);
      return numberFormatted;
    }
    return number;
  }
  unformatPhoneNumber(number) {
    var numberUnformatted = number.replace(/ /g, '');
    numberUnformatted = numberUnformatted.replace(/\(/g, '');
    numberUnformatted = numberUnformatted.replace(/\)/g, '');
    numberUnformatted = numberUnformatted.replace(/-/g, '');
    return numberUnformatted;
  }

  formatCardNumber(number) {
    if (number.length > 16) {
      let numberFormatted = number.substring(0,4) + " " + number.substring(4,8) + " " + number.substring(8,12) + " " + number.substring(12,16) + " " + number.substring(16,number.length);
      return numberFormatted;
    }
    else if (number.length > 12) {
      let numberFormatted = number.substring(0,4) + " " + number.substring(4,8) + " " + number.substring(8,12) + " " + number.substring(12,number.length);
      return numberFormatted;
    }
    else if (number.length > 8) {
      let numberFormatted = number.substring(0,4) + " " + number.substring(4,8) + " " + number.substring(8);
      return numberFormatted;
    }
    else if (number.length > 4) {
      let numberFormatted = number.substring(0,4) + " " + number.substring(4);
      return numberFormatted;
    }
    return number;
  }
  unformatCardNumber(number) {
    return number.replace(/ /g, '');
  }


  getPriceForNumPrinters(num)
  {
    const priceToUse = this.state.paymentMethod == 1 ? 10.0 : 75.0;
    return (1.0725 * priceToUse * num).toFixed(2);
  }

}
