  import React from 'react';
import ReactDOM from "react-dom"

const axios = require('axios');



export async function postNewUser(email, firstName, lastName, phoneNum)
{
  var requestStr = '{'
    requestStr += '\"customer\": '
    requestStr += '{'
      requestStr += '\"first_name\": \"' + firstName + '\",'
      requestStr += '\"last_name\": \"' + lastName + '\",'
      requestStr += '\"email\": \"' + email + '\",'
      if (phoneNum != "NO_PHONE") {
        requestStr += '\"phone\": \"' + phoneNum + '\",'
      }
      requestStr += '\"verified_email\": true,'
      // requestStr += '\"metafields\": ['
      //   requestStr += '{'
      //   requestStr += '\"key\": \"new\",'
      //   requestStr += '\"value\": \"newvalue\",'
      //   requestStr += '\"value_type\": \"string\",'
      //   requestStr += '\"namespace\": \"global\"'
      //   requestStr += '}'
      // requestStr += '],'
      requestStr += '\"send_email_invite\": false,'
      requestStr += '\"accepts_marketing\": true'
    requestStr += '}'
  requestStr += '}'

  //const shopifyURL = 'https://' + process.env.SHOPIFY_API_KEY + ':' + process.env.SHOPIFY_API_PASS + '@' + process.env.SHOPIFY_API_STORE + '/admin/api/' + process.env.SHOPIFY_API_VERSION + '/customers.json'
  const shopifyURL = 'https://' + 'fe9db0497849d08f42f156bd5c9e3603:0acae06febe40b2aa2dea2f9667c392c@unlimited-ink-club2.myshopify.com' + '/admin/api/' + '2019-07' + '/customers.json'
  const shopifyUrlToUse = 'https://arcane-bastion-40707.herokuapp.com/' + shopifyURL
  //const shopifyUrlToUse = shopifyURL

  var response;
  try {
    response = await axios.post(shopifyUrlToUse,
        requestStr,
        {
        headers: {
          'Authorization' : 'Basic ZmU5ZGIwNDk3ODQ5ZDA4ZjQyZjE1NmJkNWM5ZTM2MDM6MGFjYWUwNmZlYmU0MGIyYWEyZGVhMmY5NjY3YzM5MmM=',
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Authorization header',
      	},
    });
  }
  catch (error) {
    //console.log(error);
    //console.log(error.response)
    if (error.response.statusText == "Unprocessable Entity") {
      return "-3"
    }
  }

  if (response) {
    //const data = response.data;
    //console.log(data);
  }
  return "";
}


export async function searchForCustomer(email)
{
  const shopifyURL = 'https://' + 'fe9db0497849d08f42f156bd5c9e3603:0acae06febe40b2aa2dea2f9667c392c@unlimited-ink-club2.myshopify.com' + '/admin/api/' + '2019-07' + '/customers/search.json?query=' + email
  const shopifyUrlToUse = 'https://arcane-bastion-40707.herokuapp.com/' + shopifyURL
  //const shopifyUrlToUse = shopifyURL

  var response;
  try {
    response = await axios.get(shopifyUrlToUse,
        {
        headers: {
          'Authorization' : 'Basic ZmU5ZGIwNDk3ODQ5ZDA4ZjQyZjE1NmJkNWM5ZTM2MDM6MGFjYWUwNmZlYmU0MGIyYWEyZGVhMmY5NjY3YzM5MmM=',
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Authorization header',
      	},
    });
  }
  catch (error) {
    //console.log(error);
    //console.log(error.response)
    return null;
  }

  if (response) {
    const data = response.data;
    //console.log(data);
    if (response.data.customers.length > 0) {
      return response.data.customers[0];
    }
  }
  return null;
}

export async function updateCustomer(customerId, addressId, firstName, lastName, address)
{
  var requestStr = '{'
    if (addressId == null) {
      requestStr += '\"customer\": '
      requestStr += '{'
        requestStr += '\"addresses\": ['
          requestStr += '{'
          requestStr += '\"address1\": \"' + address.address1 + '\",'
          requestStr += '\"city\": \"' + address.city + '\",'
          requestStr += '\"state\": \"' + address.addrState + '\",'
          requestStr += '\"zip\": \"' + address.zipCode + '\",'
          requestStr += '\"last_name\": \"' + lastName + '\",'
          requestStr += '\"first_name\": \"' + firstName + '\",'
          requestStr += '\"country\": \"United States\"'
          requestStr += '}'
        requestStr += ']'
      requestStr += '}'
    }
    else {
      requestStr += '\"address\": '
      requestStr += '{'
      requestStr += '\"id\": ' + addressId + ','
        requestStr += '\"address1\": \"' + address.address1 + '\",'
        requestStr += '\"city\": \"' + address.city + '\",'
        requestStr += '\"state\": \"' + address.addrState + '\",'
        requestStr += '\"zip\": \"' + address.zipCode + '\",'
        requestStr += '\"last_name\": \"' + lastName + '\",'
        requestStr += '\"first_name\": \"' + firstName + '\",'
        requestStr += '\"country\": \"United States\"'
      requestStr += '}'
    }
  requestStr += '}'

  var shopifyURL = 'https://' + 'fe9db0497849d08f42f156bd5c9e3603:0acae06febe40b2aa2dea2f9667c392c@unlimited-ink-club2.myshopify.com' + '/admin/api/' + '2019-07' + '/customers/' + customerId + '.json'
  if (addressId != null) {
    shopifyURL = 'https://' + 'fe9db0497849d08f42f156bd5c9e3603:0acae06febe40b2aa2dea2f9667c392c@unlimited-ink-club2.myshopify.com' + '/admin/api/' + '2019-07' + '/customers/' + customerId + '/addresses/' + addressId + '.json'
  }
  const shopifyUrlToUse = 'https://arcane-bastion-40707.herokuapp.com/' + shopifyURL

  var response;
  try {
    response = await axios.put(shopifyUrlToUse,
        requestStr,
        {
        headers: {
          'Authorization' : 'Basic ZmU5ZGIwNDk3ODQ5ZDA4ZjQyZjE1NmJkNWM5ZTM2MDM6MGFjYWUwNmZlYmU0MGIyYWEyZGVhMmY5NjY3YzM5MmM=',
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Authorization header',
      	},
    });
  }
  catch (error) {
    //console.log(error);
    //console.log(error.response)
    //return "";
  }

  if (response) {
    //const data = response.data;
    //console.log(data);
    return "";
  }
  return "";

}

export async function postNewOrder(customerId, printers, price)
{
  var requestStr = '{'
    requestStr += '\"order\": '
    requestStr += '{'
      requestStr += '\"line_items\": ['
      for (var i = 0; i < printers.length; i++) {
        requestStr += '{'
        requestStr += '\"title\": \"' + printers[i] + '\",'
        requestStr += '\"price\": ' + price + ''
        requestStr += (i == printers.length-1) ? '}' : '},'
      }
      requestStr += '],'
      requestStr += '\"customer\": {'
        requestStr += '\"id\": ' + customerId
      requestStr += '}'
    requestStr += '}'
  requestStr += '}'

  //const shopifyURL = 'https://' + process.env.SHOPIFY_API_KEY + ':' + process.env.SHOPIFY_API_PASS + '@' + process.env.SHOPIFY_API_STORE + '/admin/api/' + process.env.SHOPIFY_API_VERSION + '/customers.json'
  const shopifyURL = 'https://' + 'fe9db0497849d08f42f156bd5c9e3603:0acae06febe40b2aa2dea2f9667c392c@unlimited-ink-club2.myshopify.com' + '/admin/api/' + '2019-07' + '/orders.json'
  const shopifyUrlToUse = 'https://arcane-bastion-40707.herokuapp.com/' + shopifyURL
  //const shopifyUrlToUse = shopifyURL


  var response;
  try {
    response = await axios.post(shopifyUrlToUse,
        requestStr,
        {
        headers: {
          'Authorization' : 'Basic ZmU5ZGIwNDk3ODQ5ZDA4ZjQyZjE1NmJkNWM5ZTM2MDM6MGFjYWUwNmZlYmU0MGIyYWEyZGVhMmY5NjY3YzM5MmM=',
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Authorization header',
      	},
    });
  }
  catch (error) {
    //console.log(error);
    //console.log(error.response)
    //return "";
  }

  if (response) {
    const data = response.data;
    //console.log(data);
    return "";
  }
  return "";

}
