import React, { Component } from "react";
import { PageHeader, ListGroup, ListGroupItem, Navbar, Nav2, NavItem, Button, Glyphicon } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import './Portal.css';

export class PortalHelp extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      var3: "1"
    };
  }

  onItemSelection = arg => {
    console.log("ayeeee: " + arg.path);
    this.setState({ var3: arg.path });
  };



  render() {
    return (
      <div>
        <div>
          { }
        </div>
        <div>
          <h1>HELP</h1>
        </div>

      </div>


    );
  }
}
