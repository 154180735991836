import React, { Component } from "react";
import { PageHeader, ListGroup, ListGroupItem, Navbar, Nav2, NavItem, Button, Glyphicon } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Link, withRouter } from "react-router-dom";

import './Portal.css';
import './Signup.css';

import { Icon as BaseIcon } from "react-icons-kit";
import { print } from "react-icons-kit/fa/print";
import { plus } from "react-icons-kit/fa/plus";

const IconL = props => <BaseIcon size={56} icon={props.icon} />;
const IconM = props => <BaseIcon size={42} icon={props.icon} />;
const IconS = props => <BaseIcon size={34} icon={props.icon} />;
const IconXS = props => <BaseIcon size={29} icon={props.icon} />;


export class PortalSettings extends React.Component {

  constructor(props) {
    super(props);

    //this.didTapAddNewShipment = this.didTapAddNewShipment.bind(this);

    this.state = {

    };
  }

  renderAccount() {
    const userInfo = this.props.userInfo;
    return (
      <div>
        <div id="shipmentsAddress" className="dashboardView addressTableDiv" style={{'float': 'none', 'border': '0px', 'width': '100%'}}>
          <h2 style={{}}>Account Info</h2>
          {this.props.userInfo ?
              <table className="centerStuffClass">
                <tr>
                  <p>{userInfo.firstName} {userInfo.lastName}</p>
                  <p>{userInfo.email}</p>
                  <p>{this.formatPhoneNumber(userInfo.phoneNum)}</p>
                  <br/>
                  {userInfo.paymentMethod ?
                      <p><b>Payment Plan:</b> {userInfo.paymentMethod == 1 ? "$10/mo per printer" : userInfo.paymentMethod == 2 ? "$120/yr per printer" : ""}</p>
                      :
                      <p></p>
                  }
                </tr>
              </table>
          :
            null
          }
        </div>
      </div>
    )
  }

  renderSettings() {
    return (
      <div style={{'margin-top': '-35px'}} id="shipmentsDiv" className="centerStuffClass">
        {/*<h1 style={{'margin-top': '-20px', 'margin-bottom': '4px', 'line-height': '1.0em', 'textAlign': 'center'}}>Billings</h1>*/}
        <div>
          <div style={{'border-top': '2px black solid'}} >
              <table className="shipmentsTableDiv helpTableDiv centerStuffClass">
                <tbody>
                  <tr>
                    <td className="centerStuffClass">
                      <h2>Additional Help</h2>
                      <p><b><u>Contact us at</u></b></p>
                      <p>info@unlimitedinkclub.com</p>
                      <p>(800) 690-0221</p>
                      <p>9891 Irvine Center Dr, Suite 220</p>
                      <p>Irvine, CA 92618</p>
                      <p></p>
                    </td>
                    <td className="centerStuffClass" style={{'height': '370px'}}>
                      <img style={{'width': '178px', 'height': '70px'}} src="https://www.unlimitedinkclub.com/images/branding.png"/>
                      <br/>
                      <p style={{'width': '178px', 'marginTop':'8px', 'marginLeft':'5px', 'textAlign':'center'}}><b><Link to="/terms-and-conditions" target="_blank">Terms & Conditions</Link></b></p>
                    </td>
                    <td className="centerStuffClass">
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>
        </div>

      </div>
    );
  }



  render() {
    return (
      <div>
        <div>
          {this.renderAccount()}
        </div>
        <br/><br/><br/>
        <div>
          {this.renderSettings() }
        </div>

      </div>

    );
  }


  formatPhoneNumber(number) {
    if (number.length == 10 && number[0] != "1") {
      let numberFormatted = "(" + number.substring(0,3) + ") " + number.substring(3,6) + "-" + number.substring(6,10);
      return numberFormatted;
    }
    else if (number.length == 11 && number[0] == "1") {
      let numberFormatted = "1 (" + number.substring(1,4) + ") " + number.substring(4,7) + "-" + number.substring(7,11);
      return numberFormatted;
    }
    return number;
  }

}
