export default {
  MAX_ATTACHMENT_SIZE: 5000000,

  s3: {
    REGION: "us-east-2",
    BUCKET: "toner-club-app-bucket"
  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://a7wb6p5lua.execute-api.us-east-2.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_fNvFhkZZz",
    APP_CLIENT_ID: "1j559o4cgpm9gaa054koiq8jgo",
    IDENTITY_POOL_ID: "us-east-2:dedd4001-80ee-43a0-bd67-1ba8179a6f83"
  }
};
