import React, { Component } from "react";
import { PageHeader, ListGroup, ListGroupItem, Navbar, Nav2, NavItem, Button, Glyphicon } from "react-bootstrap";
import { Container, Table, Col, Row, Form, FormGroup, Label, Input, FormText, FormFeedback, } from 'reactstrap';

import { Icon as BaseIcon } from "react-icons-kit";
import { print } from "react-icons-kit/fa/print";
import { check } from "react-icons-kit/fa/check";
import { playCircle } from "react-icons-kit/fa/playCircle";
import { calendarCheckO } from "react-icons-kit/fa/calendarCheckO";
import { plus } from "react-icons-kit/fa/plus";
import { minus } from "react-icons-kit/fa/minus";

import './Portal.css';

import { CSSTransition, transit } from 'react-css-transition';

const IconL = props => <BaseIcon size={56} icon={props.icon} />;
const IconM = props => <BaseIcon size={42} icon={props.icon} />;
const IconS = props => <BaseIcon size={34} icon={props.icon} />;
const IconXS = props => <BaseIcon size={29} icon={props.icon} />;

var ScaleLoader = require('halogen/ScaleLoader');

var firebaseDbHelper = require('../API_helpers/FirebaseDbHelper')
var authorizeNetHelper = require('../API_helpers/AuthorizeNetHelper')

export class PortalDevices extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      showingPopover: false,
      showingPopover2: false,
      popoverDetails: "",

      showingOtherList: false,
      addingPrinters: false,

      pfPrintersSelected: [],

      printerModel: "",
      printerModelEmpty: "",
      printersEntered: [],

    };
  }

  async componentDidMount() {
    try {
      // const devices = await this.devices();
      // this.setState({ devices });
    } catch (e) {
      alert(e);
    }
  }




  renderDevices() {
    var printers = [];
    var dLength = 0;

    if (this.props.dcaPrinters != null) {
      dLength += this.props.dcaPrinters.length;
    }
    if (this.props.userInfo != null) {
      if (this.props.userInfo.pfPrinters != null) {
        dLength -= this.props.userInfo.pfPrinters.length;

        for (var i = 0; i < this.props.userInfo.pfPrinters.length; i++) {
          var printer = this.props.userInfo.pfPrinters[i];
          if (this.props.dcaPrinters != null) {
            var gotMatch = false;
            for (var j = 0; j < this.props.dcaPrinters.length; j++) {
              if (this.props.dcaPrinters[j].serialNumber == printer.serial) {
                  printer['firstReportedAt'] = this.props.dcaPrinters[j].firstReportedAt;
                  printer['lastReportedAt'] = this.props.dcaPrinters[j].lastReportedAt;
                  printer['dcaIndex'] = j;
                  gotMatch = true;
              }
            }
            if (!gotMatch) { printer['dcaIndex'] = -1; }
          }
          else {
            printer['dcaIndex'] = -1;
          }
          printers.push(printer);
        }
      }
    }


    return (
      <div>
        {this.state.showingPopover ?
          this.renderPopover()
        :
          null
        }


        {this.state.showingOtherList ?
          <h1 style={{'marginTop': '28px', 'marginBottom': '4px', 'lineHeight': '1.0em', 'textAlign': 'center'}}>Add More Printers<Button height='25px' style={{'marginTop': '-5px', 'marginLeft': '3%', 'padding':"8px 12px"}} onClick={this.didOpenCloseNetworkList.bind(this)}><b><IconXS icon={minus} /></b></Button></h1>
        :
          <h1 style={{'marginTop': '28px', 'marginBottom': '4px', 'lineHeight': '1.0em', 'textAlign': 'center'}}>Unlimited Ink Printers</h1>
        }


        <CSSTransition
          defaultStyle={{ transform: "translate(0px, -20px)", opacity: 0.2 }}
          enterStyle={{ transform: transit("translate(0, 0)", 300, "ease-in-out"),  opacity: transit(1.0, 250, 'ease-in-out') }}
          leaveStyle={{ transform: transit("translate(0, -25px)", 300, "ease-in-out"),  opacity: transit(0.0, 270, 'ease-in-out') }}
          activeStyle={{ transform: "translate(0, 0)", opacity: 1.0 }}
          active={!this.state.showingOtherList}
          >

          {this.state.showingOtherList ?
            <div></div>
          :
            <div >
              {this.props.userInfo ?
                this.props.userInfo.pfPrinters ?
                  <div id="devicesTable">
                    <div style={{'borderTop': '2px black solid'}} >
                        <table className="printersTable centerStuffClass">
                          <tbody>
                            {printers.map((printer, i) => {
                              //if (this.props.subbedIndexes.includes(i)) {
                                return (
                                  <tr>
                                    {printer.firstReportedAt == null || true ?
                                      <td className="centerStuffClass">
                                        <IconL icon={print} />
                                      </td>
                                    :
                                      <td className="centerStuffClass">
                                        <p><IconXS icon={print}/>&nbsp;&nbsp;<b>{printer.name}</b></p>
                                        {printer.dcaIndex != -1 ? <p>{printer.ipAddress}&emsp;<b>|</b>&emsp;{printer.serial}</p> : null}
                                      </td>
                                    }
                                    {printer.firstReportedAt == null || true ?
                                      <td className="centerStuffClass">
                                        <p><b>{printer.name}</b></p>
                                        {printer.dcaIndex != -1 ? <p>{printer.ipAddress}&emsp;<b>|</b>&emsp;{printer.serial}</p> : null}
                                      </td>
                                    :
                                      <td className="centerStuffClass">
                                        <p><IconXS icon={playCircle}/>&nbsp;&nbsp;{this.formatDate(printer.firstReportedAt)}</p>
                                        <p><IconXS icon={calendarCheckO}/>&nbsp;&nbsp;{this.formatDate(printer.lastReportedAt)}</p>
                                      </td>
                                    }
                                    {this.renderTonerLevels1(printer.dcaIndex)}
                                    {this.renderTonerLevels2(printer.dcaIndex)}
                                  </tr>
                                );
                              //}
                            })}
                          </tbody>
                        </table>
                    </div>
                  </div>
                :
                  <div id="orderInfoDiv" className="centerStuffClass">
                    <br/>
                    <p>No printers subscribed</p>
                  </div>
              :
                <div id="orderInfoDiv"  className="centerStuffClass">
                  <br/><br/>
                  <ScaleLoader loading={true} color="#27AAEA" size="16px" margin="4px"/>
                </div>
              }
          </div>
        }
        </CSSTransition>



        <CSSTransition
          defaultStyle={{ transform: "translate(0px, 20px)", opacity: 0.2 }}
          enterStyle={{ transform: transit("translate(0, 0)", 300, "ease-in-out"),  opacity: transit(1.0, 250, 'ease-in-out') }}
          leaveStyle={{ transform: transit("translate(0, 25px)", 300, "ease-in-out"),  opacity: transit(0.0, 270, 'ease-in-out') }}
          activeStyle={{ transform: "translate(0, 0)", opacity: 1.0 }}
          active={this.state.showingOtherList}
          >

          {!this.state.showingOtherList ?
            <div></div>
          :
            <div className="centerStuffClass">
              <div className="centerStuffClass">
                <br/><br/>
                <Form className="form centerStuffClass" style={{'maxWidth': '70%', 'margin':'0 auto'}} onSubmit={this.preventSubmit}>
                  <Col>
                    <FormGroup style={{'textAlign': 'left'}}>
                      <Label>Printer Model&emsp;(ex. HP OfficeJet Pro 8720)</Label>
                      <Input
                        type = "text"
                        name = "printerModel"
                        id = "printerModel"
                        value={ this.state.printerModel }
                        onChange={ (e) => { this.handleChange(e) }}
                        invalid={ this.state.printerModelEmpty === 'has-danger' }
                        onKeyPress={event => {
                          if (event.key === "Enter") {
                            this.didAddPrinter();
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Button height='25px' style={{'padding':"12px 30px", 'margin': '0 auto'}} onClick={this.didAddPrinter.bind(this)}><b><IconS icon={plus} /></b></Button>
                </Form>
              </div>

              <br/>
              {this.state.printersEntered.length > 0 ?
                <div id="orderInfoDiv">
                  <div className="addPrinterTableDiv">
                    <table className="printersTable printersTable2 centerStuffClass">
                      <tbody>
                        {this.state.printersEntered.map((printer, i) => {
                          return (
                            <tr>
                              <td className="printersTableCol1">
                                <IconL icon={print} />
                              </td>
                              <td className="printersTableCol2">
                                <div>
                                  <p><b>{printer}</b></p>
                                </div>
                              </td>
                              <td className="printersTableCol3">
                                <Button height='15px' style={{'padding':"6px 16px"}} onClick={this.didRemovePrinter.bind(this, i)}><IconS icon={minus} /></Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <br/>
                  <Button height='25px' style={{'padding':"12px 30px", 'margin': '0 auto'}} onClick={this.didConfirmAddPrinters.bind(this)}><b>CONFIRM</b></Button>
                </div>
              :
                null
              }
            </div>

            /*
            (this.props.userInfo && this.props.dcaPrinters) ?
              dLength > 0 ?
                <div id="orderInfoDiv" className="otherDevicesTable">
                    <table className="printersTable printersTable2 centerStuffClass">
                      <tbody>
                        {this.props.dcaPrinters.map((printer, i) => {
                          if (!this.props.subbedIndexes.includes(i)) {
                            return (
                              <tr>
                                <td className="printersTableCol1">
                                  <IconL icon={print} />
                                </td>
                                <td className="printersTableCol2">
                                  {(printer.name == null) ?
                                    <p><b>Still fetching device info...</b></p>
                                    :
                                    <div>
                                      <p><b>{printer.name.split(';')[0].substring(0, 35)}</b></p>
                                      <p>{printer.ipAddress}&emsp;<b>|</b>&emsp;{printer.serialNumber}</p>
                                    </div>
                                  }
                                </td>
                                <td className="printersTableCol3">
                                  {(this.printerIsSelected(printer) >= 0) ?
                                    <div>
                                      <Button height='25px' style={{'padding':"14px 26px"}} onClick={this.didSelectPfPrinter.bind(this, printer)}><b><IconS icon={check} /></b></Button>
                                      <p id="activeSubtext"><b>UNLIMITED INK!</b></p>
                                    </div>
                                    :
                                    <Button height='15px' style={{'padding':"14px 26px"}} onClick={this.didSelectPfPrinter.bind(this, printer)}>SELECT</Button>
                                  }
                                </td>
                              </tr>
                            );
                          }
                        })}
                      </tbody>
                    </table>
                </div>
              :
                <div id="orderInfoDiv" className="centerStuffClass">
                  <br/>
                  <p>No other printers found on your network</p>
                </div>
            :
              <div id="orderInfoDiv"  className="centerStuffClass">
                <br/><br/>
                <ScaleLoader loading={true} color="#27AAEA" size="16px" margin="4px"/>
              </div>
          }
          */
        }

        </CSSTransition>




        {this.state.showingOtherList ?
          this.state.pfPrintersSelected.length > 0 ?
            <div className="centerStuffClass" style={{'marginTop': '40px', 'marginBottom':'4px'}}>
              <Button style={{'padding':"12px 28px"}} bsStyle="primary" bsSize="large" onClick={this.didTapApply.bind(this)}>APPLY CHANGES</Button>
            </div>
          :
            null
        :
          <div>
            <h1 style={{'marginTop': '40px', 'marginBottom': '4px', 'lineHeight': '1.0em', 'textAlign': 'center'}}>Add More Printers<Button height='25px' style={{'marginTop': '-5px', 'marginLeft': '3%', 'padding':"8px 12px"}} onClick={this.didOpenCloseNetworkList.bind(this)}><b><IconXS icon={plus} /></b></Button></h1>
          </div>
        }



      </div>
    );
  }

  didOpenCloseNetworkList() {
    this.setState({ showingOtherList: !this.state.showingOtherList});
  }

  preventSubmit(e) {
    e.preventDefault();
  }
  didAddPrinter() {
    if (this.state.printerModel == "") {
      this.setState({ printerModelEmpty: 'has-danger'});
      return;
    }
    this.setState({ printersEntered: this.state.printersEntered.concat(this.state.printerModel), printerModel: "", printerModelEmpty: "" });
  }
  didRemovePrinter(index) {
    this.setState({ printersEntered: this.state.printersEntered.filter((_, i) => i !== index) });
  }
  didConfirmAddPrinters() {
    this.setState({ showingPopover: true });
  }
  handleChange = async event => {
    this.setState({ [event.target.id]: event.target.value });
  }

  renderPopover() {
    return (
      <div className="popoverDiv centerStuffClass">
        <div className="popoverDivInner centerStuffClass">
          <div style={{'margin': '16px 12px'}}>
            <h2>Apply Changes</h2>
            <p>Adding {this.state.printersEntered.length} printer{this.state.printersEntered.length != 1 ? 's' : ''} to your Unlimited Ink account</p>
            <p>You will be charged $75+tax for each printer</p>
            <p>A complete set of ink cartridges for each printer model will be immediately shipped to you,<br/>and any additional ink needed for the next year will be delivered automatically for free</p>

            {this.state.isLoading ?
              <div>
                <br/>
                <ScaleLoader loading={true} color="#27AAEA" size="16px" margin="4px"/>
              </div>
            :
              <div style={{'marginTop': '35px'}}>
                <Button style={{'padding':"12px 38px"}} bsStyle="primary" bsSize="large" onClick={this.didTapAlertOk.bind(this)}>OK</Button>
                &emsp;
                <Button style={{'padding':"12px 38px"}} bsStyle="primary" bsSize="large" onClick={this.didTapAlertCancel.bind(this)}>Cancel</Button>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
  renderPopover2() {
    return (
      <div className="popoverDiv centerStuffClass">
        <div className="popoverDivInner centerStuffClass">
          <div style={{'margin': '16px 12px'}}>
            <h2>WHOOPS</h2>
            <p>{this.state.popoverDetails}</p>
            <div style={{'marginTop': '35px'}}>
              <Button style={{'padding':"12px 38px"}} bsStyle="primary" bsSize="large" onClick={this.didTapAlertCancel.bind(this)}>OK</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  async didTapAlertOk() {

    this.setState({ isLoading: true });

    let amount = this.getPriceForNumPrinters(this.state.printersEntered.length);
    let orderInfo = { name: this.state.firstName + ' ' + this.state.lastName, amount: amount, };
    let billing = this.props.userInfo.billings[this.props.userInfo.selectedBilling];
    authorizeNetHelper.chargeCustomerProfile(billing.authNetProfileId, billing.authNetPaymentId, amount, async function(response) {
      if (response.success) {
        await this.pushUserDataToFirebase();
        this.sendAddedPrintersEmail();
        this.setState({ printersEntered: [], printerModel: "", printerModelEmpty: "" });
        this.setState({ isLoading: false});
        this.setState({ showingOtherList: false, showingPopover: false});
      }
      else if (response.message.includes("duplicate")) {
        await this.pushUserDataToFirebase();
        this.sendAddedPrintersEmail();

        var user = firebaseDbHelper.getCurrentUser();
        var email = this.props.userInfo.email;
        var firstName = this.props.userInfo.firstName;
        var lastName = this.props.userInfo.lastName;
        var phoneNum = this.props.userInfo.phoneNum;
        var authNetProfileId = billing.authNetProfileId;
        var authNetPaymentId = billing.authNetPaymentId;
        var uuid = user['uid'];
        window.emailjs.send(
          'mailgun',
          process.env.REACT_APP_EMAILJS_TEMPLATEID_DUPLICATE_PAYMENT,
          { email, firstName, lastName, phoneNum, authNetProfileId, authNetPaymentId, uuid }
        )
        this.setState({ printersEntered: [], printerModel: "", printerModelEmpty: "" });
        this.setState({ isLoading: false });
        this.setState({ showingOtherList: false, showingPopover: false});
      }
      else {
        var details = response.message;
        if (response.message.includes('paymentProfile')) {
          details = "Please finish signup process!"
        }
        this.setState({ printersEntered: [], printerModel: "", printerModelEmpty: "" });
        this.setState({ isLoading: false });
        this.setState({showingPopover2: true, showingPopover: false, popoverDetails: details})
      }
    }.bind(this));
  }

  didTapAlertCancel() {
    this.setState({ showingPopover: false, showingPopover2: false });
  }

  async pushUserDataToFirebase()
  {
    var user = firebaseDbHelper.getCurrentUser();
    if (user != null)
    {
      var printersDb = this.props.userInfo.pfPrinters;
      for (var i = 0; i < this.state.printersEntered.length; i++) {
        let randUid = (Math.floor(Math.random() * 8999999) + 1000000).toString();
        let printerInfo = { serial: "", name: this.state.printersEntered[i], ipAddress: "", uuid: randUid };
        printersDb.push(printerInfo);
      }

      var payments = this.props.userInfo.payments;
      var date = new Date();
      var options = {year: 'numeric', month: 'short', day: 'numeric' };
      var dateStr = date.toLocaleDateString("en-US", options);

      var printersDb2 = this.props.userInfo.pfPrinters;
      for (var i = 0; i < this.state.printersEntered.length; i++) {
        let randUid = (Math.floor(Math.random() * 8999999) + 1000000).toString();
        let printerInfo = { serial: "", name: this.state.printersEntered[i], ipAddress: "", uuid: randUid };
        printersDb2.push(printerInfo);
      }

      let payment = {
        date: dateStr,
        price: this.getPriceForNumPrinters(this.state.printersEntered.length),
        devices: printersDb2,
      }
      payments.push(payment);

      var shipments = this.props.userInfo.shipments;
      for (var i = 0; i < this.state.printersEntered.length; i++) {
        var partInfo = {serial: "", type: "Full Ink Set"};
        var deviceInfo = {name: this.state.printersEntered[i]};

        var date = new Date();
        var options = {year: 'numeric', month: 'short', day: 'numeric' };
        var dateShipped = date.toLocaleDateString("en-US", options);
        var dateArrived = "IN TRANSIT";

        var shipment = {partInfo: partInfo, deviceInfo: deviceInfo, dateShipped: dateShipped, dateArrived: dateArrived};
        shipments.push(shipment);
      }

      await firebaseDbHelper.updatePfPrinters(user['uid'], printersDb);
      await firebaseDbHelper.updatePayments(user['uid'], payments);
      await firebaseDbHelper.updateShipments(user['uid'], shipments);
    }
  }

  getPriceForNumPrinters(num)
  {
    return (1.0725 * 75.0 * num).toFixed(2);
  }


  sendAddedPrintersEmail() {
    // send email
    var user = firebaseDbHelper.getCurrentUser();
    var email = this.props.userInfo.email;
    var firstName = this.props.userInfo.firstName;
    var lastName = this.props.userInfo.lastName;
    var phoneNum = this.props.userInfo.phoneNum;
    var uuid = user['uid'];
    var pfPrinters = ""
    for (var i = 0; i < this.state.printersEntered.length; i++) {
      let printer = this.state.printersEntered[i];
      var str = printer.name + " , " + printer.serialNumber + " , " + printer.ipAddress + " , " + printer.id + "<br>";
      pfPrinters = pfPrinters + str;
    }
    window.emailjs.send(
      'mailgun',
      process.env.REACT_APP_EMAILJS_TEMPLATEID_ADDED_PRINTERS,
      { email, firstName, lastName, phoneNum, uuid, pfPrinters }
    )
  }


  didSelectPfPrinter(printer) {
    if (printer.name == null) { return; }

    let index = this.printerIsSelected(printer);
    if (index >= 0) {
      this.setState({ pfPrintersSelected: this.state.pfPrintersSelected.filter((x,i) => i != index) });
    }
    else {
      this.setState({ pfPrintersSelected: this.state.pfPrintersSelected.concat(printer) });
    }
  }
  printerIsSelected(printer) {
    for (var i = 0; i < this.state.pfPrintersSelected.length; i++) {
        if (this.state.pfPrintersSelected[i].serialNumber == printer.serialNumber) {
            return i;
        }
    }
    return -1;
  }


  renderTonerLevels1(index) {
    return (
      (index == -1) ?
      <td className="">
        <div className="inkLevelPendingDiv centerStuffClass">
          <p style={{'fontSize': '0.85em'}}></p>
        </div>
      </td>
       :
      this.props.dcaPrinters ?
        this.props.dcaPrinters[index].tonerLevels ?
          this.props.dcaPrinters[index].tonerLevels.length >= 4 ?
            <td className="barPercentages">
              <div>
                <p>{this.props.dcaPrinters[index].tonerLevels[0].value}%</p>
                <p>{this.props.dcaPrinters[index].tonerLevels[1].value}%</p>
                <p>{this.props.dcaPrinters[index].tonerLevels[2].value}%</p>
                <p>{this.props.dcaPrinters[index].tonerLevels[3].value}%</p>
              </div>
            </td>
          :
            <td className="barPercentages">
              <div>
                <p>{this.props.dcaPrinters[index].tonerLevels[0].value}%</p>
              </div>
            </td>
        :
          <div className="centerStuffClass">
            <br/><br/>
            <ScaleLoader loading={true} color="#27AAEA" size="1px" margin="3px"/>
          </div>
      :
        <div className="centerStuffClass">
          <br/><br/>
          <ScaleLoader loading={true} color="#27AAEA" size="1px" margin="3px"/>
        </div>
    )
  }
  renderTonerLevels2(index) {
    return (
      (index == -1) ? null :
      this.props.dcaPrinters ?
        this.props.dcaPrinters[index].tonerLevels ?
          this.props.dcaPrinters[index].tonerLevels.length >= 4 ?
            <td>
              <div className="bar_fill bar1" style={{'width': (this.props.dcaPrinters[index].tonerLevels[0].value-2)+'%'}}>
              </div>
              <div className="bar_border bar1">
              </div>
              <div className="bar_fill bar2" style={{'width': (this.props.dcaPrinters[index].tonerLevels[1].value-2)+'%'}}>
              </div>
              <div className="bar_border bar2">
              </div>
              <div className="bar_fill bar3" style={{'width': (this.props.dcaPrinters[index].tonerLevels[2].value-2)+'%'}}>
              </div>
              <div className="bar_border bar3">
              </div>
              <div className="bar_fill bar4" style={{'width': (this.props.dcaPrinters[index].tonerLevels[3].value-2)+'%'}}>
              </div>
              <div className="bar_border bar4">
              </div>
            </td>
          :
            <td>
              <div className="bar_fill bar1" style={{'width': (this.props.dcaPrinters[index].tonerLevels[0].value-2)+'%'}}>
              </div>
              <div className="bar_border bar1">
              </div>
            </td>
        :
          null
      :
        null
    )
  }


  render() {
    return (
      <div>
        {this.state.showingPopover2 ?
          this.renderPopover2()
        :
          this.state.showingPopover ?
            this.renderPopover()
          :
            null
        }
        <div>
          {this.renderDevices()}
        </div>
        <div>
          <h1></h1>
        </div>

      </div>


    );
  }


  formatDate(dateStr)
  {
    var date = new Date(dateStr);
    var options = {year: 'numeric', month: 'short', day: 'numeric' };

    return date.toLocaleDateString("en-US", options);
  }



}
