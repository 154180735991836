import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";

import * as firebase from 'firebase';


export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      failedLogin: false,
      email: "",
      password: ""
    };

  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });
    await this.loginUser();
    this.setState({ isLoading: false });
  }

  async loginUser() {
    var errorMessage = null;
    await firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).catch(function(error) {
      errorMessage = error.message;
    });
    if (errorMessage == null) {
      this.setState({ failedLogin: false });
      this.props.userHasAuthenticated(true);
    }
    else {
      this.setState({ failedLogin: true });
      this.props.userHasAuthenticated(false);
    }
  }


  /*
  handleClick = event => {
      var apiBaseUrl = "http://localhost:4000/api/";
      var self = this;
      var payload = {
        "email": this.state.username,
        "password": this.state.password
      }
      axios.post(apiBaseUrl + 'login', payload)
        .then(function(response) {
          console.log(response);
          if (response.data.code == 200) {
            console.log("Login successfull");
            var uploadScreen = [];
            uploadScreen.push( < UploadScreen appContext = {
              self.props.appContext
            }/>)
            self.props.appContext.setState({
              loginPage: [],
              uploadScreen: uploadScreen
            })
          }
          else if (response.data.code == 204) {
            console.log("Username password do not match");
            alert("username password do not match")
          } else {
            console.log("Username does not exists");
            alert("Username does not exist");
          }
        })
        .catch(function(error) {
          console.log(error);
        });
  }
  */


  render() {
    return (
      <div>
        <div className="Login">
          <form onSubmit={this.handleSubmit}>
            <FormGroup controlId="email" bsSize="large">
              <ControlLabel>Email</ControlLabel>
              <FormControl
                type="email"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup controlId="password" bsSize="large">
              <ControlLabel>Password</ControlLabel>
              <FormControl
                value={this.state.password}
                onChange={this.handleChange}
                type="password"
              />
            </FormGroup>
            {this.state.failedLogin ?
              <div>
                <p style={{'color': '#e82020', 'marginBottom': '4px'}}>Invalid email or password</p>
              </div>
            :
              null
            }
            <LoaderButton
              block
              bsSize="large"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Login"
              loadingText="Logging in…"
            />
          </form>
        </div>
      </div>
    );
  }
}
