import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { PageHeader, ListGroup, ListGroupItem, Navbar, Nav2, NavItem, Button, Glyphicon } from "react-bootstrap";
import { Container, Table, Col, Row, Form, FormGroup, Label, Input, FormText, FormFeedback, } from 'reactstrap';
import { LinkContainer } from "react-router-bootstrap";
import LoaderButton from "../components/LoaderButton";

import { MemoryRouter as Router, Route, Switch } from "react-router-dom";

import Login from "./Login";
import Portal from "./Portal";

import "./Home.css";

import * as firebase from 'firebase';
var shopifyHelper = require('../API_helpers/ShopifyHelper')

var firebaseDbHelper = require('../API_helpers/FirebaseDbHelper')
var axessHelper = require('../API_helpers/AxessECommerceHelper')
var ScaleLoader = require('halogen/ScaleLoader');


export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: false,

      user: null,
      pressedLogin: false,

      showingPopover: false,
      popoverTitle: "",
      popoverDetails: "",


      // user info
      firstName: "",
      lastName: "",
      email: "",
      phoneNum: "",
      password: "",
      passwordConfirm: "",
      acceptedTerms: false,
      verifyingEmail: false,
      verifiedEmail: false,

      validate: {
        firstNameState: '',
        lastNameState: '',
        emailState: '',
        phoneNumState: '',
        passwordState: '',
        passwordConfirmState: '',
        acceptedTermsState: '',
      }

    };
  }

  componentWillMount() {
    this.handleWindowSizeChange();
    window.addEventListener('resize', this.handleWindowSizeChange);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }
  handleWindowSizeChange = () => {
    let mobile = window.innerWidth <= 600;
    this.setState({ isMobile: mobile });
  };


  renderPopover() {
    this.scrollToTop(2.0);
    return (
      <div className="popoverDiv centerStuffClass">
        <div className="popoverDivInner centerStuffClass" style={{'left': 'calc(50vw - 220px)'}}>
          <div style={{'margin': '16px 12px'}}>
            <h2>{this.state.popoverTitle}</h2>
            <p>{this.state.popoverDetails}</p>

            <div style={{'marginTop': '35px'}}>
              <Button style={{'padding':"12px 38px", 'margin': '0 auto'}} bsStyle="primary" bsSize="large" onClick={this.didTapAlertOk.bind(this)}>OK</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  didTapAlertOk() {
    this.setState({ showingPopover: false });
  }


  renderLoading() {
    return (
      <div className="lander">
      </div>
    )
  }

  renderLanding() {
    if (this.state.isMobile) {
      return (
        <div className="" style={{'textAlign': 'center'}}>
          <img style={{'width': '178px', 'height': '70px', 'marginTop': '-30px', 'marginBottom': '20px'}} src="https://www.unlimitedinkclub.com/images/branding.png"/>

          <div className="" style={{'marginBottom':'190px'}}>
            <h2 style={{'lineHeight':'1.25em'}}><b>Complete set of ink/toner sent to you immediately!!</b><br/>Then UNLIMITED ink provided for the rest of the year!</h2>
            <h2 style={{'lineHeight':'1.25em'}}>Offer 1: <b>$10/month*</b></h2>
            <p className="offerDetails" style={{'marginTop':'-25px'}}>Complete set of ink shipped immediately.</p>
            <p className="offerDetails">Auto-replenishment program, monitors your printers ink levels and ships new cartridges to your door before you ever run out.</p>
            <p className="offerDetails">12 month commitment. After initial 12 months, subscription bills month to month.</p>
            <p className="offerDetails">After 12 months, cancel any time.</p>
            <br/>
            <h2 style={{'lineHeight':'1.25em'}}>Offer 2: <b>$75 one time annual payment (LIMITED TIME OFFER)**</b></h2>
            <p className="offerDetails" style={{'marginTop':'-25px'}}>Complete set of ink shipped immediately.</p>
            <p className="offerDetails">Auto-replenishment program, monitors your printers ink levels and ships new cartridges to your door before you ever run out.</p>
            <p className="offerDetails">After first year, bills at an annual rate of $120.</p>
            <p className="offerDetails">Cancel any time.</p>
            <br/>
            <div className="centerStuffClass limitedTimeOfferDivMobile">
              <img src={require('../imgs/limitedTimeOffer.png')} className="" id="" />
            </div>
          </div>

          <div className="landingLeftDivMobile">
            <br/>
            {this.makeSignupForm()}
            <br/>
            <div style={{'textAlign': 'center'}}>
              <Button style={{'padding':"15px 38px", 'margin': '0 auto'}} onClick={this.didClickGetStarted.bind(this)}>Get Started</Button>
            </div>
            <p className="disclaimerText" style={{'marginTop':'5px', 'marginBottom':'0px'}}>(Payment option will be selected at checkout)</p>
            <br/><br/>
          </div>

          <div className="alreadyHaveMobile">
            <p><b>Already have an account?</b></p>
            <Button style={{'padding':"15px 38px"}} onClick={this.didClickLogin.bind(this)}>Login</Button>
          </div>

          <br/><br/>

          <iframe src='https://www.youtube.com/embed/T-ooEAz3zis' width="320" height="250"
            frameBorder='0'
            allow='autoplay; encrypted-media'
            allowFullScreen
            title='Unlimited Ink Club'
          />

          <br/><br/>

          <div>
            <p className="disclaimerText">*Your agreement will be a commitment of $10 a month for 12 consecutive months from the day of signup. After the initial 12 months, you will be billed on a month-to-month basis and can cancel at any time. Based off the printer make/model you provide at signup, you will be shipped a full set of ink cartridges immediately after signup is completed. To take advantage of the ink cartridges being shipped to you directly you must download the monitoring software.
              </p>

            <p className="disclaimerText">**Your agreement will be for $75 for 12 months from the day of signup. You may cancel at any time. Based off the printer make/model you provide at signup you will be shipped a full set of ink cartridges immediately after signup is completed. To take advantage of the free ink cartridges being shipped to you directly you must download the monitoring software. After the 12 months you will be billed annually at a recurring rate of $120 and will receive ink cartridges via the monitoring software.
              </p>
            <div className="centerStuffClass">
              <a href="https://www.unlimitedinkclub.com/privacy-policy.php"><p>Privacy Policy</p></a>
            </div>
          </div>

        </div>
      )
    }
    else {
      return (
        <div className="">
          <br/>

          <div className="landingLeftDiv">
            <br/><br/><br/>
            {this.makeSignupForm()}
            <br/>
            <div style={{'textAlign': 'center'}}>
              <Button style={{'padding':"15px 38px"}} onClick={this.didClickGetStarted.bind(this)}>Get Started</Button>
            </div>
            <p className="disclaimerText" style={{'marginTop':'5px', 'marginBottom':'0px'}}>(Payment option will be selected at checkout)</p>
            <br/><br/>
          </div>


          <div className="landingRightDiv">
            <h2 style={{'lineHeight':'1.25em'}}><b>Complete set of ink/toner sent to you immediately!!</b> Then UNLIMITED ink provided for the rest of the year!</h2>
            <h2 style={{'lineHeight':'1.25em'}}>Offer 1: <b>$10/month*</b></h2>
            <p className="offerDetails" style={{'marginTop':'-7px'}}>Complete set of ink shipped immediately.</p>
            <p className="offerDetails">Auto-replenishment program, monitors your printers ink levels and ships new cartridges to your door before you ever run out.</p>
            <p className="offerDetails">12 month commitment. After initial 12 months, subscription bills month to month.</p>
            <p className="offerDetails">After 12 months, cancel any time.</p>
            <br/>
            <h2 style={{'lineHeight':'1.25em'}}>Offer 2: <b>$75 one time annual payment (LIMITED TIME OFFER)**</b></h2>
            <p className="offerDetails" style={{'marginTop':'-7px'}}>Complete set of ink shipped immediately.</p>
            <p className="offerDetails">Auto-replenishment program, monitors your printers ink levels and ships new cartridges to your door before you ever run out.</p>
            <p className="offerDetails">After first year, bills at an annual rate of $120.</p>
            <p className="offerDetails">Cancel any time.</p>
            <br/>
            <div className="centerStuffClass limitedTimeOfferDiv">
              <img src={require('../imgs/limitedTimeOffer.png')} className="" id="" />
            </div>


            {/*
            <h2>Then, thanks to our ink-monitoring software, any additional cartridges needed for the next year will
               automatically be sent to you for <b>FREE</b> before you even run out.</h2>
             <br/>
           */}


            {/*
            <iframe src='https://www.youtube.com/embed/T-ooEAz3zis' width="320" height="250"
              frameBorder='0'
              allow='autoplay; encrypted-media'
              allowFullScreen
              title='Unlimited Ink Club'
            />
            */}
          <br/>
            <p className="disclaimerText">*Your agreement will be a commitment of $10 a month for 12 consecutive months from the day of signup. After the initial 12 months, you will be billed on a month-to-month basis and can cancel at any time. Based off the printer make/model you provide at signup, you will be shipped a full set of ink cartridges immediately after signup is completed. To take advantage of the ink cartridges being shipped to you directly you must download the monitoring software.
              </p>
            <p className="disclaimerText">**Your agreement will be for $75 for 12 months from the day of signup. You may cancel at any time. Based off the printer make/model you provide at signup you will be shipped a full set of ink cartridges immediately after signup is completed. To take advantage of the free ink cartridges being shipped to you directly you must download the monitoring software. After the 12 months you will be billed annually at a recurring rate of $120 and will receive ink cartridges via the monitoring software.</p>
            <div className="centerStuffClass">
              <a href="https://www.unlimitedinkclub.com/privacy-policy.php"><p>Privacy Policy</p></a>
            </div>
          </div>


          <div className="alreadyHave">
            <p><b>Already have an account?</b></p>
            <Button style={{'padding':"15px 38px", 'marginBottom':'65px'}} onClick={this.didClickLogin.bind(this)}>Login</Button>
            <br/>
            <iframe src='https://www.youtube.com/embed/T-ooEAz3zis' width="320" height="260"
              frameBorder='0'
              allow='autoplay; encrypted-media'
              allowFullScreen
              title='Unlimited Ink Club'
            />
          </div>

        </div>
      )
    }
  }




  async didClickGetStarted()
  {
    if (!this.validatePage1()) {
      return;
    }

    this.setState({ verifyingEmail: true });

    if (!this.state.verifiedEmail) {
      await this.attemptCreateUserFirebase();
      if (this.state.verifiedEmail) {
        this.sendRegisteredEmail();
      }
    }

    if (this.state.verifiedEmail) {
      await this.pushUserDataToFirebase();
      await this.postUserToShopify();
      if (this.state.uploadedToFirebase)   {
        this.props.history.push("/signup");
      }
    }

    this.setState({ verifyingEmail: false });
  }

  didClickLogin() {
    this.scrollToTop(2.0);
    this.setState({ pressedLogin: true });
  }

  renderLogin() {
    const mTop = this.state.isMobile ? '30px' : '0px';
    const mBot = this.state.isMobile ? '7px' : '15px';

    return (
      <div className="lander" style={{'textAlign': 'center'}}>
        <div className="loginDiv">
          {this.state.isMobile ? <img style={{'width': '178px', 'height': '70px', 'marginTop': '-30px', 'marginBottom': '20px'}} src="https://www.unlimitedinkclub.com/images/branding.png"/>
          :
            <img style={{'width': '178px', 'height': '70px', 'marginTop': '10px', 'marginBottom': '20px'}} src="https://www.unlimitedinkclub.com/images/branding.png"/>
          }
          <br/>
          {<Login userHasAuthenticated={this.props.userHasAuthenticated} />}
        </div>
        <p className="" style={{'marginTop': mTop, 'marginBottom': mBot}}>Don't have an account?</p>
        <Link to="/signup"><Button className="centerSignUpButton" style={{'padding':"15px 38px", 'margin': '0 auto'}}>Sign Up</Button></Link>
      </div>
    );
  }




  renderPortal() {
    return (
      <Portal userHasAuthenticated={this.props.userHasAuthenticated} isMobile={this.state.isMobile} history={this.props.history} />
    )
  }

  render() {
    return (
      <div>
        <div className="landingDiv">
        </div>

        {this.state.showingPopover ?
          this.renderPopover() : null
        }

        <div className="Home">
          {this.props.isAuthenticating ? this.renderLoading() :
            (this.props.isAuthenticated && !this.state.verifyingEmail && !this.state.verifiedEmail) ? this.renderPortal() :
              this.state.pressedLogin ? this.renderLogin() : this.renderLanding()}
        </div>

      </div>
    );
  }


  sendRegisteredEmail() {
    // USER SIGNED UP! SEND EMAIL
    var user = firebaseDbHelper.getCurrentUser();
    var email = this.state.email;
    var firstName = this.state.firstName;
    var lastName = this.state.lastName;
    var phoneNum = this.state.phoneNum;
    var uuid = user['uid'];
    window.emailjs.send(
      'mailgun',
      process.env.REACT_APP_EMAILJS_TEMPLATEID_REGISTERED,
      { email, firstName, lastName, phoneNum, uuid,  }
    )
  }


  scrollToTop(scrollDuration) {
      var scrollStep = -window.scrollY / (scrollDuration / 15),
          scrollInterval = setInterval(function(){
          if ( window.scrollY != 0 ) {
              window.scrollBy( 0, scrollStep );
          }
          else clearInterval(scrollInterval);
      },15);
  }

  async attemptCreateUserFirebase() {
    var user = firebaseDbHelper.getCurrentUser();

    var response = await firebaseDbHelper.createNewUser(this.state.email, this.state.password);
    if (response.success) {
      var user = firebaseDbHelper.getCurrentUser();
      if (user != null) {
        // console.log("----- NEW USER CREATED -----");
        // console.log("UID: " + ;
        // console.log("Email: " + user['email']);
      }
      this.setState({ verifiedEmail: true });
      this.props.userHasAuthenticated(true);
    }
    else {
      this.setState({showingPopover: true, popoverTitle: 'WHOOPS', popoverDetails: response.errorMsg})
    }
  }


  async pushUserDataToFirebase()
  {
    var user = firebaseDbHelper.getCurrentUser();
    if (user != null) {
      await firebaseDbHelper.updateUserInfo(user['uid'], this.state.email, this.state.firstName, this.state.lastName, this.state.phoneNum);
      this.setState({ uploadedToFirebase: true });
    }
  }

  async postUserToShopify() {
    let result = await shopifyHelper.postNewUser(this.state.email, this.state.firstName, this.state.lastName, this.state.phoneNum);
    if (result == "-3") {
      await shopifyHelper.postNewUser(this.state.email, this.state.firstName, this.state.lastName, "NO_PHONE");
    }
  }


  makeSignupForm() {
    const leftCheck = (this.state.isMobile ? '10%' : '20%');
    return (
      <div>
        <Form className="form" onSubmit={ (e) => null }>
            <Col>
              <td>
                <FormGroup style={{'width': '95%'}}>
                  <Label>First Name</Label>
                  <Input style={{'padding-right': '50px'}}
                    type = "text"
                    name = "firstName"
                    id = "firstName"
                    value={ this.state.firstName }
                    valid={ this.state.validate.firstNameState === 'has-success' }
                    invalid={ this.state.validate.firstNameState === 'has-danger' }
                    onChange={ (e) => { this.handleChange(e) } }
                    onBlur={ (e) => { this.validateLabel(e) }}
                  />
                </FormGroup>
              </td>
              <td>
                <FormGroup style={{'width': '95%', 'margin-left': '5%'}}>
                  <Label>Last Name</Label>
                  <Input style={{'padding-right': '50px'}}
                    type = "text"
                    name = "lastName"
                    id = "lastName"
                    value={ this.state.lastName }
                    valid={ this.state.validate.lastNameState === 'has-success' }
                    invalid={ this.state.validate.lastNameState === 'has-danger' }
                    onChange={ (e) => { this.handleChange(e) }}
                    onBlur={ (e) => { this.validateLabel(e) }}
                  />
                {/* <FormFeedback valid>Cool name!</FormFeedback> */}
                </FormGroup>
              </td>
            </Col>
            <Col>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  type = "email"
                  name = "email"
                  id = "email"
                  value={ this.state.email }
                  valid={ this.state.validate.emailState === 'has-success' }
                  invalid={ this.state.validate.emailState === 'has-danger' }
                  onChange={ (e) => { this.handleChange(e) }}
                  onBlur={ (e) => { this.validateLabel(e) }}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Phone Number</Label>
                <Input
                  type = "text"
                  name = "phoneNum"
                  id = "phoneNum"
                  value={ this.formatPhoneNumber(this.state.phoneNum) }
                  valid={ this.state.validate.phoneNumState === 'has-success' }
                  invalid={ this.state.validate.phoneNumState === 'has-danger' }
                  onChange={ (e) => { this.handleChange(e) }}
                  onBlur={ (e) => { this.validateLabel(e) }}
                />
              </FormGroup>
            </Col>
            <Col>
              <td>
                <FormGroup style={{'width': '95%'}}>
                  <Label>Password</Label>
                  <Input style={{'padding-right': '50px'}}
                    type="password"
                    name="password"
                    id="password"
                    placeholder=""
                    value={ this.state.password }
                    valid={ this.state.validate.passwordState === 'has-success' }
                    invalid={ this.state.validate.passwordState === 'has-danger' }
                    onChange={ (e) => { this.handleChange(e) }}
                    onBlur={ (e) => { this.validateLabel(e) }}
                />
                </FormGroup>
              </td>
              <td>
                <FormGroup style={{'width': '95%', 'margin-left': '5%'}}>
                  <Label>{this.state.isMobile ? "Pwd Confirm" : "Password Confirm"}</Label>
                  <Input style={{'padding-right': '50px'}}
                    type="password"
                    name="passwordConfirm"
                    id="passwordConfirm"
                    placeholder=""
                    value={ this.state.passwordConfirm }
                    valid={ this.state.validate.passwordConfirmState === 'has-success' }
                    invalid={ this.state.validate.passwordConfirmState === 'has-danger' }
                    onChange={ (e) => { this.handleChange(e) }}
                    onBlur={ (e) => { this.validateLabel(e) }}
                />
                </FormGroup>
              </td>
              <FormText style={{'marginTop': '-8px'}} className="passwordHintText">Password must:</FormText>
              <FormText className="passwordHintText">   - be at least 8 characters</FormText>
              <FormText className="passwordHintText">   - contain one lowercase letter, uppercase letter, and number</FormText>
            </Col>
            <br/>
            <br/>

            {!this.state.verifyingEmail ?
              <Col>

                <FormGroup  className="centerStuffClass" style={{'marginTop': '18px', 'height':'50px', 'margin-bottom':'22px'}} check>
                  <Label style={{'position':'absolute', 'left':leftCheck, 'marginTop': '8px'}} check>
                    <Input
                      type="checkbox"
                      name="acceptedTerms"
                      id="acceptedTerms"
                      checked={ this.state.acceptedTerms }
                      onChange={ (e) => { this.handleChange(e) }}
                    />
                  </Label>
                  <p className="mainText" style={{'lineHeight':'25px', 'fontSize':'0.85em', 'marginTop': '-3px'}}>I agree to the Unlimited Ink Club {this.state.isMobile ? "" : <br/>}<Link to="/terms-and-conditions" target="_blank">terms & conditions</Link></p>
                </FormGroup>
              </Col>
              :
              null
            }


            {(this.state.verifyingEmail) ?
              <Col>
              <div className="centerStuffClass loaderDiv">
                <br/><br/><br/><br/><br/>
                <h2><b>VERIFYING EMAIL...</b></h2>
                <ScaleLoader loading={true} color="#27AAEA" size="16px" margin="4px"/>
                <br/><br/>
              </div>
              </Col>
              :
              null
            }

            {!this.state.verifyingEmail && false ?
              <div>
                <Col>
                  <div style={{'height':'26px'}} />
                </Col>
                <div className="centerStuffClass" style={{'marginTop':'0px'}}>
                  <br/><br/><br/>
                  <p className="mainText" style={{'lineHeight':'25px', 'fontSize':'0.85em'}}>By creating an account, you agree<br/>to our <Link to="/terms-and-conditions" target="_blank">terms & conditions</Link></p>
                  <br/>
                </div>
              </div>
            :
              null
            }
          </Form>
      </div>
    )
  }


  handleChange = async event => {
    let dLength = Math.abs(this.state[event.target.id].length - event.target.value.length);

    if (event.target.id == 'phoneNum') {
        this.setState({ [event.target.id]: this.unformatPhoneNumber(event.target.value) });
    }
    else if (event.target.id == 'acceptedTerms') {
      this.setState({ [event.target.id]: !this.state.acceptedTerms });
    }
    else {
      this.setState({ [event.target.id]: event.target.value });
    }

    if (dLength > 1) {
      this.validateLabel(event);
    }
  }

  validateLabel(e) {
    let _id = e.target.id;
    let _value = e.target.value;
    if (_id == 'phoneNum') {
      this.validateValue(_id, this.unformatPhoneNumber(_value));
    }
    else {
      this.validateValue(_id, _value);
    }
  }

  validateValue(_id, _value) {
    const { validate } = this.state
      if (_id == "firstName") {
        validate.firstNameState = (_value.length > 0) ? 'has-success' : 'has-danger';
      }
      else if (_id == "lastName") {
        validate.lastNameState = (_value.length > 0) ? 'has-success' : 'has-danger';
      }
      else if (_id == "email") {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const { validate } = this.state
          if (emailRex.test(_value)) {
            validate.emailState = 'has-success'
          } else {
            validate.emailState = 'has-danger'
          }
          this.setState({ validate })
      }
      else if (_id == "phoneNum") {
        validate.phoneNumState = (_value.length >= 3) ? 'has-success' : 'has-danger';
      }
      else if (_id == "password") {
        let lengthGood = (_value.length >= 8);
        let lowercaseGood =  (_value.match(/[a-z]/));
        let uppercaseGood =  (_value.match(/[A-Z]/));
        let numberGood =  (_value.match(/[0-9]/));
        let charsGood = lowercaseGood && uppercaseGood && numberGood;
        validate.passwordState = (lengthGood && charsGood) ? 'has-success' : 'has-danger';
      }
      else if (_id == "passwordConfirm") {
        this.validateValue("password", this.state.password);
        validate.passwordConfirmState = (_value == this.state.password && (validate.passwordState == 'has-success')) ? 'has-success' : 'has-danger';
      }
      else if (_id == "acceptedTerms") {
        //validate.acceptedTermsState = (_value == true) ? 'has-success' : 'has-danger';
      }

      this.setState({ validate })
  }

  validatePage1() {
    this.validateValue("firstName", this.state.firstName);
    this.validateValue("lastName", this.state.lastName);
    this.validateValue("email", this.state.email);
    this.validateValue("phoneNum", this.state.phoneNum);
    this.validateValue("password", this.state.password);
    this.validateValue("passwordConfirm", this.state.passwordConfirm);

    const { validate } = this.state
    let itsGood = (validate.firstNameState === 'has-success')
                  && (validate.lastNameState === 'has-success')
                  && (validate.emailState  === 'has-success')
                  && (validate.phoneNumState  === 'has-success')
                  && (validate.passwordState  === 'has-success')
                  && (validate.passwordConfirmState  === 'has-success'
                  && this.state.acceptedTerms);

    return itsGood;
  }




  formatPhoneNumber(number) {
    if (number.length == 10 && number[0] != "1") {
      let numberFormatted = "(" + number.substring(0,3) + ") " + number.substring(3,6) + "-" + number.substring(6,10);
      return numberFormatted;
    }
    else if (number.length == 11 && number[0] == "1") {
      let numberFormatted = "1 (" + number.substring(1,4) + ") " + number.substring(4,7) + "-" + number.substring(7,11);
      return numberFormatted;
    }
    return number;
  }
  unformatPhoneNumber(number) {
    var numberUnformatted = number.replace(/ /g, '');
    numberUnformatted = numberUnformatted.replace(/\(/g, '');
    numberUnformatted = numberUnformatted.replace(/\)/g, '');
    numberUnformatted = numberUnformatted.replace(/-/g, '');
    return numberUnformatted;
  }




}
