import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { PageHeader, ListGroup, ListGroupItem, Navbar, Nav2, NavItem, Button, Glyphicon } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { Icon as BaseIcon } from "react-icons-kit";
import { print } from "react-icons-kit/fa/print";
import { check } from "react-icons-kit/fa/check";

import './Portal.css';
import './Signup.css';

const IconL = props => <BaseIcon size={56} icon={props.icon} />;
const IconM = props => <BaseIcon size={42} icon={props.icon} />;
const IconS = props => <BaseIcon size={34} icon={props.icon} />;
const IconXS = props => <BaseIcon size={29} icon={props.icon} />;

var ScaleLoader = require('halogen/ScaleLoader');

var firebaseDbHelper = require('../API_helpers/FirebaseDbHelper')
var authorizeNetHelper = require('../API_helpers/AuthorizeNetHelper')

export class PortalDashboard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      devices: [],
      shipments: [],

      addingPrinters: false,
      showingPopover: false,
      showingPopover2: false,
      popoverDetails: "",
      pfPrintersSelected: [],
      authNetSubscriptionId: "",

    };
  }


  didTapAlertOk() {

  }

  renderAlerts() {
    return (
      <div id="dashboardAlerts" className="dashboardView centerStuffClass">
        {this.props.alertInfo ?
          this.props.alertInfo.code == '00001' ?
            <div style={{'marginTop': '5px'}}>
              <br/><br/>
              <p><b>Sign up incomplete, please finish!</b></p>
              <Link to="/signup"><Button style={{'marginTop': '-20px', 'marginBottom': '20px', 'padding':"12px 38px"}} bsStyle="primary" bsSize="large" onClick={this.didTapAlertOk.bind(this)}>FINISH SIGN UP</Button></Link>
            </div>
          :
          this.props.alertInfo.code == '00002' ?
            <div style={{'marginTop': '5px'}}>
              <br/><br/>
              <h2><b>Our database is still processing your printers</b></h2>
              <h2>Make sure printers are <u>turned on</u> and <u>awake</u></h2>
              <br/>
              <h2 className="mainText">This usually takes just a couple minutes,<br/>but can occasionally take up to 10-15 minutes</h2>
              <br/>
              <h2 className="mainText">This page will automatically refresh when finished.<br/>An email will also be sent to notify you</h2>
            </div>
          :
          this.props.alertInfo.code == '00003' ?
            <div style={{'marginTop': '5px'}}>
              <br/>
              {this.renderNetworkDevices()}
            </div>
          :
          this.props.alertInfo.code == '00005' ?
            <div style={{'marginTop': '5px'}}>
              <h1>Alerts</h1>
              <p>Please install our printer monitoring software so we can <br/>automatically send you new ink cartridges when you are running low!</p>
              <h3 className="twoButtonStyle twoButtonStyleL"><a href={this.props.userInfo.pfDcas[0].urlPC}><b>WINDOWS</b></a></h3>
              <h3 className="twoButtonStyle twoButtonStyleR"><a href={this.props.userInfo.pfDcas[0].urlMAC}><b>MAC</b></a></h3>
            </div>
          :
          this.props.alertInfo.code == '00009' ?
            <div>
              <h1>Alerts</h1>
              <p style={{'marginBottom':'4px'}}>{this.props.alertInfo.message}</p>
              <ScaleLoader loading={true} color="#27AAEA" size="4px" margin="2px" style={{'marginBottom':'18px'}}/>
            </div>
          :
            <div>
              <h1>Alerts</h1>
              <p>{this.props.alertInfo.message}</p>
            </div>
        :
          <div className="centerStuffClass">
            <h1>Alerts</h1>
            <ScaleLoader loading={true} color="#27AAEA" size="16px" margin="4px"/>
          </div>
        }
      </div>
    )
  }

  renderPopover() {
    return (
      <div className="popoverDiv centerStuffClass">
        <div className="popoverDivInner centerStuffClass">
          <div style={{'margin': '16px 12px'}}>
            <h2>Apply Changes</h2>
            <p>Adding {this.state.pfPrintersSelected.length} printer{this.state.pfPrintersSelected.length != 1 ? 's' : ''} to your Unlimited Ink account</p>
            <p>Changes will be reflected on your next payment</p>

            <div style={{'marginTop': '35px'}}>
              <Button style={{'padding':"12px 38px"}} bsStyle="primary" bsSize="large" onClick={this.didTapAlertOk.bind(this)}>OK</Button>
              &emsp;
              <Button style={{'padding':"12px 38px"}} bsStyle="primary" bsSize="large" onClick={this.didTapAlertCancel.bind(this)}>Cancel</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  renderPopover2(message) {
    return (
      <div className="popoverDiv centerStuffClass">
        <div className="popoverDivInner centerStuffClass">
          <div style={{'margin': '16px 12px'}}>
            <h2>WHOOPS</h2>
            <p>{this.state.popoverDetails}</p>
            <div style={{'marginTop': '35px'}}>
              <Button style={{'padding':"12px 38px"}} bsStyle="primary" bsSize="large" onClick={this.didTapAlertCancel.bind(this)}>OK</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  didTapApply() {
    this.setState({ showingPopover: true });
  }

  async didTapAlertOk() {
    if (this.state.addingPrinters) { return; }
    this.setState({ addingPrinters: true });

    var billing = this.props.userInfo.billings[this.props.userInfo.selectedBilling];
    let amount = this.getPriceForNumPrinters(this.state.pfPrintersSelected.length);
    let orderInfo = { name: this.props.userInfo.firstName + ' ' + this.props.userInfo.lastName, amount: amount, };
    authorizeNetHelper.createSubscriptionFromCustomerProfile(orderInfo, billing.authNetProfileId, billing.authNetPaymentId, 'addressId', function(response) {
      if (response.success) {
        // console.log("----- AUTH.NET SUBSCIPTION PAYMENT SUCCESS -----");
        this.setState({ authNetSubscriptionId: response.subscriptionId });
        this.createSubscriptionSuccessful();
      } else {
        this.setState({ addingPrinters: false });
        if (response.message.includes("duplicate")) {
          var user = firebaseDbHelper.getCurrentUser();
          var email = this.props.userInfo.email;
          var firstName = this.props.userInfo.firstName;
          var lastName = this.props.userInfo.lastName;
          var phoneNum = this.props.userInfo.phoneNum;
          var authNetProfileId = billing.authNetProfileId;
          var authNetPaymentId = billing.authNetPaymentId;
          var uuid = user['uid'];
          window.emailjs.send(
            'mailgun',
            process.env.REACT_APP_EMAILJS_TEMPLATEID_DUPLICATE_PAYMENT,
            { email, firstName, lastName, phoneNum, authNetProfileId, authNetPaymentId, uuid }
          )
          this.setState({ authNetSubscriptionId: "" });
          this.createSubscriptionSuccessful();
        } else {
          this.setState({showingPopover2: true, popoverDetails: response.message})
        }
      }
    }.bind(this));
  }

  didTapAlertCancel() {
    this.setState({ showingPopover: false, showingPopover2: false });
  }

  async createSubscriptionSuccessful() {
    var user = firebaseDbHelper.getCurrentUser();

    var printersDb = this.props.userInfo.pfPrinters;
    if (printersDb == null) { printersDb = []; }
    for (var i = 0; i < this.state.pfPrintersSelected.length; i++) {
      let printer = this.state.pfPrintersSelected[i];
      let printerInfo = {
        serial: printer.serialNumber,
        name: printer.name,
        ipAddress: printer.ipAddress,
        uuid: printer.id,
      };
      printersDb.push(printerInfo);
    }

    var payments = [];
    var date = new Date();
    var options = {year: 'numeric', month: 'short', day: 'numeric' };
    var dateStr = date.toLocaleDateString("en-US", options);
    let payment = {
      date: dateStr,
      price: this.getPriceForNumPrinters(this.state.pfPrintersSelected.length),
      devices: printersDb,
    }
    payments.push(payment);


    await firebaseDbHelper.updatePfPrinters(user['uid'], printersDb);
    await firebaseDbHelper.updatePayments(user['uid'], payments);
    await firebaseDbHelper.updateSubscriptionId(user['uid'], this.state.authNetSubscriptionId);

    // send email
    var email = this.props.userInfo.email;
    var firstName = this.props.userInfo.firstName;
    var lastName = this.props.userInfo.lastName;
    var phoneNum = this.props.userInfo.phoneNum;
    var uuid = user['uid'];
    var pfPrinters = ""
    for (var i = 0; i < this.state.pfPrintersSelected.length; i++) {
      let printer = this.state.pfPrintersSelected[i];
      var str = printer.name + " , " + printer.serialNumber + " , " + printer.ipAddress + " , " + printer.id + "<br>";
      pfPrinters = pfPrinters + str;
    }
    window.emailjs.send(
      'mailgun',
      process.env.REACT_APP_EMAILJS_TEMPLATEID_ADDED_PRINTERS,
      { email, firstName, lastName, phoneNum, uuid, pfPrinters }
    )

    this.setState({ showingPopover: false, pfPrintersSelected: [], addingPrinters: false });
    this.props.fetchUserInfo(true, true);
  }


  renderNetworkDevices() {
    return (
      (this.props.userInfo && this.props.dcaPrinters) ?
        <div>
          <h2><b>Select your printer(s)</b><br/><p style={{'fontSize':'0.725em'}}>(If you don't see your printer, it may still be processing)</p></h2>

          <div id="orderInfoDiv" className="otherDevicesTable">
              <table className="printersTable printersTable2 centerStuffClass">
                <tbody>
                  {this.props.dcaPrinters.map((printer, i) => {
                    if (!this.props.subbedIndexes.includes(i)) {
                      return (
                        <tr>
                          <td className="printersTableCol1">
                            <IconL icon={print} />
                          </td>
                          <td className="printersTableCol2">
                            {(printer.name == null) ?
                              <p><b>Still fetching device info...</b></p>
                              :
                              <div>
                                <p><b>{printer.name.split(';')[0].substring(0, 35)}</b></p>
                                <p>{printer.ipAddress}&emsp;<b>|</b>&emsp;{printer.serialNumber}</p>
                              </div>
                            }
                          </td>
                          <td className="printersTableCol3">
                            {(this.printerIsSelected(printer) >= 0) ?
                              <div>
                                <Button height='25px' style={{'padding':"12px 26px"}} onClick={this.didSelectPfPrinter.bind(this, printer)}><b><IconS icon={check} /></b></Button>
                                <p id="activeSubtext" style={{'fontSize':'0.75em', 'margin':'-3px'}}><b>UNLIMITED INK!</b></p>
                              </div>
                              :
                              <Button height='15px' style={{'padding':"14px 26px"}} onClick={this.didSelectPfPrinter.bind(this, printer)}>SELECT</Button>
                            }
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
          </div>
          <div>
            {this.state.pfPrintersSelected.length > 0 ?
              <div className="centerStuffClass" style={{'marginTop': '40px', 'marginBottom':'4px'}}>
                <Button style={{'padding':"12px 28px"}} bsStyle="primary" bsSize="large" onClick={this.didTapApply.bind(this)}>APPLY CHANGES</Button>
              </div>
            :
              null
            }
          </div>
        </div>
      :
        <div id="orderInfoDiv" className="centerStuffClass">
          <br/>
          <p>No other printers found on your network</p>
        </div>
    )
  }

  didSelectPfPrinter(printer) {
    if (printer.name == null) { return; }

    let index = this.printerIsSelected(printer);
    if (index >= 0) {
      this.setState({ pfPrintersSelected: this.state.pfPrintersSelected.filter((x,i) => i != index) });
    }
    else {
      this.setState({ pfPrintersSelected: this.state.pfPrintersSelected.concat(printer) });
    }
  }
  printerIsSelected(printer) {
    for (var i = 0; i < this.state.pfPrintersSelected.length; i++) {
        if (this.state.pfPrintersSelected[i].serialNumber == printer.serialNumber) {
            return i;
        }
    }
    return -1;
  }

  renderDevices() {
    var printers = [];
    if (this.props.userInfo != null) {
      if (this.props.userInfo.pfPrinters == null) {
        //this.props.history.push("/signup");
      }
      if (this.props.userInfo.pfPrinters != null) {
        for (var i = 0; i < this.props.userInfo.pfPrinters.length; i++) {
          var printer = this.props.userInfo.pfPrinters[i];
          if (this.props.dcaPrinters != null) {
            var gotMatch = false;
            for (var j = 0; j < this.props.dcaPrinters.length; j++) {
              if (this.props.dcaPrinters[j].serialNumber == printer.serial) {
                  printer['firstReportedAt'] = this.props.dcaPrinters[j].firstReportedAt;
                  printer['lastReportedAt'] = this.props.dcaPrinters[j].lastReportedAt;
                  printer['dcaIndex'] = j;
                  gotMatch = true;
              }
            }
            if (!gotMatch) { printer['dcaIndex'] = -1; }
          }
          else {
            printer['dcaIndex'] = -1;
          }
          printers.push(printer);
        }
      }
    }

    return (
      <div id="dashboardDevices" className="dashboardView centerStuffClass">
        <h1>Printers</h1>
        {this.props.userInfo ?
          this.props.userInfo.pfPrinters ?
            <div id="dashboardDevicesTable">
              <table className="printersTable printersTable2 centerStuffClass">
                <tbody>
                  {printers.map((printer, i) => {
                    //if (this.props.subbedIndexes.includes(i)) {
                      return (
                        <tr>
                          <td className="">
                            <IconL icon={print} />
                          </td>
                          <td className="">
                            <div>
                              <p><b>{printer.name}</b></p>
                              {printer.dcaIndex != -1 ? <p>{printer.ipAddress}&emsp;<b>|</b>&emsp;{printer.serial}</p> : null}
                            </div>
                          </td>
                          {this.renderTonerLevels1(printer.dcaIndex)}
                          {this.renderTonerLevels2(printer.dcaIndex)}
                        </tr>
                      );
                    //}
                  })}
                </tbody>
              </table>
            </div>
          :
            <div id="" >
              <br/>
              <p>No printers subscribed</p>
            </div>
        :
          <div className="centerStuffClass">
            <br/><br/>
            <ScaleLoader loading={true} color="#27AAEA" size="16px" margin="4px"/>
          </div>
        }
      </div>
    )
  }

  renderTonerLevels1(index) {
    return (
      (index == -1) ?
      <td className="">
        <div className="inkLevelPendingDiv centerStuffClass">
          <p></p>
        </div>
      </td>
       :
      this.props.dcaPrinters ?
        this.props.dcaPrinters[index].tonerLevels ?
          this.props.dcaPrinters[index].tonerLevels.length >= 4 ?
            <td className="barPercentages">
              <div>
                <p>{this.props.dcaPrinters[index].tonerLevels[0].value}%</p>
                <p>{this.props.dcaPrinters[index].tonerLevels[1].value}%</p>
                <p>{this.props.dcaPrinters[index].tonerLevels[2].value}%</p>
                <p>{this.props.dcaPrinters[index].tonerLevels[3].value}%</p>
              </div>
            </td>
          :
            <td className="barPercentages">
              <div>
                <p>{this.props.dcaPrinters[index].tonerLevels[0].value}%</p>
              </div>
            </td>
        :
        <div className="centerStuffClass">
          <br/><br/>
          <ScaleLoader loading={true} color="#27AAEA" size="1px" margin="3px"/>
        </div>
      :
        <div className="centerStuffClass">
          <br/><br/>
          <ScaleLoader loading={true} color="#27AAEA" size="1px" margin="3px"/>
        </div>
    )
  }
  renderTonerLevels2(index) {
    return (
      (index == -1) ? null :
      this.props.dcaPrinters ?
        this.props.dcaPrinters[index].tonerLevels ?
          this.props.dcaPrinters[index].tonerLevels.length >= 4 ?
            <td>
              <div className="bar_fill bar1" style={{'width': (this.props.dcaPrinters[index].tonerLevels[0].value-2)+'%'}}>
              </div>
              <div className="bar_border bar1">
              </div>
              <div className="bar_fill bar2" style={{'width': (this.props.dcaPrinters[index].tonerLevels[1].value-2)+'%'}}>
              </div>
              <div className="bar_border bar2">
              </div>
              <div className="bar_fill bar3" style={{'width': (this.props.dcaPrinters[index].tonerLevels[2].value-2)+'%'}}>
              </div>
              <div className="bar_border bar3">
              </div>
              <div className="bar_fill bar4" style={{'width': (this.props.dcaPrinters[index].tonerLevels[3].value-2)+'%'}}>
              </div>
              <div className="bar_border bar4">
              </div>
            </td>
          :
            <td>
              <div className="bar_fill bar1" style={{'width': (this.props.dcaPrinters[index].tonerLevels[0].value-2)+'%'}}>
              </div>
              <div className="bar_border bar1">
              </div>
            </td>
        :
          null
      :
        null
    )
  }

  renderAddress() {

    const userInfo = this.props.userInfo;
    var address = null;
    if (userInfo != null) {
      if (userInfo.addresses == null) {
        //this.props.history.push("/signup");
      } else {
        address = userInfo.addresses[userInfo.selectedAddress];
      }
    }
    return (
      <div id="dashboardAddress" className="dashboardView addressTableDiv centerStuffClass">
        <h2>Shipping to</h2>
        {this.props.userInfo ?
          address ?
            <table className="centerStuffClass">
              <tr>
                <p>{userInfo.firstName} {userInfo.lastName}</p>
                <p>{address.name}</p>
                <p>{address.address1}</p>
                <p>{address.address2}</p>
                <p>{address.city + ", " + address.state + " " + address.zipCode}</p>
              </tr>
            </table>
          :
            null
        :
          <div className="centerStuffClass">
            <ScaleLoader loading={true} color="#27AAEA" size="16px" margin="4px"/>
          </div>

        }

      </div>
    )
  }

  renderShipments() {
    return (
      <div id="dashboardShipments" className="dashboardView centerStuffClass">
        <h2>Recent Shipments</h2>
        <table className="centerStuffClass">
          <tbody>
            <tr><p>- coming soon! -</p></tr>
          </tbody>
        </table>
      </div>
    )

    const userInfo = this.props.userInfo;
    if (userInfo != null) {
      if (userInfo.shipments == null) {
        //this.props.history.push("/signup");
      }
    }
    return (
      <div id="dashboardShipments" className="dashboardView centerStuffClass">
        <h2>Recent Shipments</h2>
        {userInfo ?
          userInfo.shipments ?
            <div id="dashboardShipmentsTable">
              <table className="centerStuffClass">
                <tbody>
                  {userInfo.shipments.map((shipment) => {
                    return (
                      <tr>
                        <h3><b>{shipment.dateShipped}</b></h3>
                        <p>{shipment.deviceInfo.name}</p>
                        <p>{shipment.partInfo.type}</p>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          :
            <div id="" >
              <br/>
              <p style={{'fontSize': '0.85em'}}>No shipments yet</p>
            </div>
        :
          <div className="centerStuffClass">
            <br/><br/>
            <ScaleLoader loading={true} color="#27AAEA" size="16px" margin="4px"/>
          </div>
        }

      </div>
    )
  }


  renderMainArea() {
    return (
      <div>
        <div>
          {this.renderDevices()}
        </div>
        <div>
          {this.renderAddress()}
          {this.renderShipments()}
        </div>
      </div>
    )
  }


  render() {
    return (
      <div>
        {this.state.showingPopover2 ?
          this.renderPopover2()
        :
          this.state.showingPopover ?
            this.renderPopover()
          :
            null
        }

        <div>
          {this.renderAlerts()}
        </div>
        <div>
          {this.props.alertInfo ?
            this.props.alertInfo.code == '00001' ?
              <div>
              </div>
            :
              <div>
                <div>
                  {this.renderDevices()}
                </div>
                <div>
                  {this.renderAddress()}
                  {this.renderShipments()}
                </div>
              </div>
          :
            <div>
              <div>
                {this.renderDevices()}
              </div>
              <div>
                {this.renderAddress()}
                {this.renderShipments()}
              </div>
            </div>
          }
        </div>

      </div>


    );
  }


  getPriceForNumPrinters(num)
  {
    return (1.0725 * 10.0 * this.state.pfPrintersSelected.length).toFixed(2);
  }

}
