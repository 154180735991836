import React from 'react';

import * as firebase from 'firebase';



export function getCurrentUser() {
  return firebase.auth().currentUser;
}

export async function createNewUser(email, password) {
  var response;
  try { response = await firebase.auth().createUserWithEmailAndPassword(email, password); }
  catch (error) {
    return {'success': false, 'errorMsg': error.message};
  };
  //console.log(response);
  return {'success': true};
}

export async function loginUser(email, password) {
  var response;
  try { response = await firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password) }
  catch (error) {
    alert(error.message);
    return false;
  };
  // console.log(response);
  return true;
}

export async function logoutUser() {
  var response;
  try { response = await firebase.auth().signOut(); }
  catch (error) {
    alert(error.message);
    return false;
  };

  // console.log(response);
  return true;
}



export async function writeUserData(uid, email,firstName,lastName,phoneNum, pfGroupId,pfPrinters,selectedDca,pfDcas, selectedAddress,addresses, selectedBilling,billings, subscriptionId, payments,paymentMethod, shipments ) {
  let dbRef = 'Users/' + uid;
  let response;
  try {
    response = await firebase.database().ref(dbRef).update({
        // user info
        email,
        firstName,
        lastName,
        phoneNum,

        // print fleet info
        pfGroupId,
        pfPrinters,
        selectedDca,
        pfDcas,

        // shipping info
        selectedAddress,
        addresses,

        // billing info
        selectedBilling,
        billings,

        subscriptionId,

        // payment
        payments,
        paymentMethod,

        // shipment
        shipments,
    });
  }
  catch (error) {
      console.log('error ' , error)
  }
  // console.log('response: ' , response)
}

export async function updateDateRegistered(uid, dateRegistered) {
  let dbRef = 'Users/' + uid;
  let response;
  try {
    response = await firebase.database().ref(dbRef).update({
      dateRegistered,
    });
  }
  catch (error) {
      console.log('error ' , error)
  }
  // console.log('response: ' , response)
}

export async function updateAAA(uid, aaa) {
  let dbRef = 'Users/' + uid;
  let response;
  try {
    response = await firebase.database().ref(dbRef).update({
      aaa,
    });
  }
  catch (error) {
      console.log('error ' , error)
  }
  // console.log('response: ' , response)
}

export async function updatePfGroup(uid, pfGroupId, selectedDca, pfDcas) {
  let dbRef = 'Users/' + uid;
  let response;
  try {
    response = await firebase.database().ref(dbRef).update({
      pfGroupId,
      selectedDca,
      pfDcas,
    });
  }
  catch (error) {
      console.log('error ' , error)
  }
  // console.log('response: ' , response)
}

export async function updatePfPrinters(uid, pfPrinters) {
  let dbRef = 'Users/' + uid;
  let response;
  try {
    response = await firebase.database().ref(dbRef).update({
        pfPrinters
    });
  }
  catch (error) {
      console.log('error ' , error)
  }
  // console.log('response: ' , response)
}

export async function updatePayments(uid, payments) {
  let dbRef = 'Users/' + uid;
  let response;
  try {
    response = await firebase.database().ref(dbRef).update({
        payments
    });
  }
  catch (error) {
      console.log('error ' , error)
  }
  // console.log('response: ' , response)
}

export async function updateShipments(uid, shipments) {
  let dbRef = 'Users/' + uid;
  let response;
  try {
    response = await firebase.database().ref(dbRef).update({
        shipments
    });
  }
  catch (error) {
      console.log('error ' , error)
  }
  // console.log('response: ' , response)
}

export async function updateSubscriptionId(uid, subscriptionId) {
  let dbRef = 'Users/' + uid;
  let response;
  try {
    response = await firebase.database().ref(dbRef).update({
        subscriptionId
    });
  }
  catch (error) {
      console.log('error ' , error)
  }
  // console.log('response: ' , response)
}

export async function updateUserInfo(uid, email, firstName, lastName, phoneNum) {
  let dbRef = 'Users/' + uid;
  let response;
  try {
    response = await firebase.database().ref(dbRef).update({
        email,
        firstName,
        lastName,
        phoneNum,
    });
  }
  catch (error) {
      console.log('error ' , error)
  }
  // console.log('response: ' , response)
}

export async function updateAddresses(uid, addresses) {
  let dbRef = 'Users/' + uid;
  let response;
  try {
    response = await firebase.database().ref(dbRef).update({
        addresses
    });
  }
  catch (error) {
      console.log('error ' , error)
  }
  // console.log('response: ' , response)
}

export async function updateSelectedAddress(uid, selectedAddress) {
  let dbRef = 'Users/' + uid;
  let response;
  try {
    response = await firebase.database().ref(dbRef).update({
        selectedAddress
    });
  }
  catch (error) {
      console.log('error ' , error)
  }
  // console.log('response: ' , response)
}

export async function updateBillings(uid, billings) {
  let dbRef = 'Users/' + uid;
  let response;
  try {
    response = await firebase.database().ref(dbRef).update({
        billings
    });
  }
  catch (error) {
      console.log('error ' , error)
  }
  // console.log('response: ' , response)
}

export async function updateSelectedBilling(uid, selectedBilling) {
  let dbRef = 'Users/' + uid;
  let response;
  try {
    response = await firebase.database().ref(dbRef).update({
        selectedBilling
    });
  }
  catch (error) {
      console.log('error ' , error)
  }
  // console.log('response: ' , response)
}

export async function updateHasInstalledDca(uid, hasInstalledDca) {
  let dbRef = 'Users/' + uid;
  let response;
  try {
    response = await firebase.database().ref(dbRef).update({
        hasInstalledDca
    });
  }
  catch (error) {
      console.log('error ' , error)
  }
  // console.log('response: ' , response)
}







export async function readUserData() {
  let uid = firebase.auth().currentUser.uid;
  let dbRef = 'Users/' + uid;
  await firebase.database().ref(dbRef).once('value', function (snapshot) {
      // console.log(snapshot.val())
  });
}
