import React, { Component } from "react";
import { PageHeader, ListGroup, ListGroupItem, Navbar, Nav2, NavItem, Button, Glyphicon } from "react-bootstrap";
import { Container, Table, Col, Row, Form, FormGroup, Label, Input, FormText, FormFeedback, } from 'reactstrap';
import { LinkContainer } from "react-router-bootstrap";
import * as firebase from 'firebase';

import { Icon as BaseIcon } from "react-icons-kit";
import { print } from "react-icons-kit/fa/print";
import { flask } from "react-icons-kit/fa/flask";
import { truck } from "react-icons-kit/fa/truck";
import { envelopeOpen } from "react-icons-kit/fa/envelopeOpen";
import { plus } from "react-icons-kit/fa/plus";
import { trash } from "react-icons-kit/fa/trash";

import './Portal.css';
import './Signup.css';

const IconL = props => <BaseIcon size={56} icon={props.icon} />;
const IconM = props => <BaseIcon size={42} icon={props.icon} />;
const IconS = props => <BaseIcon size={34} icon={props.icon} />;
const IconXS = props => <BaseIcon size={29} icon={props.icon} />;

var ScaleLoader = require('halogen/ScaleLoader');

var firebaseDbHelper = require('../API_helpers/FirebaseDbHelper')

export class PortalShipments extends React.Component {

  constructor(props) {
    super(props);

    this.didTapAddNewShipment = this.didTapAddNewShipment.bind(this);
    //this.didTapOtherShipment = this.didTapOtherShipment.bind(this);

    this.state = {
      showingPopover: false,
      popoverTitle: "",
      popoverDetails: "",
      selectedIndex: 0,

      showAddShipment: false,
      addingShipment: false,

      fieldAddressName: "",
      fieldAddress1: "",
      fieldAddress2: "",
      fieldCity: "",
      fieldState: "",
      fieldZipCode: "",
      fieldCountry: "USA",

      validate: {
        addressNameState: '',
        address1State: '',
        cityState: '',
        addrStateState: '',
        zipCodeState: '',
        countryState: '',
      },
    };
  }

  renderPopover() {
    return (
      <div className="popoverDiv centerStuffClass">
        <div className="popoverDivInner centerStuffClass">
          <div style={{'margin': '16px 12px'}}>
            <h2>{this.state.popoverTitle}</h2>
            <p>{this.state.popoverDetails}</p>

            <div style={{'marginTop': '35px'}}>
              <Button style={{'padding':"12px 38px"}} bsStyle="primary" bsSize="large" onClick={this.didTapAlertOk.bind(this)}>OK</Button>
              &emsp;
              <Button style={{'padding':"12px 38px"}} bsStyle="primary" bsSize="large" onClick={this.didTapAlertCancel.bind(this)}>Cancel</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  async didTapAlertOk() {
    if (this.state.addingShipment) {
      return;
    }
    this.setState({ addingShipment: true });
    var user = firebaseDbHelper.getCurrentUser();


    if (this.state.popoverTitle.includes('REMOVE')) {
      var addresses = this.props.userInfo.addresses;
      addresses.splice(this.state.selectedIndex, 1);
      await firebaseDbHelper.updateAddresses(user['uid'], addresses);
    }
    else {
      var userInfo = this.props.userInfo;

      await firebaseDbHelper.updateSelectedAddress(user['uid'], this.state.selectedIndex);
      // send email
      var type = "SHIPPING";
      var email = userInfo.email;
      var firstName = userInfo.firstName;
      var lastName = userInfo.lastName;
      var phoneNum = userInfo.phoneNum;
      var uuid = user['uid'];

      var oldIndex = userInfo.selectedAddress;
      var oldAddress = userInfo.addresses[oldIndex];
      var oldValue = userInfo.firstName + " " + userInfo.lastName + "<br>" + oldAddress.name + "<br>" + oldAddress.address1 + ", " + oldAddress.address2 + "<br>" + oldAddress.city + ", " + oldAddress.state + " " + oldAddress.zipCode;

      var newIndex = this.state.selectedIndex;
      var newAddress = userInfo.addresses[newIndex];
      var newValue = userInfo.firstName + " " + userInfo.lastName + "<br/>"+ newAddress.name + "<br>" + newAddress.address1 + ", " + newAddress.address2 + "<br>" + newAddress.city + ", " + newAddress.state + " " + newAddress.zipCode;
      window.emailjs.send(
        'mailgun',
        process.env.REACT_APP_EMAILJS_TEMPLATEID_CHANGED,
        {type, email, firstName, lastName, phoneNum, uuid, oldIndex, oldValue, newIndex, newValue }
      )
    }
    this.setState({ showingPopover: false, addingShipment: false });
    this.props.fetchUserInfo(true, true);
  }
  didTapAlertCancel() {
    this.setState({ showingPopover: false });
  }


  renderAddress() {
    var userInfo = this.props.userInfo;
    var address = null;
    var addresses = null;
    if (userInfo != null) {
      if (userInfo.addresses) {
          address = userInfo.addresses[userInfo.selectedAddress];
          addresses = userInfo.addresses;
      }
    }
    return (
      <div>
        <div id="shipmentsAddress" className="dashboardView addressTableDiv">
          <h2 style={{}}>Shipping to</h2>
          {this.props.userInfo ?
            address ?
              <table className="centerStuffClass">
                <tr>
                  <p>{userInfo.firstName} {userInfo.lastName}</p>
                  <p>{address.name}</p>
                  <p>{address.address1}</p>
                  <p>{address.address2}</p>
                  <p>{address.city + ", " + address.state + " " + address.zipCode}</p>
                </tr>
              </table>
            :
            null
          :
            <div className="centerStuffClass">
              <ScaleLoader loading={true} color="#27AAEA" size="16px" margin="4px"/>
            </div>
          }
        </div>
        <div id="shipmentsAddress2" className="dashboardView addressTableDiv">
          <h2>Other Addresses  <Button height='22px' style={{'marginTop': '-3px', 'marginLeft': '3%', 'padding':"3px 10px"}} bsStyle="primary" bsSize="large" onClick={this.didTapAddNewShipment}>{this.state.showAddShipment ? "CANCEL" : <IconXS icon={plus}/>}</Button></h2>
          {this.props.userInfo ?
            addresses ?
              addresses.length > 1 ?
              <div id="otherAddressesTable">
                <table className="centerStuffClass">
                  <tbody>
                    {addresses.map((address2, i) => {
                      if (this.props.userInfo.selectedAddress != i) {
                        return (
                          <tr>
                            <td>
                              <p>{userInfo.firstName} {userInfo.lastName}</p>
                              <p>{address2.name}</p>
                              <p>{address2.address1}</p>
                              <p>{address2.address2}</p>
                              <p>{address2.city + ", " + address2.state + " " + address2.zipCode}</p>
                            </td>
                            <td>
                              <Button height='22px' style={{'marginTop': '7px', 'marginLeft': '3%', 'padding':"3px 10px"}} bsStyle="primary" bsSize="large" onClick={this.didTapOtherShipment.bind(this, i, false)}><IconXS icon={truck}/></Button>
                              <Button height='22px' style={{'marginTop': '7px', 'marginLeft': '3%', 'padding':"3px 10px"}} bsStyle="primary" bsSize="large" onClick={this.didTapOtherShipment.bind(this, i, true)}><IconXS icon={trash}/></Button>
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              </div>
              :
              <div className="centerStuffClass">
              </div>
            :
            null
          :
            <div className="centerStuffClass">
              <ScaleLoader loading={true} color="#27AAEA" size="16px" margin="4px"/>
            </div>
          }
        </div>
      </div>
    )
  }

  renderShipments() {
    return (
      <div className="centerStuffClass">
        <h1 style={{'margin-top': '-65px', 'margin-bottom': '4px', 'line-height': '1.0em', 'textAlign': 'center'}}>Shipments</h1>
        <div id="orderInfoDiv" >
          <br/>
          <p>- coming soon! -</p>
        </div>
      </div>
    )

    return (
      <div className="centerStuffClass">
        <h1 style={{'margin-top': '-65px', 'margin-bottom': '4px', 'line-height': '1.0em', 'textAlign': 'center'}}>Shipments</h1>
        {this.props.userInfo ?
          this.props.userInfo.shipments ?
            <div id="shipmentsTable">
              <div style={{'border-top': '2px black solid'}} >
                  <table className="shipmentsTableDiv centerStuffClass">
                    <tbody>
                      {this.props.userInfo.shipments.map((shipment) => {
                        return (
                          <tr>
                            <td className="centerStuffClass">
                              <p><IconXS icon={print}/>&nbsp;&nbsp;{shipment.deviceInfo.name}</p>
                              <p><IconXS icon={flask}/>&nbsp;&nbsp;{shipment.partInfo.serial} {shipment.partInfo.type}</p>
                            </td>
                            <td className="centerStuffClass">
                              <p><IconXS icon={truck}/>&nbsp;&nbsp;{shipment.dateShipped}</p>
                              {/* <p><IconXS icon={envelopeOpen}/>&nbsp;&nbsp;{shipment.dateArrived}</p> */}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
              </div>
            </div>
          :
            <div id="orderInfoDiv" >
              <br/>
              <p>No shipments yet</p>
            </div>
        :
          <div id="orderInfoDiv"  className="centerStuffClass">
            <br/><br/>
            <ScaleLoader loading={true} color="#27AAEA" size="16px" margin="4px"/>
          </div>
        }

        <br/><br/><br/>

      </div>
    );
  }


  renderAddNewAddress() {
    return (
      <div id="shipmentsAddDiv">
        <br/>
        <Form className="form" onSubmit={ (e) => null }>
            <Col>
              <FormGroup>
                <Label>Company Name (optional)</Label>
                <Input
                  type = "text"
                  name = "fieldAddressName"
                  id = "fieldAddressName"
                  value={ this.state.fieldAddressName }
                  onChange={ (e) => { this.handleChange(e) }}
                  onBlur={ (e) => { this.validateLabel(e) }}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Address 1</Label>
                <Input
                  type = "text"
                  name = "fieldAddress1"
                  id = "fieldAddress1"
                  value={ this.state.fieldAddress1 }
                  valid={ this.state.validate.address1State === 'has-success' }
                  invalid={ this.state.validate.address1State === 'has-danger' }
                  onChange={ (e) => { this.handleChange(e) }}
                  onBlur={ (e) => { this.validateLabel(e) }}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Address 2</Label>
                <Input
                  type = "text"
                  name = "fieldAddress2"
                  id = "fieldAddress2"
                  value={ this.state.fieldAddress2 }
                  onChange={ (e) => { this.handleChange(e) } }
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>City</Label>
                <Input
                  type = "text"
                  name = "fieldCity"
                  id = "fieldCity"
                  value={ this.state.fieldCity }
                  valid={ this.state.validate.cityState === 'has-success' }
                  invalid={ this.state.validate.cityState === 'has-danger' }
                  onChange={ (e) => { this.handleChange(e) }}
                  onBlur={ (e) => { this.validateLabel(e) }}
                />
              </FormGroup>
            </Col>
            <Col>
              <td>
                <FormGroup style={{'width': '94%', 'min-width': '110px', 'margin-right': '6%'}}>
                  <Label>State</Label>
                  <Input
                    type = "select"
                    name = "fieldState"
                    id = "fieldState"
                    value={ this.state.fieldState }
                    invalid={ this.state.validate.addrStateState === 'has-danger' }
                    onChange={ (e) => { this.handleChange(e) }}
                    onBlur={ (e) => { this.validateLabel(e) }}
                  >
                    <option value="">--</option>
                    <option value="AK">AK</option>
                    <option value="AL">AL</option>
                    <option value="AR">AR</option>
                    <option value="AZ">AZ</option>
                    <option value="CA">CA</option>
                    <option value="CO">CO</option>
                    <option value="CT">CT</option>
                    <option value="DE">DE</option>
                    <option value="FL">FL</option>
                    <option value="GA">GA</option>
                    <option value="HI">JI</option>
                    <option value="IA">IA</option>
                    <option value="ID">ID</option>
                    <option value="IL">IL</option>
                    <option value="IN">IN</option>
                    <option value="KS">KS</option>
                    <option value="KY">KY</option>
                    <option value="LA">LA</option>
                    <option value="MA">MA</option>
                    <option value="MD">MD</option>
                    <option value="ME">ME</option>
                    <option value="MI">MI</option>
                    <option value="MN">MN</option>
                    <option value="MO">MO</option>
                    <option value="MS">MS</option>
                    <option value="MT">MT</option>
                    <option value="NC">NC</option>
                    <option value="ND">ND</option>
                    <option value="NE">NE</option>
                    <option value="NH">NH</option>
                    <option value="NJ">NJ</option>
                    <option value="NM">NM</option>
                    <option value="NV">NV</option>
                    <option value="NY">NY</option>
                    <option value="OH">OH</option>
                    <option value="OK">OK</option>
                    <option value="OR">OR</option>
                    <option value="PA">PA</option>
                    <option value="RI">RI</option>
                    <option value="SC">SC</option>
                    <option value="SD">SD</option>
                    <option value="TN">TN</option>
                    <option value="TX">TX</option>
                    <option value="UT">UT</option>
                    <option value="VA">VA</option>
                    <option value="VT">VT</option>
                    <option value="WA">WA</option>
                    <option value="WI">WI</option>
                    <option value="WV">WV</option>
                    <option value="WY">WY</option>
                  </Input>
                </FormGroup>
              </td>
              <td>
                <FormGroup style={{'width': '94%', 'margin-left': '6%', 'margin-right': '3%'}}>
                  <Label>Zip Code</Label>
                  <Input
                    type="text"
                    name="fieldZipCode"
                    id="fieldZipCode"
                    value={ this.state.fieldZipCode }
                    valid={ this.state.validate.zipCodeState === 'has-success' }
                    invalid={ this.state.validate.zipCodeState === 'has-danger' }
                    onChange={ (e) => { this.handleChange(e) }}
                    onBlur={ (e) => { this.validateLabel(e) }}
                />
                </FormGroup>
              </td>
              <td>
                <FormGroup style={{'width': '94%', 'margin-left': '6%'}}>
                  <Label>Country</Label>
                  <Input
                    type="text"
                    name="fieldCountry"
                    id="fieldCountry"
                    value={ this.state.fieldCountry }
                    valid={ this.state.validate.countryState === 'has-success' }
                    invalid={ this.state.validate.countryState === 'has-danger' }
                    onBlur={ (e) => { this.validateLabel(e) }}
                />
                </FormGroup>
              </td>
            </Col>

            <br/>
            <div className="centerStuffClass">
              <Button style={{'padding':"12px 28px"}} bsStyle="primary" bsSize="large" onClick={this.didTapSubmitNewShipment.bind(this)}><b>SUBMIT</b></Button>
            </div>

          </Form>
      </div>
    )
  }

  didTapOtherShipment(index, toDelete) {
    const title = toDelete ? 'REMOVE ADDRESS?' : 'MAKE ACTIVE ADDRESS?';
    const details = toDelete ? 'Confirm you would like to remove this shipping address' : 'Confirm you would like to make this shipping address your active address';
    this.setState({ showingPopover: true, popoverTitle: title, popoverDetails: details, selectedIndex: index })
  }

  didTapAddNewShipment() {
    this.setState({ showAddShipment: !this.state.showAddShipment });
  }

  async didTapSubmitNewShipment() {
    if (!this.validatePage()) {
      return;
    }
    if (this.state.addingShipment) {
      return;
    }
    this.setState({ addingShipment: true });
    var user = firebaseDbHelper.getCurrentUser();

    var addresses = this.props.userInfo.addresses;
    let address = {
      name: this.state.fieldAddressName,
      address1: this.state.fieldAddress1,
      address2: this.state.fieldAddress2,
      city: this.state.fieldCity,
      state: this.state.fieldState,
      zipCode: this.state.fieldZipCode,
      country: this.state.fieldCountry
    }
    addresses.push(address);

    await firebaseDbHelper.updateAddresses(user['uid'], addresses);

    this.setState({ addingShipment: false, showAddShipment: !this.state.showAddShipment });
    this.setState({ fieldAddressName: "", fieldAddress1: "", fieldAddress2: "",
      fieldCity: "", fieldState: "", fieldZipCode: "", fieldCountry: "USA", });

    const { validate } = this.state
    //validate.addressNameState = '';
    validate.address1State = '';
    validate.cityState = '';
    validate.zipCodeState = '';
    validate.address1State = '';
    validate.countryState = '';

    this.props.fetchUserInfo(true, true);
  }

  render() {
    return (
      <div>
        {this.state.showingPopover ?
          this.renderPopover()
        :
          null
        }
        <div>
          {this.renderAddress()}
        </div>
        <br/><br/><br/>
        <div>
          {this.state.showAddShipment ?
            this.renderAddNewAddress()
          :
            this.renderShipments()
          }
        </div>

      </div>

    );
  }



  handleChange = async event => {
    let dLength = Math.abs(this.state[event.target.id].length - event.target.value.length);

    this.setState({ [event.target.id]: event.target.value });

    if (dLength > 1) {
      this.validateLabel(event);
    }
  }

  validateLabel(e) {
    let _id = e.target.id;
    let _value = e.target.value;
    this.validateValue(_id, _value);
  }

  validateValue(_id, _value) {
    const { validate } = this.state
      // if (_id == "fieldAddressName") {
      //   validate.addressNameState = (_value.length > 0) ? 'has-success' : 'has-danger';
      // }
      if (_id == "fieldAddress1") {
        validate.address1State = (_value.length > 0) ? 'has-success' : 'has-danger';
      }
      else if (_id == "fieldCity") {
        validate.cityState = (_value.length > 0) ? 'has-success' : 'has-danger';
      }
      else if (_id == "fieldState") {
        validate.addrStateState = (_value.length > 0) ? 'has-success' : 'has-danger';
      }
      else if (_id == "fieldZipCode") {
        validate.zipCodeState = (_value.length > 0) ? 'has-success' : 'has-danger';
      }
      else if (_id == "fieldCountry") {
        validate.countryState = (_value.length > 0) ? 'has-success' : 'has-danger';
      }

      this.setState({ validate })
  }

  validatePage() {
    //this.validateValue("fieldAddressName", this.state.fieldAddressName);
    this.validateValue("fieldAddress1", this.state.fieldAddress1);
    this.validateValue("fieldCity", this.state.fieldCity);
    this.validateValue("fieldState", this.state.fieldState);
    this.validateValue("fieldZipCode", this.state.fieldZipCode);
    this.validateValue("fieldCountry", this.state.fieldCountry);

    const { validate } = this.state
    let itsGood = //(validate.addressNameState === 'has-success')
                  (validate.address1State === 'has-success')
                  && (validate.cityState === 'has-success')
                  && (validate.addrStateState  === 'has-success')
                  && (validate.zipCodeState  === 'has-success')
                  && (validate.countryState  === 'has-success');

    return itsGood;
  }

}
