import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem, Image, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import RegNavBar from "./RegNavBar";

import "./App.css";
import Routes from "./Routes";
import * as firebase from 'firebase';

//import PrintFleetSingleton from './containers/PrintFleetSingleton';

import { logo } from "./imgs/logo.jpg";





class App extends Component
{
  constructor(props) {
    super(props);

    this.state = {
      authenticatedUser: null,
      isAuthenticated: false,
      isAuthenticating: true,
      printFleetSingleton: null,
    };


    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

    firebase.auth().onAuthStateChanged(function(user) {
      this.setState({ authenticatedUser: user,
                      isAuthenticated: (user != null),
                      isAuthenticating: false
                    });
      this.setState({ state: this.state });
    }.bind(this));

  }

  async componentDidMount() {
    var user = firebase.auth().currentUser;

    if (user != null) {
      console.log("  Provider-specific UID: " + user['uid']);
      console.log("  Email: " + user['email']);
    }
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  handleLogout = async event => {
    try {
      await firebase.auth().signOut();
      this.userHasAuthenticated(false);
      this.props.history.push("/");
    } catch (e){
      alert(e);
    }
  }



  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      isAuthenticating: this.state.isAuthenticating
    };

    return (
      <div>
        <RegNavBar />

        {!this.state.isAuthenticating ?
          <div className="App container">
            {/* }{this.state.isAuthenticated && (
              <div>
                <Button onClick={this.handleLogout} style={{float:"right"}}>Logout</Button>
                <br/>
              </div>
            )} */}
            <Routes childProps={childProps} />
          </div>
        : null }


      </div>

    );

}




};

export default withRouter(App);
