import React from 'react';
import ReactDOM from "react-dom"

const axios = require('axios');
const cors = require('cors')({origin: true});

const ROOT_GROUP_ID = process.env.REACT_APP_GROUP_ID;

//const ROOT_GROUP_ID = '0f040a30-52f9-4229-b843-ffafb0b9b42a';
export async function getPfGroups()
{
  var response;
  try {
    response = await axios.get('https://arcane-bastion-40707.herokuapp.com/https://axess.axessmps.com:443/restapi/3.11.0/groups', {
    	headers: {
        'Authorization' : process.env.REACT_APP_PF_BASIC_AUTH,
        'x-api-key' : process.env.REACT_APP_PF_API_KEY,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Authorization header',
    	},
    });
  }
  catch (error) {
    console.log(error);
    return "";
  }

  const data = response.data;
  // console.log(data);
  // console.log(data[0]['children']['length']);
  return "ayeeee";

}


export async function createPfGroup(groupName)
{
  var response;
  try {
    response = await axios.put('https://arcane-bastion-40707.herokuapp.com/https://axess.axessmps.com:443/restapi/3.11.0/groups',
        {
          groupTypeId: "62eed4c4-0233-4150-95f7-776a2fbdd94c",
          name: groupName,
          parentId: ROOT_GROUP_ID,
        },
        {
        headers: {
          'Authorization' : process.env.REACT_APP_PF_BASIC_AUTH,
          'x-api-key' : process.env.REACT_APP_PF_API_KEY,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Authorization header',
      	},
    });
  }
  catch (error) {
    console.log("----- CREATE PRINTFLEET GROUP ~ERROR~ -----");
    console.log(error);
    alert(error);
    // console.log("-----------------------------------------");
    return "";
  }

  const data = response.data;
  let groupId = data['id'];

  // console.log("----- CREATE PRINTFLEET GROUP RESPONSE -----");
  // console.log(groupId);
  // console.log("-----------------------------------------");
  return groupId;
}


export async function createPfDCA(groupName, groupId)
{
  var response;
  try {
    response = await axios.put('https://arcane-bastion-40707.herokuapp.com/https://axess.axessmps.com:443/restapi/3.11.0/dcas',
        {
          name: "DCA Pulse - " + groupName,
          groupId: groupId,
          dcaType: "Pulse"
        },
        {
        headers: {
          'Authorization' : process.env.REACT_APP_PF_BASIC_AUTH,
          'x-api-key' : process.env.REACT_APP_PF_API_KEY,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Authorization header',
      	},
    });
  }
  catch (error) {
    // console.log("----- CREATE PRINTFLEET DCA ~ERROR~ -----");
    // console.log(error);
    // console.log("-----------------------------------------");
    return null;
  }

  const data = response.data;
  let dcaId = data['id']
  let activationPin = data['activationPin'];
  let dcaURLpc = data['dcaDownloadUrls'][3]['sourceUrl'];
  let dcaURLmac = data['dcaDownloadUrls'][2]['sourceUrl'];
  dcaURLpc = dcaURLpc.replace("updates", "install")
  dcaURLpc = dcaURLpc.replace(".exe", " [" + activationPin + "].exe")
  dcaURLmac = dcaURLmac.replace("updates", "install")
  dcaURLmac = dcaURLmac.replace(".pkg", " [" + activationPin + "].pkg")

  // console.log("----- CREATE PRINTFLEET DCA RESPONSE -----");
  // console.log(dcaId);
  // console.log(dcaURLpc);
  // console.log(dcaURLmac);
  // console.log("------------------------------------------");
  return {dcaId: dcaId, dcaURLpc: dcaURLpc, dcaURLmac: dcaURLmac};
}


export async function getPfDcaIsActivated(groupId)
{
  var response;
  try {
    response = await axios.get('https://arcane-bastion-40707.herokuapp.com/https://axess.axessmps.com:443/restapi/3.11.0/dcas?groupId=' + groupId, {
    	headers: {
        'Authorization' : process.env.REACT_APP_PF_BASIC_AUTH,
        'x-api-key' : process.env.REACT_APP_PF_API_KEY,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Authorization header',
    	},
    });
  }
  catch (error) {
    console.log(error);
    return "";
  }

  const data = response.data;
  let isActivated = data[0]['isActivated'];
  if (isActivated == null) { isActivated = false; }

  console.log("DCA IS ACTIVATED : " + isActivated);

  return isActivated;
}

export async function getPfDcaIsConnected(dcaId)
{
  var response;
  try {
    response = await axios.get('https://arcane-bastion-40707.herokuapp.com/https://axess.axessmps.com:443/restapi/3.11.0/dcas/' + dcaId + '/status', {
    	headers: {
        'Authorization' : process.env.REACT_APP_PF_BASIC_AUTH,
        'x-api-key' : process.env.REACT_APP_PF_API_KEY,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Authorization header',
    	},
    });
  }
  catch (error) {
    console.log(error);
    return "";
  }

  const data = response.data;
  let isConnected = data['isConnected'];

  console.log("DCA IS CONNECTED : " + isConnected);

  return isConnected;
}

export async function getPfDcaDevices(groupId)
{
  var response;
  try {
    response = await axios.get('https://arcane-bastion-40707.herokuapp.com/https://axess.axessmps.com:443/restapi/3.11.0/devices?groupId=' + groupId, {
    	headers: {
        'Authorization' : process.env.REACT_APP_PF_BASIC_AUTH,
        'x-api-key' : process.env.REACT_APP_PF_API_KEY,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Authorization header',
    	},
    });
  }
  catch (error) {
    console.log(error);
    return "";
  }

  const data = response.data;
  let printers = data;
  console.log(printers);

  return printers;
}

export async function getPfDcaDeviceSupplies(deviceId)
{
  var response;
  try {
    response = await axios.get('https://arcane-bastion-40707.herokuapp.com/https://axess.axessmps.com:443/restapi/3.11.0/devices/' + deviceId + '/supplies', {
    	headers: {
        'Authorization' : process.env.REACT_APP_PF_BASIC_AUTH,
        'x-api-key' : process.env.REACT_APP_PF_API_KEY,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Authorization header',
    	},
    });
  }
  catch (error) {
    console.log(error);
    return "";
  }

  const data = response.data;
  //console.log(data);

  var tonerLevels = [];
  for (var i = 0; i < data.length; i++) {
    if (data[i].color == null) { continue; }
    if (data[i].type == null || data[i].type != 'Toner') { continue; }

    if (data[i].color.includes("Black")) {
      var value = data[i].level.lowPercent;
      if (value == "Unknown" || value == null) {
        value = "? ";
      } else {
        value = 0.5*(data[i].level.lowPercent + data[i].level.highPercent).toFixed(0);
      }
      var oemPartNumber = data[i].oemPartNumber;

      var level = {'value': value, 'oemPartNumber': oemPartNumber}
      tonerLevels[0] = level;
    }
    else if (data[i].color.includes("Cyan")) {
      var value = data[i].level.lowPercent;
      if (value == "Unknown" || value == null) {
        value = "? ";
      } else {
        value = 0.5*(data[i].level.lowPercent + data[i].level.highPercent).toFixed(0);
      }
      var oemPartNumber = data[i].oemPartNumber;

      var level = {'value': value, 'oemPartNumber': oemPartNumber}
      tonerLevels[1] = level;
    }
    else if (data[i].color.includes("Yellow")) {
      var value = data[i].level.lowPercent;
      if (value == "Unknown" || value == null) {
        value = "? ";
      } else {
        value = 0.5*(data[i].level.lowPercent + data[i].level.highPercent).toFixed(0);
      }
      var oemPartNumber = data[i].oemPartNumber;

      var level = {'value': value, 'oemPartNumber': oemPartNumber}
      tonerLevels[2] = level;
    }
    else if (data[i].color.includes("Magenta")) {
      var value = data[i].level.lowPercent;
      if (value == "Unknown" || value == null) {
        value = "? ";
      } else {
        value = 0.5*(data[i].level.lowPercent + data[i].level.highPercent).toFixed(0);
      }
      var oemPartNumber = data[i].oemPartNumber;

      var level = {'value': value, 'oemPartNumber': oemPartNumber}
      tonerLevels[3] = level;
    }
  }

  if (tonerLevels[0] == null) {
    tonerLevels[0] = {'value': "? ", 'oemPartNumber': "generic"};
  }

  //console.log(tonerLevels);
  return tonerLevels;
}

export async function getPfLocalHost() {
  var response;
  try {
    response = await axios.get('http://192.168.0.3:31816/', {
    	headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, Accept, X-Auth-Token',
        'Access-Control-Allow-Methods': 'GET'
    	},
    });
  }
  catch (error) {
    console.log(error);
    return "";
  }

  const data = response.data;
  let printers = data;
  // console.log(response);

  return printers;
}
