import React, { Component } from "react";
import { PageHeader, ListGroup, ListGroupItem, Navbar, Nav2, NavItem, Button, Glyphicon } from "react-bootstrap";
import { Container, Table, Col, Row, Form, FormGroup, Label, Input, FormText, FormFeedback, } from 'reactstrap';
import { LinkContainer } from "react-router-bootstrap";

import { Icon as BaseIcon } from "react-icons-kit";
import { print } from "react-icons-kit/fa/print";
import { plus } from "react-icons-kit/fa/plus";
import { dollar } from "react-icons-kit/fa/dollar";
import { calendar } from "react-icons-kit/fa/calendar";
import { creditCardAlt } from "react-icons-kit/fa/creditCardAlt";
import { trash } from "react-icons-kit/fa/trash";
import { shoppingCart } from "react-icons-kit/fa/shoppingCart";

import './Portal.css';
import './Signup.css';

const IconL = props => <BaseIcon size={56} icon={props.icon} />;
const IconM = props => <BaseIcon size={42} icon={props.icon} />;
const IconS = props => <BaseIcon size={34} icon={props.icon} />;
const IconXS = props => <BaseIcon size={29} icon={props.icon} />;
const IconXXS = props => <BaseIcon size={25} icon={props.icon} />;

var authorizeNetHelper = require('../API_helpers/AuthorizeNetHelper')

var ScaleLoader = require('halogen/ScaleLoader');

var firebaseDbHelper = require('../API_helpers/FirebaseDbHelper')

export class PortalBillings extends React.Component {

  constructor(props) {
    super(props);

    this.didTapAddNewBilling = this.didTapAddNewBilling.bind(this);

    this.state = {
      showingPopover: false,
      popoverTitle: "",
      popoverDetails: "",
      selectedIndex: 0,

      showAddBilling: false,
      verifyingCard: false,

      cardNumber: "",
      cardName: "",
      cardMonth: "",
      cardYear: "",
      cardCvc: "",
      billingAddrCheck: 1,
      verifyingCard: false,
      authNetVerifiedCard: false,
      authNetProfileId: "",
      authNetPaymentId: "",

      b_address1: "",
      b_city: "",
      b_addrState: "",
      b_zipCode: "",
      b_country: "USA",

      validate: {
        cardNumberState: '',
        cardNameState: '',
        cardMonthState: '',
        cardYearState: '',
        cardCvcState: '',

        b_address1State: '',
        b_cityState: '',
        b_addrStateState: '',
        b_zipCodeState: '',
        b_countryState: '',
      },
    };
  }


  renderPopover() {
    return (
      <div className="popoverDiv centerStuffClass">
        <div className="popoverDivInner centerStuffClass">
          <div style={{'margin': '16px 12px'}}>
            <h2>{this.state.popoverTitle}</h2>
            <p>{this.state.popoverDetails}</p>

            <div style={{'marginTop': '35px'}}>
              <Button style={{'padding':"12px 38px"}} bsStyle="primary" bsSize="large" onClick={this.didTapAlertOk.bind(this)}>OK</Button>
              &emsp;
              <Button style={{'padding':"12px 38px"}} bsStyle="primary" bsSize="large" onClick={this.didTapAlertCancel.bind(this)}>Cancel</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  async didTapAlertOk()
  {
    var user = firebaseDbHelper.getCurrentUser();

    if (this.state.popoverTitle.includes('REMOVE')) {
      var billings = this.props.userInfo.billings;
      billings.splice(this.state.selectedIndex, 1);
      await firebaseDbHelper.updateBillings(user['uid'], billings);
    }
    else {
      await firebaseDbHelper.updateSelectedBilling(user['uid'], this.state.selectedIndex);
      // send email
      var type = "BILLING";
      var email = this.props.userInfo.email;
      var firstName = this.props.firstName;
      var lastName = this.props.lastName;
      var phoneNum = this.props.userInfo.phoneNum;
      var uuid = user['uid'];

      var oldIndex = this.props.userInfo.selectedBilling;
      var oldBilling = this.props.userInfo.billings[oldIndex];
      var oldValue = oldBilling.name + "<br>" + oldBilling.lastFour + "<br>" + oldBilling.authNetProfileId + "<br>" + oldBilling.authNetPaymentId;

      var newIndex = this.state.selectedIndex;
      var newBilling = this.props.userInfo.billings[newIndex];
      var newValue = newBilling.name + "<br>" + newBilling.lastFour + "<br>" + newBilling.authNetProfileId + "<br>" + newBilling.authNetPaymentId;

      window.emailjs.send(
        'mailgun',
        process.env.REACT_APP_EMAILJS_TEMPLATEID_CHANGED,
        {type, email, firstName, lastName, phoneNum, uuid, oldIndex, oldValue, newIndex, newValue }
      )
    }
    this.setState({ showingPopover: false });
    this.props.fetchUserInfo(true, true);
  }
  didTapAlertCancel() {
    this.setState({ showingPopover: false });
  }


  renderBilling() {
    const userInfo = this.props.userInfo;
    var billing = null;
    if (userInfo != null) {
      if (userInfo.billings) {
        billing = userInfo.billings[userInfo.selectedBilling];
      }
    }
    return (
      <div>
        <div id="shipmentsAddress" className="dashboardView addressTableDiv">
          <h2 style={{}}>Billing to</h2>
          {this.props.userInfo ?
            billing ?
              <table className="centerStuffClass">
                <tr>
                  <p>{billing.name}</p>
                  <p style={{'marginTop': '10px'}}><IconXXS icon={creditCardAlt}/>&nbsp;ending in {billing.lastFour}</p>
                </tr>
              </table>
            :
              null
          :
            <div className="centerStuffClass">
              <ScaleLoader loading={true} color="#27AAEA" size="16px" margin="4px"/>
            </div>
          }
        </div>
        <div id="shipmentsAddress2" className="dashboardView addressTableDiv">
          <h2>Other Accounts<Button height='22px' style={{'marginTop': '-3px', 'marginLeft': '3%', 'padding':"3px 10px"}} bsStyle="primary" bsSize="large" onClick={this.didTapAddNewBilling}>{this.state.showAddBilling ? "CANCEL" : <IconXS icon={plus}/>}</Button></h2>
          {this.props.userInfo ?
            billing ?
              <div id="otherAddressesTable">
                <table className="centerStuffClass">
                  <tbody>
                    {this.props.userInfo.billings.map((billing2, i) => {
                      if (this.props.userInfo.selectedBilling != i) {
                        return (
                          <tr>
                            <td>
                              <p>{billing2.name}</p>
                              <p style={{'marginTop': '10px'}}><IconXXS icon={creditCardAlt}/>&nbsp;ending in {billing2.lastFour}</p>
                            </td>
                            <td>
                              <Button height='22px' style={{'marginTop': '7px', 'marginLeft': '3%', 'padding':"3px 10px"}} bsStyle="primary" bsSize="large" onClick={this.didTapOtherBilling.bind(this, i, false)}><IconXS icon={shoppingCart}/></Button>
                              <Button height='22px' style={{'marginTop': '7px', 'marginLeft': '3%', 'padding':"3px 10px"}} bsStyle="primary" bsSize="large" onClick={this.didTapOtherBilling.bind(this, i, true)}><IconXS icon={trash}/></Button>
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              </div>
              :
              <div className="centerStuffClass">
              </div>
          :
            <div className="centerStuffClass">
              <ScaleLoader loading={true} color="#27AAEA" size="16px" margin="4px"/>
            </div>
          }
        </div>
      </div>
    )
  }

  renderPayments() {
    return (
      <div className="centerStuffClass">
        <h1 style={{'margin-top': '-45px', 'margin-bottom': '4px', 'line-height': '1.0em', 'textAlign': 'center'}}>Payments</h1>
        <div id="orderInfoDiv" >
          <br/>
          <p>- coming soon! -</p>
        </div>
      </div>
    )

    return (
      <div id="shipmentsDiv" className="centerStuffClass">
        <h1 style={{'margin-top': '-45px', 'margin-bottom': '4px', 'line-height': '1.0em', 'textAlign': 'center'}}>Payments</h1>
        {this.props.userInfo ?
          this.props.userInfo.payments ?
            <div id="shipmentsTable">
              <div style={{'border-top': '2px black solid'}} >
                  <table className="shipmentsTableDiv centerStuffClass">
                    <tbody>
                      {this.props.userInfo.payments.map((payment) => {
                        return (
                          <tr>
                            <td className="centerStuffClass" style={{'text-align': 'center'}}>
                              <p><IconXS icon={calendar}/>&nbsp;&nbsp;{payment.date}</p>
                            </td>
                            <td className="centerStuffClass" style={{'text-align': 'left'}}>
                              <p><IconXS icon={print}/>&nbsp;&nbsp;{payment.devices.length}</p>
                            </td>
                            <td className="centerStuffClass" style={{'text-align': 'left'}}>
                              <p><IconXS icon={dollar}/>&nbsp;{Number.parseFloat(payment.price).toFixed(2)}</p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
              </div>
            </div>
          :
            <div id="orderInfoDiv" >
              <br/>
              <p>No payments yet</p>
            </div>
        :
          <div id="orderInfoDiv" className="centerStuffClass">
            <br/><br/>
            <ScaleLoader loading={true} color="#27AAEA" size="16px" margin="4px"/>
          </div>
        }

        <br/><br/><br/>

      </div>
    );
  }


  renderAddNewBilling() {
    return  (
      <div id="shipmentsAddDiv">
        <br/>
        <Form className="form" onSubmit={ (e) => null }>
            <Col>
              <FormGroup>
                <Label>Card Number</Label>
                <Input
                  type = "text"
                  name = "cardNumber"
                  id = "cardNumber"
                  value={ this.formatCardNumber(this.state.cardNumber) }
                  valid={ this.state.validate.cardNumberState === 'has-success' }
                  invalid={ this.state.validate.cardNumberState === 'has-danger' }
                  onChange={ (e) => { this.handleChange(e) }}
                  onBlur={ (e) => { this.validateLabel(e) }}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Name on Card</Label>
                <Input
                  type = "text"
                  name = "cardName"
                  id = "cardName"
                  value={ this.state.cardName }
                  valid={ this.state.validate.cardNameState === 'has-success' }
                  invalid={ this.state.validate.cardNameState === 'has-danger' }
                  onChange={ (e) => { this.handleChange(e) }}
                  onBlur={ (e) => { this.validateLabel(e) }}
                />
              </FormGroup>
            </Col>
            <Col>
              <td>
                <FormGroup style={{'width': '30%', 'min-width': '105px', 'margin-right': '10px'}} controlId="formControlsSelect">
                  <Label>Month</Label>
                  <Input type="select" className="centerStuffClass"
                    name = "cardMonth"
                    id = "cardMonth"
                    value={ this.state.cardMonth }
                    invalid={ this.state.validate.cardMonthState === 'has-danger' }
                    onChange={ (e) => { this.handleChange(e) }}
                    onBlur={ (e) => { this.validateLabel(e) }}
                    >
                    <option value="">--</option>
                    <option value="01">01</option>
                    <option value="02">02</option>
                    <option value="03">03</option>
                    <option value="04">04</option>
                    <option value="05">05</option>
                    <option value="06">06</option>
                    <option value="07">07</option>
                    <option value="08">08</option>
                    <option value="09">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </Input>
                </FormGroup>
              </td>
              <td>
                <FormGroup style={{'width': '30%', 'min-width': '105px', 'margin-left': '10px', 'margin-right': '50px'}} controlId="formControlsSelect">
                  <Label>Year</Label>
                  <Input type="select" className="centerStuffClass"
                    name="cardYear"
                    id="cardYear"
                    value={ this.state.cardYear }
                    invalid={ this.state.validate.cardYearState === 'has-danger' }
                    onChange={ (e) => { this.handleChange(e) }}
                    onBlur={ (e) => { this.validateLabel(e) }}
                    >
                    <option value="">--</option>
                    <option value="19">2019</option>
                    <option value="20">2020</option>
                    <option value="21">2021</option>
                    <option value="22">2022</option>
                    <option value="23">2023</option>
                    <option value="24">2024</option>
                    <option value="25">2025</option>
                    <option value="26">2026</option>
                    <option value="27">2027</option>
                  </Input>
                </FormGroup>
              </td>
              <td>
                <FormGroup style={{'width': '95%', 'margin-left': '5%'}}>
                  <Label>CVV</Label>
                  <Input style={{'padding-right': '50px'}}
                    type = "text"
                    name = "cardCvc"
                    id = "cardCvc"
                    value={ this.state.cardCvc }
                    valid={ this.state.validate.cardCvcState === 'has-success' }
                    invalid={ this.state.validate.cardCvcState === 'has-danger' }
                    onChange={ (e) => { this.handleChange(e) }}
                    onBlur={ (e) => { this.validateLabel(e) }}
                  />
                </FormGroup>
              </td>
            </Col>

            <br/>

            <Col>
              <td>
                <FormGroup style={{'width': '95%'}}>
                  <Label>Billing Address</Label>
                  <Input style={{'padding-right': '50px'}}
                    type = "text"
                    name = "b_address1"
                    id = "b_address1"
                    value={ this.state.b_address1 }
                    valid={ this.state.validate.b_address1State === 'has-success' }
                    invalid={ this.state.validate.b_address1State === 'has-danger' }
                    onChange={ (e) => { this.handleChange(e) }}
                    onBlur={ (e) => { this.validateLabel(e) }}
                  />
                </FormGroup>
              </td>
              <td>
                <FormGroup style={{'width': '95%', 'margin-left': '5%'}}>
                  <Label>City</Label>
                  <Input style={{'padding-right': '50px'}}
                    type = "text"
                    name = "b_city"
                    id = "b_city"
                    value={ this.state.b_city }
                    valid={ this.state.validate.b_cityState === 'has-success' }
                    invalid={ this.state.validate.b_cityState === 'has-danger' }
                    onChange={ (e) => { this.handleChange(e) }}
                    onBlur={ (e) => { this.validateLabel(e) }}
                  />
                </FormGroup>
              </td>
            </Col>
            <Col>
              <td>
                <FormGroup style={{'width': '94%', 'min-width': '110px', 'margin-right': '6%'}}>
                  <Label>State</Label>
                  <Input
                    type = "select"
                    name = "b_addrState"
                    id = "b_addrState"
                    value={ this.state.b_addrState }
                    invalid={ this.state.validate.b_addrStateState === 'has-danger' }
                    onChange={ (e) => { this.handleChange(e) }}
                    onBlur={ (e) => { this.validateLabel(e) }}
                  >
                    <option value="">--</option>
                    <option value="AK">AK</option>
                    <option value="AL">AL</option>
                    <option value="AR">AR</option>
                    <option value="AZ">AZ</option>
                    <option value="CA">CA</option>
                    <option value="CO">CO</option>
                    <option value="CT">CT</option>
                    <option value="DE">DE</option>
                    <option value="FL">FL</option>
                    <option value="GA">GA</option>
                    <option value="HI">JI</option>
                    <option value="IA">IA</option>
                    <option value="ID">ID</option>
                    <option value="IL">IL</option>
                    <option value="IN">IN</option>
                    <option value="KS">KS</option>
                    <option value="KY">KY</option>
                    <option value="LA">LA</option>
                    <option value="MA">MA</option>
                    <option value="MD">MD</option>
                    <option value="ME">ME</option>
                    <option value="MI">MI</option>
                    <option value="MN">MN</option>
                    <option value="MO">MO</option>
                    <option value="MS">MS</option>
                    <option value="MT">MT</option>
                    <option value="NC">NC</option>
                    <option value="ND">ND</option>
                    <option value="NE">NE</option>
                    <option value="NH">NH</option>
                    <option value="NJ">NJ</option>
                    <option value="NM">NM</option>
                    <option value="NV">NV</option>
                    <option value="NY">NY</option>
                    <option value="OH">OH</option>
                    <option value="OK">OK</option>
                    <option value="OR">OR</option>
                    <option value="PA">PA</option>
                    <option value="RI">RI</option>
                    <option value="SC">SC</option>
                    <option value="SD">SD</option>
                    <option value="TN">TN</option>
                    <option value="TX">TX</option>
                    <option value="UT">UT</option>
                    <option value="VA">VA</option>
                    <option value="VT">VT</option>
                    <option value="WA">WA</option>
                    <option value="WI">WI</option>
                    <option value="WV">WV</option>
                    <option value="WY">WY</option>
                  </Input>
                </FormGroup>
              </td>
              <td>
                <FormGroup style={{'width': '94%', 'margin-left': '6%', 'margin-right': '3%'}}>
                  <Label>Zip Code</Label>
                  <Input
                    type="text"
                    name="b_zipCode"
                    id="b_zipCode"
                    value={ this.state.b_zipCode }
                    valid={ this.state.validate.b_zipCodeState === 'has-success' }
                    invalid={ this.state.validate.b_zipCodeState === 'has-danger' }
                    onChange={ (e) => { this.handleChange(e) }}
                    onBlur={ (e) => { this.validateLabel(e) }}
                />
                </FormGroup>
              </td>
              <td>
                <FormGroup style={{'width': '94%', 'margin-left': '6%'}}>
                  <Label>Country</Label>
                  <Input
                    type="text"
                    name="b_country"
                    id="b_country"
                    value={ this.state.b_country }
                    valid={ this.state.validate.b_countryState === 'has-success' }
                    invalid={ this.state.validate.b_countryState === 'has-danger' }
                    onBlur={ (e) => { this.validateLabel(e) }}
                />
                </FormGroup>
              </td>
            </Col>

            {/*
            <div className="centerStuffClass">
              <img src={require('../imgs/visa.png')} className="creditCardIcon" id="visa" />
              <img src={require('../imgs/mastercard.png')} className="creditCardIcon" id="mastercard" />
              <img src={require('../imgs/amex.png')} className="creditCardIcon" id="amex" />
              <img src={require('../imgs/discover.png')} className="creditCardIcon" id="discover" />
              <div class="AuthorizeNetSeal"> <script type="text/javascript" language="javascript">var ANS_customer_id="95fd3116-4eaf-4819-9aba-8c6f74890c00";</script> <script type="text/javascript" language="javascript" src="//verify.authorize.net:443/anetseal/seal.js" ></script> </div>
            </div>
            */}

            <br/>

            {(this.state.verifyingCard) ?
              <div className="centerStuffClass" style={{'marginTop': '0px'}}>
                <h2 style={{'marginTop': '5px', 'lineHeight': '10px'}}><b>VERIFYING CARD...</b></h2>
                <ScaleLoader style={{'marginTop': '0px'}} loading={true} color="#27AAEA" size="16px" margin="4px"/>
              </div>
              :
              <div className="centerStuffClass">
                <Button style={{'padding':"12px 28px"}} bsStyle="primary" bsSize="large" onClick={this.didTapSubmitNewBilling.bind(this)}><b>SUBMIT</b></Button>
              </div>
            }



          </Form>
        </div>
    )
  }


  didTapOtherBilling(index, toDelete) {
    const title = toDelete ? 'REMOVE ACCOUNT?' : 'MAKE ACTIVE ACCOUNT?';
    const details = toDelete ? 'Confirm you would like to remove this payment account' : 'Confirm you would like to make this payment account your active account';
    this.setState({ showingPopover: true, popoverTitle: title, popoverDetails: details, selectedIndex: index })
  }

  didTapAddNewBilling() {
    this.setState({ showAddBilling: !this.state.showAddBilling });
  }

  didTapSubmitNewBilling() {
    if (!this.validatePage()) {
      return;
    }
    if (this.state.verifyingCard) {
      return;
    }

    this.setState({ verifyingCard: true });

    let cardInfo = {
      cardNumber: this.state.cardNumber,
      cardMonth: this.state.cardMonth,
      cardYear: this.state.cardYear,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phoneNum: this.props.userInfo.phoneNum,
      address1: this.state.b_address1,
      address2: "",
      city: this.state.b_city,
      addrState: this.state.b_addrState,
      zip: this.state.b_zipCode,
      country: this.state.b_country,
      email: this.props.userInfo.email,
    };

    authorizeNetHelper.createCustomerProfile(cardInfo, function(response) {
      if (response.success) {
        // console.log("*************************************");
        // console.log("***** AUTH.NET CUSTOMER CREATED ******");
        // console.log("*************************************");
        this.setState({ authNetProfileId: response.profileId, authNetPaymentId: response.paymentId });

        authorizeNetHelper.validateCustomerPaymentProfile(this.state.authNetProfileId, this.state.authNetPaymentId, this.state.cardCvc, function(response2) {
          if (response2.success) {
            this.setState({ authNetVerifiedCard: true });
            // console.log("*************************************");
            // console.log("***** AUTH.NET PAYMENT VERIFIED *****");
            // console.log("*************************************");
            setTimeout(function() {
              this.createCustomerProfileSuccess();
            }.bind(this), 300);
          } else {
            this.createCustomerProfileFailed(response2.message);
          }
        }.bind(this));
      } else {
        this.createCustomerProfileFailed(response.message);
      }
    }.bind(this));
  }

  async createCustomerProfileSuccess()
  {
    var user = firebaseDbHelper.getCurrentUser();

    var billings = this.props.userInfo.billings;
    let b_address = {
      address1: this.state.b_address1,
      address2: "",
      city: this.state.b_city,
      state: this.state.b_addrState,
      zipCode: this.state.b_zipCode,
      country: this.state.b_country,
    };
    let billing = {
      authNetProfileId: this.state.authNetProfileId,
      authNetPaymentId: this.state.authNetPaymentId,
      name: this.state.cardName,
      lastFour: this.state.cardNumber.substring(this.state.cardNumber.length - 4, this.state.cardNumber.length),
      address: b_address,
    }
    billings.push(billing);


    await firebaseDbHelper.updateBillings(user['uid'], billings);

    this.setState({ verifyingCard: false, showAddBilling: !this.state.showAddBilling });
    this.setState({ cardNumber: "", cardName: "", cardMonth: "", cardYear: "",
      cardCvc: "", authNetProfileId: "", authNetPaymentId: "",
      b_address1: "", b_city: "", b_addrState: "", b_zipCode: "", b_country: ""});

    const { validate } = this.state
    validate.cardNumberState = '';
    validate.cardNameState = '';
    validate.cardMonthState = '';
    validate.cardYearState = '';
    validate.cardCvcState = '';

    this.props.fetchUserInfo(true, true);
  }

  createCustomerProfileFailed(message) {
    this.setState({ verifyingCard: false });
    alert(message);
  }

  render() {
    return (
      <div>
        {this.state.showingPopover ?
          this.renderPopover()
        :
          null
        }
        <div>
          {this.renderBilling()}
        </div>
        <br/><br/>
        <div>
          {this.state.showAddBilling ?
            this.renderAddNewBilling()
          :
            this.renderPayments()
          }
        </div>

      </div>

    );
  }



  handleChange = async event => {
    let dLength = Math.abs(this.state[event.target.id].length - event.target.value.length);

    this.setState({ [event.target.id]: event.target.value });

    if (event.target.id == 'billingAddrCheck') {
      this.setState({ [event.target.id]: !this.state.billingAddrCheck });
    }
    else {
      if (event.target.id == 'cardNumber') {
        this.setState({ [event.target.id]: this.unformatCardNumber(event.target.value) });
      }
      else {
        this.setState({ [event.target.id]: event.target.value });
      }

      if (dLength > 1) {
        this.validateLabel(event);
      }
    }
  }

  validateLabel(e) {
    let _id = e.target.id;
    let _value = e.target.value;
    if (_id == 'cardNumber') {
      this.validateValue(_id, this.unformatCardNumber(_value));
    }
    else {
      this.validateValue(_id, _value);
    }
  }

  validateValue(_id, _value) {
    const { validate } = this.state
      if (_id == "cardNumber") {
        validate.cardNumberState = (_value.length >= 12 && _value.length <= 19) ? 'has-success' : 'has-danger';
      }
      else if (_id == "cardName") {
        validate.cardNameState = (_value.length > 0) ? 'has-success' : 'has-danger';
      }
      else if (_id == "cardMonth") {
        validate.cardMonthState = (_value.length == 2) ? 'has-success' : 'has-danger';
      }
      else if (_id == "cardYear") {
        validate.cardYearState = (_value.length == 2) ? 'has-success' : 'has-danger';
      }
      else if (_id == "cardCvc") {
        validate.cardCvcState = (_value.length >= 3 && _value.length <= 5) ? 'has-success' : 'has-danger';
      }

      else if (_id == "b_address1") {
        validate.b_address1State = (_value.length > 0) ? 'has-success' : 'has-danger';
      }
      else if (_id == "b_city") {
        validate.b_cityState = (_value.length > 0) ? 'has-success' : 'has-danger';
      }
      else if (_id == "b_addrState") {
        validate.b_addrStateState = (_value.length > 0) ? 'has-success' : 'has-danger';
      }
      else if (_id == "b_zipCode") {
        validate.b_zipCodeState = (_value.length > 0) ? 'has-success' : 'has-danger';
      }
      else if (_id == "b_country") {
        validate.b_countryState = (_value.length > 0) ? 'has-success' : 'has-danger';
      }

      this.setState({ validate })
  }

  validatePage() {
    this.validateValue("cardNumber", this.state.cardNumber);
    this.validateValue("cardName", this.state.cardName);
    this.validateValue("cardMonth", this.state.cardMonth);
    this.validateValue("cardYear", this.state.cardYear);
    this.validateValue("cardCvc", this.state.cardCvc);

    const { validate } = this.state

    this.validateValue("b_address1", this.state.b_address1);
    this.validateValue("b_city", this.state.b_city);
    this.validateValue("b_addrState", this.state.b_addrState);
    this.validateValue("b_zipCode", this.state.b_zipCode);
    this.validateValue("b_country", this.state.b_country);
    var addrGood = (validate.b_address1State === 'has-success')
                  && (validate.b_cityState === 'has-success')
                  && (validate.b_addrStateState  === 'has-success')
                  && (validate.b_zipCodeState  === 'has-success')
                  && (validate.b_countryState  === 'has-success')

    let itsGood = (validate.cardNumberState === 'has-success')
                  && (validate.cardNameState === 'has-success')
                  && (validate.cardMonthState  === 'has-success')
                  && (validate.cardYearState  === 'has-success')
                  && (validate.cardCvcState  === 'has-success'
                  && addrGood);

    let valid = (itsGood && !this.state.verifyingCard);
    return valid;
  }

  formatCardNumber(number) {
    if (number.length > 16) {
      let numberFormatted = number.substring(0,4) + " " + number.substring(4,8) + " " + number.substring(8,12) + " " + number.substring(12,16) + " " + number.substring(16,number.length);
      return numberFormatted;
    }
    else if (number.length > 12) {
      let numberFormatted = number.substring(0,4) + " " + number.substring(4,8) + " " + number.substring(8,12) + " " + number.substring(12,number.length);
      return numberFormatted;
    }
    else if (number.length > 8) {
      let numberFormatted = number.substring(0,4) + " " + number.substring(4,8) + " " + number.substring(8);
      return numberFormatted;
    }
    else if (number.length > 4) {
      let numberFormatted = number.substring(0,4) + " " + number.substring(4);
      return numberFormatted;
    }
    return number;
  }
  unformatCardNumber(number) {
    return number.replace(/ /g, '');
  }

}
