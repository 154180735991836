import React from 'react';

//import { ApiContracts, ApiControllers, utils } from 'authorizenet';

import * as firebase from 'firebase';

var ApiContracts = require('authorizenet').APIContracts;
var ApiControllers = require('authorizenet').APIControllers;
var constants = require('authorizenet').Constants;

const LIVE_OR_TEST_MODE = ApiContracts.ValidationModeEnum.LIVEMODE;


export function createCustomerProfile(cardInfo, callback)
{
  var merchantAuthenticationType = new ApiContracts.MerchantAuthenticationType();
  merchantAuthenticationType.setName(process.env.REACT_APP_AUTHNET_API_LOGIN_ID);
  merchantAuthenticationType.setTransactionKey(process.env.REACT_APP_AUTHNET_TRANSACTION_KEY);

	var creditCard = new ApiContracts.CreditCardType();
	creditCard.setCardNumber(cardInfo.cardNumber);
	creditCard.setExpirationDate(cardInfo.cardMonth + cardInfo.cardYear);

	var paymentType = new ApiContracts.PaymentType();
	paymentType.setCreditCard(creditCard);

	var customerAddress = new ApiContracts.CustomerAddressType();
	customerAddress.setFirstName(cardInfo.firstName);
	customerAddress.setLastName(cardInfo.lastName);
	customerAddress.setAddress(cardInfo.address1 + " " + cardInfo.address2);
	customerAddress.setCity(cardInfo.city);
	customerAddress.setState(cardInfo.addrState);
	customerAddress.setZip(cardInfo.zipCode);
	customerAddress.setCountry(cardInfo.country);
	//customerAddress.setPhoneNumber(cardInfo.phoneNum);

  var shippingAddress = new ApiContracts.NameAndAddressType();
	shippingAddress.setFirstName(cardInfo.firstName);
	shippingAddress.setLastName(cardInfo.lastName);
	shippingAddress.setAddress(cardInfo.address1 + " " + cardInfo.address2);
	shippingAddress.setCity(cardInfo.city);
	shippingAddress.setState(cardInfo.addrState);
	shippingAddress.setZip(cardInfo.zipCode);
	shippingAddress.setCountry(cardInfo.country);

	var customerPaymentProfileType = new ApiContracts.CustomerPaymentProfileType();
	customerPaymentProfileType.setCustomerType(ApiContracts.CustomerTypeEnum.INDIVIDUAL);
	customerPaymentProfileType.setPayment(paymentType);
	customerPaymentProfileType.setBillTo(customerAddress);
  //customerPaymentProfileType.setShipTo(shippingAddress);

	var paymentProfilesList = [];
	paymentProfilesList.push(customerPaymentProfileType);

	var customerProfileType = new ApiContracts.CustomerProfileType();
	customerProfileType.setMerchantCustomerId('M_' + getRandomString('cust'));
	customerProfileType.setDescription('Customer Description');
  customerProfileType.setEmail(cardInfo.email);
	customerProfileType.setPaymentProfiles(paymentProfilesList);

	var createRequest = new ApiContracts.CreateCustomerProfileRequest();
	createRequest.setProfile(customerProfileType);
	createRequest.setValidationMode(LIVE_OR_TEST_MODE);
	createRequest.setMerchantAuthentication(merchantAuthenticationType);

	//console.log(JSON.stringify(createRequest.getJSON(), null, 2));

	var ctrl = new ApiControllers.CreateCustomerProfileController(createRequest.getJSON());

  if (LIVE_OR_TEST_MODE == ApiContracts.ValidationModeEnum.LIVEMODE) {
    ctrl.setEnvironment(process.env.REACT_APP_AUTHNET_API_ENDPOINT);
  }

	ctrl.execute(function(){
		var apiResponse = ctrl.getResponse();
		var response = new ApiContracts.CreateCustomerProfileResponse(apiResponse);
		//console.log(JSON.stringify(response, null, 2));

		if(response != null)
		{
			if(response.getMessages().getResultCode() == ApiContracts.MessageTypeEnum.OK) {
        let _profileId = response['customerProfileId'];
        let _paymentId = response['customerPaymentProfileIdList']['numericString'][0].toString();
        // console.log('auth.net profile id: ' + _profileId);
        // console.log('auth.net payment id: ' + _paymentId);
        callback({ success: true, profileId: _profileId, paymentId: _paymentId });
			}
			else {
				//console.log('Result Code: ' + response.getMessages().getResultCode());
				//console.log('Error Code: ' + response.getMessages().getMessage()[0].getCode());
				//console.log('Error message: ' + response.getMessages().getMessage()[0].getText());
        callback({ success: false, message: response.getMessages().getMessage()[0].getText() });
			}
		}
		else {
			//console.log('Null response received');
      callback({ success: false, message: "Couldn't process payment" });
		}
	});
}


export function validateCustomerPaymentProfile(customerProfileId, customerPaymentProfileId, cardCode, callback)
{
  var merchantAuthenticationType = new ApiContracts.MerchantAuthenticationType();
	merchantAuthenticationType.setName(process.env.REACT_APP_AUTHNET_API_LOGIN_ID);
	merchantAuthenticationType.setTransactionKey(process.env.REACT_APP_AUTHNET_TRANSACTION_KEY);

	var validateRequest = new ApiContracts.ValidateCustomerPaymentProfileRequest();
	validateRequest.setMerchantAuthentication(merchantAuthenticationType);
	validateRequest.setCustomerProfileId(customerProfileId);
	validateRequest.setCustomerPaymentProfileId(customerPaymentProfileId);
	validateRequest.setValidationMode(LIVE_OR_TEST_MODE);
	validateRequest.setCardCode(cardCode);

	var ctrl = new ApiControllers.ValidateCustomerPaymentProfileController(validateRequest.getJSON());

  if (LIVE_OR_TEST_MODE == ApiContracts.ValidationModeEnum.LIVEMODE) {
    ctrl.setEnvironment(process.env.REACT_APP_AUTHNET_API_ENDPOINT);
  }

	ctrl.execute(function() {
		var apiResponse = ctrl.getResponse();
		var response = new ApiContracts.ValidateCustomerPaymentProfileResponse(apiResponse);

		//pretty print response
		//console.log(JSON.stringify(response, null, 2));

		if(response != null)  {
			if(response.getMessages().getResultCode() == ApiContracts.MessageTypeEnum.OK) {
        callback({ success: true });
			}
			else {
				//console.log('Result Code: ' + response.getMessages().getResultCode());
				//console.log('Error Code: ' + response.getMessages().getMessage()[0].getCode());
				//console.log('Error message: ' + response.getMessages().getMessage()[0].getText());
        callback({ success: false });
			}
		}
		else {
			//console.log('Null response received');
      callback({ success: false });
		}
	});
}



export function createSubscriptionFromCustomerProfile(orderInfo, customerProfileId, customerPaymentProfileId, customerAddressId, callback) {
	var merchantAuthenticationType = new ApiContracts.MerchantAuthenticationType();
	merchantAuthenticationType.setName(process.env.REACT_APP_AUTHNET_API_LOGIN_ID);
	merchantAuthenticationType.setTransactionKey(process.env.REACT_APP_AUTHNET_TRANSACTION_KEY);

	var interval = new ApiContracts.PaymentScheduleType.Interval();
	interval.setLength(1);
	interval.setUnit(ApiContracts.ARBSubscriptionUnitEnum.MONTHS);

	var paymentScheduleType = new ApiContracts.PaymentScheduleType();
	paymentScheduleType.setInterval(interval);
	paymentScheduleType.setStartDate(getDate());
	paymentScheduleType.setTotalOccurrences(24);
	paymentScheduleType.setTrialOccurrences(0);

	var customerProfileIdType = new ApiContracts.CustomerProfileIdType();
	customerProfileIdType.setCustomerProfileId(customerProfileId);
	customerProfileIdType.setCustomerPaymentProfileId(customerPaymentProfileId);
	//customerProfileIdType.setCustomerAddressId('1506461995');

	var arbSubscription = new ApiContracts.ARBSubscriptionType();
	arbSubscription.setName(orderInfo.name);
	arbSubscription.setPaymentSchedule(paymentScheduleType);
	arbSubscription.setAmount(orderInfo.amount);
  arbSubscription.setTrialAmount(0);
	arbSubscription.setProfile(customerProfileIdType);

	var createRequest = new ApiContracts.ARBCreateSubscriptionRequest();
	createRequest.setMerchantAuthentication(merchantAuthenticationType);
	createRequest.setSubscription(arbSubscription);

	var ctrl = new ApiControllers.ARBCreateSubscriptionController(createRequest.getJSON());

  if (LIVE_OR_TEST_MODE == ApiContracts.ValidationModeEnum.LIVEMODE) {
    ctrl.setEnvironment(process.env.REACT_APP_AUTHNET_API_ENDPOINT);
  }

	ctrl.execute(function(){
		var apiResponse = ctrl.getResponse();
		var response = new ApiContracts.ARBCreateSubscriptionResponse(apiResponse);
		//console.log(JSON.stringify(response, null, 2));

		if(response != null){
			if(response.getMessages().getResultCode() == ApiContracts.MessageTypeEnum.OK){
				// console.log('Subscription Id : ' + response.getSubscriptionId());
				// console.log('Message Code : ' + response.getMessages().getMessage()[0].getCode());
				// console.log('Message Text : ' + response.getMessages().getMessage()[0].getText());
        callback({ success: true, subscriptionId: response.getSubscriptionId() });
			}
			else{
				//console.log('Result Code: ' + response.getMessages().getResultCode());
				//console.log('Error Code: ' + response.getMessages().getMessage()[0].getCode());
				//console.log('Error message: ' + response.getMessages().getMessage()[0].getText());
        callback({ success: false, message: response.getMessages().getMessage()[0].getText() });
			}
		}
		else{
			//console.log('Null Response.');
      callback({ success: false, message: "Payment failed" });
		}
	});
}



export function chargeCustomerProfile(customerProfileId, customerPaymentProfileId, amount, callback) {
	var merchantAuthenticationType = new ApiContracts.MerchantAuthenticationType();
	merchantAuthenticationType.setName(process.env.REACT_APP_AUTHNET_API_LOGIN_ID);
	merchantAuthenticationType.setTransactionKey(process.env.REACT_APP_AUTHNET_TRANSACTION_KEY);

	var profileToCharge = new ApiContracts.CustomerProfilePaymentType();
	profileToCharge.setCustomerProfileId(customerProfileId);

	var paymentProfile = new ApiContracts.PaymentProfile();
	paymentProfile.setPaymentProfileId(customerPaymentProfileId);
	profileToCharge.setPaymentProfile(paymentProfile);

	var orderDetails = new ApiContracts.OrderType();
	orderDetails.setInvoiceNumber('INV-UIC');
	orderDetails.setDescription('Unlimited Ink Club');

  /*
	var lineItem_id1 = new ApiContracts.LineItemType();
	lineItem_id1.setItemId('1');
	lineItem_id1.setName('vase');
	lineItem_id1.setDescription('cannes logo');
	lineItem_id1.setQuantity('18');
	lineItem_id1.setUnitPrice(45.00);

	var lineItem_id2 = new ApiContracts.LineItemType();
	lineItem_id2.setItemId('2');
	lineItem_id2.setName('vase2');
	lineItem_id2.setDescription('cannes logo2');
	lineItem_id2.setQuantity('28');
	lineItem_id2.setUnitPrice('25.00');

	var lineItemList = [];
	lineItemList.push(lineItem_id1);
	lineItemList.push(lineItem_id2);

	var lineItems = new ApiContracts.ArrayOfLineItem();
	lineItems.setLineItem(lineItemList);
  */
  /*
	var shipTo = new ApiContracts.CustomerAddressType();
	shipTo.setFirstName('China');
	shipTo.setLastName('Bayles');
	shipTo.setCompany('Thyme for Tea');
	shipTo.setAddress('12 Main Street');
	shipTo.setCity('Pecan Springs');
	shipTo.setState('TX');
	shipTo.setZip('44628');
	shipTo.setCountry('USA');
  */

	var transactionRequestType = new ApiContracts.TransactionRequestType();
	transactionRequestType.setTransactionType(ApiContracts.TransactionTypeEnum.AUTHCAPTURETRANSACTION);
	transactionRequestType.setProfile(profileToCharge);
	transactionRequestType.setAmount(amount);
	//transactionRequestType.setLineItems(lineItems);
	transactionRequestType.setOrder(orderDetails);
	//transactionRequestType.setShipTo(shipTo);

	var createRequest = new ApiContracts.CreateTransactionRequest();
	createRequest.setMerchantAuthentication(merchantAuthenticationType);
	createRequest.setTransactionRequest(transactionRequestType);

	//pretty print request
	//console.log(JSON.stringify(createRequest.getJSON(), null, 2));

	var ctrl = new ApiControllers.CreateTransactionController(createRequest.getJSON());

  if (LIVE_OR_TEST_MODE == ApiContracts.ValidationModeEnum.LIVEMODE) {
    ctrl.setEnvironment(process.env.REACT_APP_AUTHNET_API_ENDPOINT);
  }

	ctrl.execute(function(){

		var apiResponse = ctrl.getResponse();

		var response = new ApiContracts.CreateTransactionResponse(apiResponse);

		//pretty print response
		//console.log(JSON.stringify(response, null, 2));

		if(response != null){
			if(response.getMessages().getResultCode() == ApiContracts.MessageTypeEnum.OK){
				if(response.getTransactionResponse().getMessages() != null){
					//console.log('Successfully created transaction with Transaction ID: ' + response.getTransactionResponse().getTransId());
					//console.log('Response Code: ' + response.getTransactionResponse().getResponseCode());
					//console.log('Message Code: ' + response.getTransactionResponse().getMessages().getMessage()[0].getCode());
					//console.log('Description: ' + response.getTransactionResponse().getMessages().getMessage()[0].getDescription());
          callback({ success: true });
				}
				else {
					console.log('Failed Transaction.');
					if(response.getTransactionResponse().getErrors() != null){
						//console.log('Error Code: ' + response.getTransactionResponse().getErrors().getError()[0].getErrorCode());
						//console.log('Error message: ' + response.getTransactionResponse().getErrors().getError()[0].getErrorText());
            callback({ success: false, message: response.getTransactionResponse().getErrors().getError()[0].getErrorText() });
					}
          else {
            callback({ success: false, message: "Payment failed!" });
          }
				}
			}
			else {
				console.log('Failed Transaction. ');
				if(response.getTransactionResponse() != null && response.getTransactionResponse().getErrors() != null){
					//console.log('Error Code: ' + response.getTransactionResponse().getErrors().getError()[0].getErrorCode());
					//console.log('Error message: ' + response.getTransactionResponse().getErrors().getError()[0].getErrorText());
          callback({ success: false, message: response.getTransactionResponse().getErrors().getError()[0].getErrorText() });
				}
				else {
					//console.log('Error Code: ' + response.getMessages().getMessage()[0].getCode());
					//console.log('Error message: ' + response.getMessages().getMessage()[0].getText());
          callback({ success: false, message: response.getMessages().getMessage()[0].getText() });
				}
			}
		}
		else {
			//console.log('Null Response.');
      callback({ success: false, message: "Payment failed!" });
		}
	});
}




export function getCustomerPaymentProfile(customerProfileId, customerPaymentProfileId, callback) {

	var merchantAuthenticationType = new ApiContracts.MerchantAuthenticationType();
	merchantAuthenticationType.setName(process.env.REACT_APP_AUTHNET_API_LOGIN_ID);
	merchantAuthenticationType.setTransactionKey(process.env.REACT_APP_AUTHNET_TRANSACTION_KEY);

	var getRequest = new ApiContracts.GetCustomerPaymentProfileRequest();
	getRequest.setMerchantAuthentication(merchantAuthenticationType);
	getRequest.setCustomerProfileId(customerProfileId);
	getRequest.setCustomerPaymentProfileId(customerPaymentProfileId);

	//pretty print request
	//console.log(JSON.stringify(createRequest.getJSON(), null, 2));

	var ctrl = new ApiControllers.GetCustomerProfileController(getRequest.getJSON());


  if (LIVE_OR_TEST_MODE == ApiContracts.ValidationModeEnum.LIVEMODE) {
    ctrl.setEnvironment(process.env.REACT_APP_AUTHNET_API_ENDPOINT);
  }

	ctrl.execute(function(){

		var apiResponse = ctrl.getResponse();

		var response = new ApiContracts.GetCustomerPaymentProfileResponse(apiResponse);

		//pretty print response
		//console.log(JSON.stringify(response, null, 2));

		if(response != null)
		{
			if(response.getMessages().getResultCode() == ApiContracts.MessageTypeEnum.OK)
			{
				// console.log('Customer Payment Profile ID : ' + response.getPaymentProfile().getCustomerPaymentProfileId());
				// console.log('Customer Name : ' + response.getPaymentProfile().getBillTo().getFirstName() + ' ' + response.getPaymentProfile().getBillTo().getLastName());
				// console.log('Address : ' + response.getPaymentProfile().getBillTo().getAddress());
			}
			else
			{
				//console.log('Result Code: ' + response.getMessages().getResultCode());
				//console.log('Error Code: ' + response.getMessages().getMessage()[0].getCode());
				//console.log('Error message: ' + response.getMessages().getMessage()[0].getText());
			}
		}
		else
		{
			//console.log('Null response received');
		}

		callback(response);
	});
}


function getRandomString(text){
	return text + Math.floor((Math.random() * 100000) + 1);
}

function getRandomInt(){
	return Math.floor((Math.random() * 100000) + 1);
}

function getRandomAmount(){
	return ((Math.random() * 100) + 1).toFixed(2);
}

function getDate(){
	return (new Date()).toISOString().substring(0, 10) ;
}
