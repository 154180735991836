import React from 'react';
import ReactDOM from "react-dom"

const axios = require('axios');

const requestStr = "<OrderSubmit><Credential><UserID>CUST-140589</UserID><CompanyCd>01</CompanyCd><SenderID>drivenosuser1</SenderID><Password>!Drvn0$!</Password></Credential><PODate>9/28/2009</PODate><RequiredDate>10/9/2009</RequiredDate><Payment><Terms>Credit Card</Terms></Payment><BillTo><Address><Name>Company Name</Name><Addr1>Bill Address 1</Addr1><Addr2>Bill Address 2</Addr2><City>Wheeling</City><State>WV</State><Zip>26003</Zip></Address><EmailAddr>jdoe@companyname.com</EmailAddr><PONumber>123456789</PONumber><Id>114</Id><!--Optional--></BillTo><ShipTo><Address><Name>Company Name</Name><Addr1>Ship Address 1</Addr1><Addr2>Ship Address 2</Addr2><!--Optional-->	<City>ShipCity</City><State>WV</State><Zip>26003</Zip></Address><PONumber>987654321</PONumber></ShipTo><ItemList><Item><SKU>114804P</SKU><Qty>3</Qty><Price>1.00</Price><Uom>EA</Uom></Item><Item><SKU>114616P</SKU><Qty>10</Qty><Price>1.00</Price><Uom>EA</Uom></Item></ItemList></OrderSubmit>"



export async function submitXmlOrder()
{
  console.log("trying...");

  // make xml string
  // ...
  // ...
  var xmlStr = "<OrderSubmit><Credential><UserID>" + process.env.AXESS_ECOMMERCE_USERID_TEST;
  xmlStr += "</UserID><CompanyCd>01</CompanyCd><SenderID>" + process.env.AXESS_ECOMMERCE_SENDERID;
  xmlStr += "</SenderID><Password>" + process.env.AXESS_ECOMMERCE_PW;
  xmlStr += "</Password></Credential><PODate>" + "";


  var response;
  try {
    response = await axios.post('https://arcane-bastion-40707.herokuapp.com/http://www.westpointproducts.com/ci/CustomerIntegration.aspx',
        // {
        //   OrderSubmit: {
        //     Credential: {
        //       UserID: "CUST-140589",
        //       CompanyCd: "01",
        //       SenderID: "drivenosuser1",
        //       Password: "!Drvn0$!"
        //     }
        //   }
        // },
        requestStr,
        {
        headers: {
          'Content-Type': 'text/xml',
      	},
    });
  }
  catch (error) {
    console.log(error);
    //return "";
  }

  const data = response.data;
  // console.log(data);
  // console.log(data[0]['children']['length']);

  console.log("HELLLOOO");
  console.log(data);

  return "ayeeee";

}
