import React from "react";
import { withRR4, SideNav, Nav as BaseNav } from "react-sidenav";
import styled from "styled-components";
// import {
//   AppContainer as BaseAppContainer,
//   ExampleNavigation as BaseNavigation,
//   ExampleBody as Body
// } from "./containers";
import { Icon as BaseIcon } from "react-icons-kit";
import { dashboard } from "react-icons-kit/fa/dashboard";
import { users } from "react-icons-kit/fa/users";
import { shoppingCart } from "react-icons-kit/fa/shoppingCart";
import { cubes } from "react-icons-kit/fa/cubes";
import { circleO } from "react-icons-kit/fa/circleO";


// const AppContainer = styled.div`
//   display: flex;
//   height: 100%;
//   width: 100%;
//
//   height: calc(100vh - 40px);
// `;

// const Navigation = styled.div`
//   height: 100%;
//   margin-right: 4px;
//   border: 1px solid rgba(0, 0, 0, 0.125);
//
//   background: #303641;
//   color: #8d97ad;
//   font-size: 1em;
//   letter-spacing: 2px;
//   width: 130px;
//   line-height: 36px;
// `;

// const Body = styled.div`
//   width: 100%
//   background: #fff;
//   padding: 12px;
//   border: 1px solid rgba(0, 0, 0, 0.125);
// `;


const IconCnt = styled.div`
  color: #FFF;
  display: flex;
  justify-content: center;
  aligh-items: center;
`;

const Nav = styled(BaseNav)`
  flex-direction: column;
`;

const theme = {
  selectionColor: "#FFF",
  hoverBgColor: "#181b20",
  selectionBgColor: "#00BCD4"
};

const Text = styled.div`
  font-size: 0.72em;
  text-transform: uppercase;
`;

//const SideNav = withRR4();

const Icon = props => <BaseIcon size={32} icon={props.icon} />;

export class PortalSideNav extends React.Component {

  state = { selectedPath: "1" };

  onItemSelection = arg => {
    this.setState({ selectedPath: arg.path });
  };

  render() {
    return (
      <SideNav defaultSelectedPath="1" theme={theme} onItemSelection={this.onItemSelection}>
        <Nav id="1">
          <IconCnt>
            <Icon icon={dashboard} />
          </IconCnt>
          <Text>Dashboard</Text>
        </Nav>
        <Nav id="2">
          <IconCnt>
            <Icon icon={users} />
          </IconCnt>
          <Text>Devices</Text>
        </Nav>
        <Nav id="3">
          <IconCnt>
            <Icon icon={shoppingCart} />
          </IconCnt>
          <Text>Shipments</Text>
        </Nav>
        <Nav id="4">
          <IconCnt>
            <Icon icon={circleO} />
          </IconCnt>
          <Text>Billings</Text>
        </Nav>
        <Nav id="5">
          <IconCnt>
            <Icon icon={cubes} />
          </IconCnt>
          <Text>Settings</Text>
        </Nav>
        <Nav id="6">
          <IconCnt>
            <Icon icon={cubes} />
          </IconCnt>
          <Text>Help</Text>
        </Nav>
      </SideNav>
    );
  }
}
