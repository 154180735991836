import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { SideNav, Nav as BaseNav } from "react-sidenav";
import styled from "styled-components";

import { Icon as BaseIcon } from "react-icons-kit";
import { dashboard } from "react-icons-kit/fa/dashboard";
import { truck } from "react-icons-kit/fa/truck";
import { questionCircle } from "react-icons-kit/fa/questionCircle";
import { cog } from "react-icons-kit/fa/cog";
import { print } from "react-icons-kit/fa/print";
import { shoppingCart } from "react-icons-kit/fa/shoppingCart";
import { cubes } from "react-icons-kit/fa/cubes";
import { circleO } from "react-icons-kit/fa/circleO";
import { signOut  } from "react-icons-kit/fa/signOut";

import { PortalSideNav } from "./PortalSideNav";
import { PortalDashboard } from "./PortalDashboard";
import { PortalDevices } from "./PortalDevices";
import { PortalShipments } from "./PortalShipments";
import { PortalBillings } from "./PortalBillings";
import { PortalSettings } from "./PortalSettings";
import { PortalHelp } from "./PortalHelp";


import './Portal.css';

import "react-credit-cards/lib/styles.scss";

import * as firebase from 'firebase';
var printFleetHelper = require('../API_helpers/PrintFleetHelper')
var firebaseDbHelper = require('../API_helpers/FirebaseDbHelper')
var authorizeNetHelper = require('../API_helpers/AuthorizeNetHelper')

var ScaleLoader = require('halogen/ScaleLoader');


export default class Portal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      portalSection: "1",

      showingPopover: false,

      fetchingInfo: false,
      makingPfGroup: false,

      alertInfo: null,
      userInfo: null,
      dcaPrinters: null,
      subbedIndexes: [],

      hasSentActivationEmail: false,

    };
  }

  async componentDidMount()
  {
    //await this.fetchUserInfo(false);
    setTimeout(function() {
      this.fetchUserInfo(false);
    }.bind(this), 350);
    setTimeout(function() {
      this.fetchUserInfo(false);
    }.bind(this), 850);
    setTimeout(function() {
      this.fetchUserInfo(false);
    }.bind(this), 1700);
    setTimeout(function() {
      this.fetchUserInfo(false);
    }.bind(this), 4200);
    setTimeout(function() {
      this.fetchUserInfo(false);
    }.bind(this), 13000);
    setTimeout(function() {
      this.fetchUserInfo(false);
    }.bind(this), 25000);


    setInterval(function() {
      if (this.state.userInfo != null) {
        if (this.state.userInfo.hasInstalledDca == null) {
          this.checkDca();
        }
        else if (this.state.userInfo.pfPrinters == null) {
            this.checkPrinters(true);
        }
      }
    }.bind(this), 7000);

    setInterval(function() {
      this.fetchUserInfo(true);
    }.bind(this), 55000);
  }

  async fetchUserInfo(forceRefresh, hardForce)
  {
    if (this.state.fetching && !forceRefresh && !hardForce) { return; }
    this.setState({fetching: true});

    if (hardForce) {
      this.setState({alertInfo: null, userInfo: null, dcaPrinters: null, subbedIndexes: null});
    }


    if (this.state.userInfo == null || forceRefresh) {
      let uid = firebase.auth().currentUser.uid;
      let dbRef = 'Users/' + uid;
      await firebase.database().ref(dbRef).once('value', function (snapshot) {
          //console.log(snapshot.val())
          this.setState({ userInfo: snapshot.val() });
          if (this.state.userInfo != null) {
            if (this.state.userInfo.shipments != null) {
              this.state.userInfo.shipments.reverse();
            }
            if (this.state.userInfo.payments != null) {
              this.state.userInfo.payments.reverse();
            }
          }
      }.bind(this));
    }


    if (this.state.userInfo != null) {
      if (this.state.userInfo.payments == null) {
        // USER DID NOT COMPLETE SIGN UP
        this.setState({ alertInfo: { code: '00001', message: 'Please complete the sign up process' } });
        this.setState({fetching: false});
        return;
      }
      else if (this.state.userInfo.pfGroupId == null || this.state.userInfo.pfGroupId == "") {
        this.setState({ alertInfo: { code: '00009', message: 'Generating Ink Monitoring Software...' } });
        await this.makePfGroup();
        this.setState({fetching: false});
        return;
      }
      else if (this.state.userInfo.hasInstalledDca == null) {
        this.setState({ alertInfo: { code: '00005', message: 'Please download & install the Ink Monitor DCA so we can ship you ink when you are running low!' } });
        this.setState({fetching: false});
        return;
      }
    }

    if ((this.state.userInfo != null && this.state.dcaPrinters == null) || forceRefresh) {
      await this.checkPrinters();
    }


    if (this.state.dcaPrinters == null) {
      // USER WAITING FOR DCA TO FETCH
      //this.setState({ alertInfo: { code: '00002', message: 'Waiting for DCA' } });
      //this.setState({fetching: false});
      //return;
    }
    else if (this.state.userInfo.pfPrinters == null) {
      // USER WAITING FOR DCA TO FETCH
      //this.setState({ alertInfo: { code: '00003', message: 'Need to select printers' } });
      //this.setState({fetching: false});
      //return;
    }

    if (this.state.alertInfo == null) {
      this.setState({ alertInfo: {code: '00000', message: "- no new alerts -"} });
    }


    if (this.state.dcaPrinters != null || forceRefresh) {
      for (var i = 0; i < this.state.dcaPrinters.length; i++) {
        if (this.state.dcaPrinters[i].tonerLevels == null || forceRefresh) {
          var levels = await printFleetHelper.getPfDcaDeviceSupplies(this.state.dcaPrinters[i].id)
          if (levels) {
            var printers = this.state.dcaPrinters;
            printers[i].tonerLevels = levels;
            this.setState({ dcaPrinters: printers });
          }
        }
      }
    }


    if (this.state.alertInfo == null) {
      this.setState({ alertInfo: {code: '00000', message: "- no new alerts -"} });
    }

    this.setState({fetching: false});
  }

  async makePfGroup() {
    if (this.state.makingPfGroup) { return; }
    this.setState({ makingPfGroup: true });
    console.log("hello");

    let groupName = this.state.userInfo.lastName + '_' + this.state.userInfo.firstName;
    let groupId = await printFleetHelper.createPfGroup(groupName);
  console.log("hello2");
    if (groupId) {
        console.log("hello3");
      let dcaResponse = await printFleetHelper.createPfDCA(groupName, groupId);
      if (dcaResponse) {
        //this.setState({
        //  pfDcaId: dcaResponse.dcaId,
        //  pfDcaURLpc: dcaResponse.dcaURLpc,
        //  pfDcaURLmac: dcaResponse.dcaURLmac,
        //});
        // console.log("*************************************");
         console.log("************ DCA CREATED ************");
        // console.log("*************************************");

        await this.pushUserDataToFirebase(groupId, dcaResponse.dcaId, dcaResponse.dcaURLpc, dcaResponse.dcaURLmac );

        this.fetchUserInfo(true, true);
      }
    }
    this.setState({ makingPfGroup: false });
      console.log("hello9");
  }


  async checkDca() {
    if (this.state.userInfo.hasInstalledDca) {
      return;
    }
    if (this.state.userInfo.pfGroupId == null || this.state.userInfo.pfGroupId == "") {
      return;
    }
    let isActivated = await printFleetHelper.getPfDcaIsActivated(this.state.userInfo.pfGroupId);
    //let isConnected = await printFleetHelper.getPfDcaIsConnected(this.state.pfDcaId);
    if (isActivated) {
      var user = firebaseDbHelper.getCurrentUser();
      await firebaseDbHelper.updateHasInstalledDca(user['uid'], true);
      this.sendActivatedDcaEmail();
      this.setState({ showingPopover: true, alertInfo: {code: '00000', message: "- no new alerts -"} });
    }
  }

  async checkPrinters() {
    var printers = await printFleetHelper.getPfDcaDevices(this.state.userInfo.pfGroupId)
    if (printers.length > 0) {
      this.setState({ dcaPrinters: printers, subbedIndexes: [] });

      // check which printers are subscribed
      if (this.state.userInfo.pfPrinters != null) {
        for (var i = 0; i < this.state.dcaPrinters.length; i++) {
          for (var j = 0; j < this.state.userInfo.pfPrinters.length; j++) {
            if (this.state.dcaPrinters[i].serialNumber == this.state.userInfo.pfPrinters[j].serial) {
              this.setState({subbedIndexes: this.state.subbedIndexes.concat(i)});
            }
          }
        }
      }
    }
    else {
      this.setState({ dcaPrinters: null, subbedIndexes: [] });
    }
  }

  sendActivatedDcaEmail() {
    if (this.state.hasSentActivationEmail) { return; }
    this.setState({ hasSentActivationEmail: true });

    // send email
    var user = firebaseDbHelper.getCurrentUser();
    var type = "INSTALLED DCA";
    var email = this.state.userInfo.email;
    var firstName = this.state.firstName;
    var lastName = this.state.lastName;
    var phoneNum = this.state.userInfo.phoneNum;
    var uuid = user['uid'];

    var oldValue = this.state.userInfo.pfGroupId;
    var newValue = this.state.userInfo.pfDcas[0].id;

    window.emailjs.send(
      'mailgun',
      process.env.REACT_APP_EMAILJS_TEMPLATEID_CHANGED,
      {type, email, firstName, lastName, phoneNum, uuid, oldValue, newValue }
    )
  }


  async handleLogout() {
    let success = await firebaseDbHelper.logoutUser();
    if (success) {
      this.props.userHasAuthenticated(false);
      //this.props.history.push("/");
    }
  }

  onItemSelection = arg => {
    if (arg.path == 7) {
      setTimeout(function() {
          this.handleLogout();
      }.bind(this), 900);
    }

    this.fetchUserInfo(false);
    this.setState({ portalSection: arg.path });
  };

  renderPortalBody() {
    return (
      this.state.portalSection == 1 &&
        <PortalDashboard alertInfo={this.state.alertInfo} userInfo={this.state.userInfo} dcaPrinters={this.state.dcaPrinters} subbedIndexes={this.state.subbedIndexes} fetchUserInfo={this.fetchUserInfo.bind(this)} history={this.props.history} />
      ||
      this.state.portalSection == 2 &&
        <PortalDevices userInfo={this.state.userInfo} dcaPrinters={this.state.dcaPrinters} subbedIndexes={this.state.subbedIndexes} fetchUserInfo={this.fetchUserInfo.bind(this)} />
      ||
      this.state.portalSection == 3 &&
        <PortalShipments userInfo={this.state.userInfo} fetchUserInfo={this.fetchUserInfo.bind(this)} />
      ||
      this.state.portalSection == 4 &&
        <PortalBillings userInfo={this.state.userInfo} fetchUserInfo={this.fetchUserInfo.bind(this)} />
      ||
      this.state.portalSection == 5 &&
        <PortalSettings userInfo={this.state.userInfo} />
      ||
      this.state.portalSection == 6 &&
        <PortalHelp/>
      ||
      this.state.portalSection == 7 &&
        (
          <div className="centerStuffClass">
            <br/><br/><br/><br/><br/>
            <h2>Logging out...</h2>
            <ScaleLoader loading={true} color="#27AAEA" size="16px" margin="4px"/>
          </div>
        )
    )
  }

  renderMobilePortal() {
    var needToFinish = false;
    var stillLoading = false;
    if (this.state.alertInfo) {
      if (this.state.alertInfo.code == '00001') {
        needToFinish = true;
      }
    } else {
      stillLoading = true;
    }
    return (
      <div>
        {stillLoading ?
          <div className="centerStuffClass">
            <br/>
            <ScaleLoader loading={true} color="#27AAEA" size="16px" margin="4px"/>
          </div>
        :
        needToFinish ?
          <div className="centerStuffClass">
            <br/>
            <p><b>Sign up incomplete, please finish!</b></p>
            <Link to="/signup" ><Button style={{'marginTop': '0px', 'marginBottom': '20px', 'padding':"12px 38px", 'margin': '0 auto'}} bsStyle="primary" bsSize="large">FINISH SIGN UP</Button></Link>
          </div>
        :
          <div className="centerStuffClass">
            <br/>
            <h2><b>Mobile portal coming soon!</b></h2>
            <p>Use a desktop to access your account</p>
            <br/>
            <Button style={{'padding':"15px 38px", 'margin': '0 auto'}} onClick={this.handleLogout.bind(this)}>Logout</Button>
          </div>
        }
      </div>
    )
  }

  render() {
    return (
      <div>
      {this.props.isMobile ?
        this.renderMobilePortal()
      :
        <div>
          {this.state.showingPopover ?
            this.renderPopover()
          :
            null
          }
          <AppContainer>
            <Navigation>
              <SideNav defaultSelectedPath="1" theme={theme} onItemSelection={this.onItemSelection}>
                <Nav id="1">
                  <IconCnt>
                    <Icon icon={dashboard} />
                  </IconCnt>
                  <Text>Dashboard</Text>
                </Nav>
                <Nav id="2">
                  <IconCnt>
                    <Icon icon={print} />
                  </IconCnt>
                  <Text>Printers</Text>
                </Nav>
                <Nav id="3">
                  <IconCnt>
                    <Icon icon={truck} />
                  </IconCnt>
                  <Text>Shipping</Text>
                </Nav>
                <Nav id="4">
                  <IconCnt>
                    <Icon icon={shoppingCart} />
                  </IconCnt>
                  <Text>Billing</Text>
                </Nav>
                <Nav id="5">
                  <IconCnt>
                    <Icon icon={cog} />
                  </IconCnt>
                  <Text>Account</Text>
                </Nav>
                {/*
                <Nav id="6">
                  <IconCnt>
                    <Icon icon={questionCircle} />
                  </IconCnt>
                  <Text>Help</Text>
                </Nav>
                */}
                <Nav id="7">
                  <IconCnt>
                    <Icon icon={signOut} />
                  </IconCnt>
                  <Text>Logout</Text>
                </Nav>
              </SideNav>

            </Navigation>
            <Body>
              {/*
              <br/><br/><br/><br/>
              <h1>PORTAL COMING SOON!</h1>
              */}

               { this.renderPortalBody() }
            </Body>
          </AppContainer>

          <div className="centerStuffClass">
            <p style={{'fontSize': '0.75em'}}><i>Unlimited Ink Portal v0.9.4</i></p>
          </div>
        </div>
      }
      </div>
    )
  }


  renderPopover(message) {
    return (
      <div className="popoverDiv centerStuffClass">
        <div className="popoverDivInner centerStuffClass">
          <div style={{'margin': '16px 12px'}}>
            <h2>Activated!</h2>
            <p>Ink-monitoring software has successfully been installed. It may take up to a day before your ink levels appear in the portal.</p>
            <div style={{'marginTop': '35px'}}>
              <Button style={{'padding':"12px 38px"}} bsStyle="primary" bsSize="large" onClick={this.didTapAlertCancel.bind(this)}>OK</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  didTapAlertCancel() {
    this.setState({ showingPopover: false });
  }


  async pushUserDataToFirebase(pfGroupId, pfDcaId, pfDcaURLpc, pfDcaURLmac)
  {
    var user = firebaseDbHelper.getCurrentUser();
    if (user != null)
    {
      var pfDcas = [];
      let dca = {
        id: pfDcaId,
        urlPC: pfDcaURLpc,
        urlMAC: pfDcaURLmac,
      }
      pfDcas.push(dca);

      await firebaseDbHelper.updatePfGroup(user['uid'],
        pfGroupId, 0, pfDcas,
      );
      // console.log("----- PUSHED USER DATA TO DB -----");
    }
    else {
      // console.log("----- NO USER LOGGED IN -----");
    }

  }


}










export const AppContainer = styled.div`
  display: flex;
  width: 100%;

  height: calc(78vh - 42px);
  min-height: 690px;
`;

export const Navigation = styled.div`
  height: 100%;
  margin-right: 4px;
  border: 1px solid rgba(0, 0, 0, 0.125);

  background: #363636;
  color: #9da7bd;
  font-size: 1.20em;
  letter-spacing: 1.85px;
  width: 140px;
  line-height: 38px;
`;
export const Body = styled.div`
  width: 100%
  background: #cccccc14;
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.525);
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;

`;

const theme = {
  selectionColor: "#FFF",
  hoverBgColor: "#12181c",
  selectionBgColor: "#27AAE2"
};

const Nav = styled(BaseNav)`
  flex-direction: column;
`;

const Text = styled.div`
  margin-bottom: 10px;
  font-size: 0.72em;
  text-transform: uppercase;
`;

const IconCnt = styled.div`
  padding-top: 10px;
  color: #FFF;
  display: flex;
  justify-content: center;
  aligh-items: center;
`;

const Icon = props => <BaseIcon size={32} icon={props.icon} />;
