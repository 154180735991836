import React from 'react';

export default class RegNavBar extends React.Component {
  render() {
    return (
      <html>
        <head>
        <link rel="stylesheet" href="https://www.unlimitedinkclub.com/css/styles.css" media="screen"/>
        <link rel="stylesheet" href="https://www.unlimitedinkclub.com/css/print.css" media="print"/>
        </head>
          <div>

            <div className="page-navi">
              <div className="row">
                <div className="col large-3 giant-4 huge-5 flex">
                  <a href="https://www.unlimitedinkclub.com" title="Unlimited Ink Club" className="branding"><img src="https://www.unlimitedinkclub.com/images/branding.png" alt="Unlimited Ink Club - Logo" className="non-fluid"/></a>
                  <div className="contact">
                    <span className="phone ">(800) 690-0221</span> <a href="mailto:info@unlimitedinkclub.com" className="escape email " title="Send an email to Unlimited Ink Club at 92618 Irvine" data-email="{&quot;name&quot;:&quot;info&quot;,&quot;host&quot;:&quot;unlimitedinkclub.com&quot;}">info@unlimitedinkclub.com</a>
                  </div>
                </div>

                <div className="col large-9 giant-8 huge-7">
                  <nav>
                    <ul className="navi navi-main">
                      <li className="home"><a title="Ink and Toner Subscription Service | Unlimited Ink Club" href="https://www.unlimitedinkclub.com/" className="home active ">Home</a></li>
                      <li className="who-we-are"><a title="Not Your Average Ink Suppliers | Unlimited Ink Club" href="https://www.unlimitedinkclub.com/who-we-are.php" className="who-we-are  ">Who We Are</a></li>
                      <li className="how-it-works"><a title="Ink and Toner Delivery Made Simple | Unlimited Ink Club" href="https://www.unlimitedinkclub.com/how-it-works.php" className="how-it-works  ">How It Works</a></li>
                      <li className="products"><a title="High Quality Printing Products | Unlimited Ink Club" href="https://www.unlimitedinkclub.com/products.php" className="products  ">Products</a></li>
                      <li className="my-account"><a title="Login to Your Account | Unlimited Ink Club" href="/" className="my-account  ">My Account</a></li>
                      <li className="get-started"><a title="The Ink Replacement Service You Need | Unlimited Ink Club" href="/" className="get-started  ">Get Started</a></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
      </html>
    )
  }
}
